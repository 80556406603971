import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { DialogAgentesComponent } from '../components/dialog/dialog-agentes.component';
import { ModalService } from '../_modal';
import { AlertService, AuthenticationService } from '../_services';

@Component({ templateUrl: 'login-empleados.component.html' })
export class LoginEmpleadosComponent implements OnInit {
    loginCuaForm: FormGroup;
    loginEmpleadosForm: FormGroup;
    submitted = false;
    returnUrl: string;
    agente: string;
    mensaje: string;
    mensajeAgenteRazon = false;
    mensajeAgenteRazon2 = false;
    private messageErrorDatoInvalido = 'Dato inválido y/o agente inexistente, favor de verificar';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private modalService: ModalService,
        public dialog: MatDialog,
        private spinner: NgxSpinnerService
    ) {
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit(): void {
        this.authenticationService.logout();

        this.loginCuaForm = this.formBuilder.group({
            agente: ['', Validators.required]
        });
        this.returnUrl = this.route.snapshot.params['/login-empleados'] || '/';

        this.loginEmpleadosForm = this.formBuilder.group({
            nombres: ['', Validators.required],
            apellidoPaterno: ['', Validators.required],
            apellidoMaterno: ['', Validators.required]
        });
        this.returnUrl = this.route.snapshot.params['/login-empleados'] || '/';
    }

    get f(): any {
        return this.loginCuaForm.controls && this.loginEmpleadosForm.controls;
    }

    openModal(id: string): void {
        this.modalService.open(id);
    }

    closeModal(id: string): void {
        this.authenticationService.logout();
        this.modalService.close(id);
    }

    closeModal2(id: string): void {
        this.router.navigate([this.returnUrl]);
        this.modalService.close(id);
    }

    loginEmpleadosCua(): void {
        this.authenticationService
            .loginEmpleadosCUA(this.loginCuaForm.controls.agente.value)
            .pipe(first())
            .subscribe((data) => {
                if (data.agentes === undefined) {
                    this.mensajeAgenteRazon = false;
                } else if (data.agentes.length === 0) {
                    this.mensajeAgenteRazon = false;
                    this.alertService.error(this.messageErrorDatoInvalido);
                } else if (
                    data.agentes[0].nombres +
                    data.agentes[0].apellidoPaterno +
                    data.agentes[0].apellidoMaterno
                ) {
                    this.mensajeAgenteRazon = true;
                    this.mensaje =
                        data.agentes[0].nombres +
                        ' ' +
                        data.agentes[0].apellidoPaterno +
                        ' ' +
                        data.agentes[0].apellidoMaterno;
                } else if (data.agentes[0].razonSocial) {
                    this.mensajeAgenteRazon = true;
                    this.mensaje = data.agentes[0].razonSocial;
                } else if (data.error) {
                    this.alertService.error(data.message);
                    this.mensajeAgenteRazon = false;
                }
                setTimeout(() => {
                    this.alertService.clear();
                }, 5000);
                return data;
            });
    }

    loginEmpleadoNombreYRazonSocial(): void {
        this.spinner.show();
        this.submitted = true;
        this.alertService.clear();

        const nombre = this.f.nombres.value;
        const paterno = this.f.apellidoPaterno.value;
        const materno = this.f.apellidoMaterno.value;

        if ((!nombre || nombre === '') && (!paterno || paterno === '') && (!materno || materno === '')) {
            this.mensajeAgenteRazon2 = false;
            setTimeout(() => {
                this.alertService.error('Favor de ingresar los datos solicitados');
            }, 3000);
            this.authenticationService.logout();
        } else {
            const nombreCompleto = this.obtenerNombreCompleto(nombre, paterno, materno);

            if (nombreCompleto && nombreCompleto !== '') {
                this.loginAgenteNombreYRazonSocial(nombreCompleto);
            } else {
                setTimeout(() => {
                    this.alertService.error('Favor de ingresar los datos solicitados');
                }, 3000);
            }
        }
        setTimeout(() => {
            this.spinner.hide();
        }, 3000);
    }

    loginEmpleadosNombre(nombre, paterno, materno): void {
        if (!nombre || nombre === '') {
            nombre = 'na';
        }

        if (!paterno || paterno === '') {
            paterno = 'na';
        }

        if (!materno || materno === '') {
            materno = 'na';
        }

        this.authenticationService
            .loginEmpleadosNOMBRE(nombre, paterno, materno)
            .pipe(first())
            .subscribe(
                (data) => {
                    if (
                        !data ||
                        data.agentes === undefined ||
                        data.agentes === null ||
                        data.agentes.length <= 0
                    ) {
                        this.mensajeAgenteRazon2 = false;
                        setTimeout(() => {
                            this.alertService.error(this.messageErrorDatoInvalido);
                        }, 3000);
                        this.authenticationService.logout();
                    } else {
                        this.mensajeAgenteRazon2 = true;
                        this.mensaje = data.agentes[0].claveUnicaAgente;
                        localStorage.setItem('cua', data.agentes[0].claveUnicaAgente);
                        setTimeout(() => {
                            this.router.navigate([this.returnUrl]);
                        }, 3000);
                    }
                    return data;
                },
                (error) => this.errorService(error)
            );
    }

    // tslint:disable-next-line: cognitive-complexity
    private loginAgenteNombreYRazonSocial(nombreCompleto: string): void {
        this.authenticationService
            .loginAgentes(nombreCompleto)
            .pipe(first())
            .subscribe(
                (data) => {
                    let agentesConCriterios = [];
                    data.subscribe(
                        (response) => {
                            for (const item in Object.keys(response)) {
                                if (response[item] && response[item].datos_agente_response) {
                                    agentesConCriterios = agentesConCriterios.concat(
                                        response[item].datos_agente_response
                                    );
                                }
                            }
                            if (agentesConCriterios.length > 0) {
                                const dialogRef = this.dialog.open(DialogAgentesComponent, {
                                    data: agentesConCriterios,
                                    autoFocus: false,
                                    width: '30%',
                                    panelClass: 'dialog-agentes',
                                    disableClose: true
                                });

                                dialogRef.afterClosed().subscribe((result) => {
                                    if (result && result !== '') {
                                        this.mensajeAgenteRazon2 = true;
                                        this.mensaje = result;
                                        localStorage.setItem('cua', result);
                                        setTimeout(() => {
                                            this.router.navigate([this.returnUrl]);
                                        }, 3000);
                                    }
                                });
                            } else {
                                this.mensajeAgenteRazon2 = false;
                                setTimeout(() => {
                                    this.alertService.error(this.messageErrorDatoInvalido);
                                }, 3000);
                                this.authenticationService.logout();
                            }
                        },
                        (error) => {
                            this.mensajeAgenteRazon2 = false;
                            setTimeout(() => {
                                this.alertService.error(this.messageErrorDatoInvalido);
                            }, 3000);
                            this.authenticationService.logout();
                        }
                    );
                },
                (error) => this.errorService(error)
            );
    }

    private errorService(error): any {
        setTimeout(() => {
            this.alertService.error(error);
        }, 3000);
        return error;
    }

    // tslint:disable-next-line: cognitive-complexity
    private obtenerNombreCompleto(nombre: string, paterno: string, materno: string): string {
        if (!nombre || nombre === '') {
            nombre = null;
        }
        if (!paterno || paterno === '') {
            paterno = null;
        }
        if (!materno || materno === '') {
            materno = null;
        }

        if (nombre && !paterno && !materno) {
            return nombre;
        } else if (nombre && paterno && !materno) {
            return nombre + ' ' + paterno;
        } else if (nombre && !paterno && materno) {
            return nombre;
        } else if (nombre && paterno && materno) {
            return nombre + ' ' + paterno + ' ' + materno;
        } else if (!nombre && paterno && !materno) {
            return paterno;
        } else if (!nombre && paterno && materno) {
            return paterno + ' ' + materno;
        } else if (!nombre && !paterno && materno) {
            return materno;
        } else if (!nombre && !paterno && !materno) {
            return '';
        } else {
            return '';
        }
    }

    cambioPagina(): void {
        if (
            this.loginCuaForm.controls.agente.value === undefined ||
            this.loginCuaForm.controls.agente.value === null ||
            this.loginCuaForm.controls.agente.value === ''
        ) {
            this.alertService.error('Favor de ingresar la cua');
            this.mensajeAgenteRazon = false;
        } else if (this.mensaje !== '') {
            this.router.navigate([this.returnUrl]);
            this.mensajeAgenteRazon = false;
        }
        setTimeout(() => {
            this.alertService.clear();
        }, 2500);
    }

    cambioPagina2(): void {
        if (
            this.f.nombres.value === undefined ||
            this.f.nombres.value === null ||
            this.f.nombres.value === ''
        ) {
            this.alertService.error('Favor de ingresar el nombre o razón social');
            this.mensajeAgenteRazon = false;
        } else if (this.mensaje !== '') {
            this.router.navigate([this.returnUrl]);
            this.mensajeAgenteRazon = false;
        }
        setTimeout(() => {
            this.alertService.clear();
        }, 2500);
    }
}
