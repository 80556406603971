import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule, MatIconModule, MatNativeDateModule, MatPaginatorModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppComponent } from './app.component';
import { AppRoutingModule, routingComponents } from './app.routing';
import { AnoActualComponent } from './año-actual/anoActual.component';
import { AnoAnteriorComponent } from './año-anterior/anoAnterior.component';
import { AnoAnterior2Component } from './año-anterior2/anoAnterior2.component';
import { AnoAnterior3Component } from './año-anterior3/anoAnterior3.component';
import { AnoAnterior4Component } from './año-anterior4/anoAnterior4.component';
import { BonosComponent } from './bonos/totalBonos.component';
import { ComisionesComponent } from './comisiones/comisiones.component';
import { ComparativoAnualComponent } from './comparativo-anual/comparativo-anual.component';
import { ComparativoMensualComponent } from './comparativo-mensual/comparativo-mensual.component';
import { ComparativoTotalAcumuladoComponent } from './comparativo-total-acumulado/comparativo-total-acumulado.component';
import { DialogAgentesComponent } from './components/dialog/dialog-agentes.component';
import { DatosFacturacionComponent } from './datos-facturacion/datosFacturacion.component';
import { OtrosMovimientosComponent } from './otros movimientos/otrosMovimientos.component';
import { PagosComponent } from './pagos/pagos.component';
import { Fecha } from './pipe/fecha.datepipe';
import { Periodo } from './pipe/periodo.datepipe';
import { ExporterService } from './services/exporter.service';
import { AlertComponent } from './_components';
import { Auth, ErrorInterceptor, JwtInterceptor } from './_helpers';
import { AuthGuard } from './_helpers/auth.guard';
import { ModalModule } from './_modal';

@NgModule({
    declarations: [
        AppComponent,
        AlertComponent,
        ComisionesComponent,
        BonosComponent,
        OtrosMovimientosComponent,
        PagosComponent,
        AnoActualComponent,
        AnoAnteriorComponent,
        AnoAnterior2Component,
        AnoAnterior3Component,
        AnoAnterior4Component,
        ComparativoAnualComponent,
        ComparativoMensualComponent,
        ComparativoTotalAcumuladoComponent,
        DatosFacturacionComponent,
        Fecha,
        Periodo,
        routingComponents,
        DialogAgentesComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        MatTabsModule,
        MatTableModule,
        MatSortModule,
        NoopAnimationsModule,
        ChartsModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        ModalModule,
        MatPaginatorModule,
        MatIconModule,
        MatNativeDateModule,
        MatDialogModule,
        MatRadioModule,
        FormsModule,
        NgxSpinnerModule,
        MatChipsModule
    ],
    exports: [DialogAgentesComponent],
    entryComponents: [DialogAgentesComponent],
    providers: [
        ExporterService,
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        Auth,
        AuthGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
