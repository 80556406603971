import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, Sort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import { Label } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ModalService } from '../_modal';

@Component({ selector: 'app-anoAnterior4', templateUrl: 'anoAnterior4.component.html' })
export class AnoAnterior4Component implements OnInit {
    datos: DatosSimplificados[] = [];
    data: Factura[];
    ScatterChart: Chart;
    year: any;
    tablaDatosActual1: [];
    tablaDatosActual2: [];
    cua: any;
    inicio: any;
    fin: any;
    inicio2: any;
    fin2: any;
    dataSource: MatTableDataSource<Factura>;
    notasFinal = [];
    datosNotas1 = '';
    datosNotas2 = '';
    montoAnioActual: any;
    montoTotal: any;
    selectionDataSource: MatTableDataSource<Factura>;
    message: boolean;
    mes: any;
    estilo1: string;
    estilo2: string;
    mesSeleccion: any;
    estilo3: string;
    mes1: any;
    mes2: any;
    mes3: any;
    mes4: any;
    mes5: any;
    mes6: any;
    mes7: any;
    mes9: any;
    mes8: any;
    mes12: any;
    mes11: any;
    mes10: any;
    estilo: string;
    estiloX: string;
    mesesSelected: boolean[];
    estiloEjeY: string;
    facturas: Factura[] = [];
    facturas2: Factura[] = [];
    notasFacturas: Factura[];
    notasFacturasDataOriginal: Factura[];

    public lineChartLabels: Label[] = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];

    public yAxisElements: string[] = [];

    displayedColumns: string[] = [
        'fechaInicioPeriodo',
        'fechaFinPeriodo',
        'comisiones',
        'bonos',
        'otrosMovimientos',
        'total',
        'saldoPagado',
        'uuidComprobanteFiscal',
        'verDetalle'
    ];

    displayedColumns2: string[] = [
        'tipoComision',
        'ramo',
        'facturaUID',
        'importeAntesImpuestos',
        'ivaAcreditado',
        'ivaRetenido',
        'isr',
        'cedular',
        'importeDespuesImpuestos'
    ];

    @ViewChild('periodosSort', { static: false }) set matPeriodosSort(periodosSort: MatSort) {
        if (this.dataSource !== null && this.dataSource !== undefined) {
            this.dataSource.sort = periodosSort;
        }
    }

    @ViewChild('comprobantesSort', { static: false }) set matComprobantesSort(comprobantesSort: MatSort) {
        if (this.dataSource !== null && this.dataSource !== undefined) {
            this.dataSource.sort = comprobantesSort;
        }
    }
    constructor(
        private http: HttpClient,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private router: Router,
        private spinner: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        const fecha = new Date();
        this.year = fecha.getFullYear() - 4;

        localStorage.setItem('anio5', this.year);

        localStorage.removeItem('anio1');
        localStorage.removeItem('anio2');
        localStorage.removeItem('anio3');
        localStorage.removeItem('anio4');

        const periodo1: any = Date.parse('01-01-' + this.year);
        const periodo2: any = Date.parse('12-31-' + this.year);

        this.cua = localStorage.getItem('cua');
        this.inicio = periodo1;
        this.fin = periodo2;

        this.message = false;

        this.resetButtonsMesesSelected();

        if (!localStorage.getItem('data2016')) {
            this.spinner.show();

            const httpOptions = {
                headers: new HttpHeaders({
                    ['Content-Type']: 'application/json',
                    ['apiKey']: environment.apikey
                })
            };
            this.http
                .get(
                    environment.apiHistorico3 +
                        this.cua +
                        '/periodoinicio/' +
                        this.inicio +
                        '/periodofin/' +
                        this.fin,
                    httpOptions
                )
                .pipe(first())
                .subscribe((data: Facturas) => {
                    if (data.facturas) {
                        data.facturas.forEach((element) => {
                            const factura: Factura = {
                                agenteId: element.agenteId,
                                anio: element.anio,
                                bonos: element.bonos,
                                claveEstatusComprobante: element.claveEstatusComprobante,
                                claveTipoComprobanteFiscal: element.claveTipoComprobanteFiscal,
                                comisiones: element.comisiones,
                                fechaInicioPeriodo: element.fechaInicioPeriodo,
                                fechaFinPeriodo: element.fechaFinPeriodo,
                                importeISRRetenido: element.importeISRRetenido,
                                importeIVAAcreditado: element.importeIVAAcreditado,
                                importeIVARetenido: element.importeIVARetenido,
                                importeImpuestoCedular: element.importeImpuestoCedular,
                                importeIngresoAntesImpuestos: element.importeIngresoAntesImpuestos,
                                importeTotal: element.importeTotal,
                                mes: element.mes,
                                otrosMovimientos: element.otrosMovimientos,
                                uuidComprobanteFiscal: element.uuidComprobanteFiscal,
                                otrosMovimientosNoFacturables: element.otrosMovimientosNoFacturables,
                                pagosNoFacturables: element.pagosNoFacturables
                            };
                            this.facturas.push(factura);
                        });
                    }

                    if (data.notas) {
                        data.notas.forEach((element) => {
                            const factura2: Factura = {
                                agenteId: element.agenteId,
                                anio: element.anio,
                                bonos: element.bonos,
                                claveEstatusComprobante: element.claveEstatusComprobante,
                                claveTipoComprobanteFiscal: element.claveTipoComprobanteFiscal,
                                comisiones: element.comisiones,
                                fechaInicioPeriodo: element.fechaInicioPeriodo,
                                fechaFinPeriodo: element.fechaFinPeriodo,
                                importeISRRetenido: element.importeISRRetenido,
                                importeIVAAcreditado: element.importeIVAAcreditado,
                                importeIVARetenido: element.importeIVARetenido,
                                importeImpuestoCedular: element.importeImpuestoCedular,
                                importeIngresoAntesImpuestos: element.importeIngresoAntesImpuestos,
                                importeTotal: element.importeTotal,
                                mes: element.mes,
                                otrosMovimientos: element.otrosMovimientos,
                                uuidComprobanteFiscal: element.uuidComprobanteFiscal,
                                otrosMovimientosNoFacturables: element.otrosMovimientosNoFacturables,
                                pagosNoFacturables: element.pagosNoFacturables
                            };
                            this.facturas2.push(factura2);
                        });
                    }

                    this.notasFacturas = [...this.facturas, ...this.facturas2];

                    localStorage.setItem('data2016', JSON.stringify(this.notasFacturas));

                    // Data original
                    this.notasFacturasDataOriginal = this.notasFacturas.slice();
                    this.llenarDatosFacturas(this.notasFacturasDataOriginal);
                    // Agrupacion
                    const notasFacturasAgrupadas = this.agrupacionEspecialComprobantes(
                        this.notasFacturas.slice()
                    );

                    this.tablaDatos1(notasFacturasAgrupadas);
                    this.datosGrafica1(notasFacturasAgrupadas);
                    this.spinner.hide();
                });
        } else if (localStorage.getItem('data2016')) {
            // Data original
            this.notasFacturasDataOriginal = JSON.parse(localStorage.getItem('data2016')).slice();
            this.llenarDatosFacturas(this.notasFacturasDataOriginal);
            // Agrupacion
            const notasFacturasAgrupadas = this.agrupacionEspecialComprobantes(
                JSON.parse(localStorage.getItem('data2016')).slice()
            );

            this.datosGrafica1(notasFacturasAgrupadas);
            this.tablaDatos1(notasFacturasAgrupadas);
        }

        if (localStorage.getItem('mesAnio5')) {
            const e = localStorage.getItem('mesAnio5');
            this.cuadro(e);
        }
    }
    // ************************************************************************************************************************************

    datosGrafica1(data): void {
        data.forEach((item) => {
            const dato: DatosSimplificados = {
                mes: item.mes,
                otrosMovimientos: item.otrosMovimientos,
                comisiones: item.comisiones,
                bonos: item.bonos
            };
            this.datos.push(dato);
        });

        const MONTOS = {
            '01': 0,
            '02': 0,
            '03': 0,
            '04': 0,
            '05': 0,
            '06': 0,
            '07': 0,
            '08': 0,
            '09': 0,
            10: 0,
            11: 0,
            12: 0
        };

        this.montoTotal = 0;
        this.datos.forEach((item) => {
            MONTOS[item.mes] = MONTOS[item.mes] + item.comisiones + item.bonos + item.otrosMovimientos;
            this.montoTotal = this.montoTotal + (item.comisiones + item.bonos + item.otrosMovimientos);
        });

        const totalAnualAcumulado = [];
        totalAnualAcumulado.push(MONTOS['01']);
        totalAnualAcumulado.push(MONTOS['02']);
        totalAnualAcumulado.push(MONTOS['03']);
        totalAnualAcumulado.push(MONTOS['04']);
        totalAnualAcumulado.push(MONTOS['05']);
        totalAnualAcumulado.push(MONTOS['06']);
        totalAnualAcumulado.push(MONTOS['07']);
        totalAnualAcumulado.push(MONTOS['08']);
        totalAnualAcumulado.push(MONTOS['09']);
        totalAnualAcumulado.push(MONTOS['10']);
        totalAnualAcumulado.push(MONTOS['11']);
        totalAnualAcumulado.push(MONTOS['12']);

        this.mes1 = totalAnualAcumulado[0].toFixed(0);
        this.mes2 = totalAnualAcumulado[1].toFixed(0);
        this.mes3 = totalAnualAcumulado[2].toFixed(0);
        this.mes4 = totalAnualAcumulado[3].toFixed(0);
        this.mes5 = totalAnualAcumulado[4].toFixed(0);
        this.mes6 = totalAnualAcumulado[5].toFixed(0);
        this.mes7 = totalAnualAcumulado[6].toFixed(0);
        this.mes8 = totalAnualAcumulado[7].toFixed(0);
        this.mes9 = totalAnualAcumulado[8].toFixed(0);
        this.mes10 = totalAnualAcumulado[9].toFixed(0);
        this.mes11 = totalAnualAcumulado[10].toFixed(0);
        this.mes12 = totalAnualAcumulado[11].toFixed(0);

        const dataGrafica = [
            { x: this.mes1, y: this.mes1 },
            { x: this.mes2, y: this.mes2 },
            { x: this.mes3, y: this.mes3 },
            { x: this.mes4, y: this.mes4 },
            { x: this.mes5, y: this.mes5 },
            { x: this.mes6, y: this.mes6 },
            { x: this.mes7, y: this.mes7 },
            { x: this.mes8, y: this.mes8 },
            { x: this.mes9, y: this.mes9 },
            { x: this.mes10, y: this.mes10 },
            { x: this.mes11, y: this.mes11 },
            { x: this.mes12, y: this.mes12 }
        ];

        this.ScatterChart = new Chart('canvas-4', {
            type: 'line',
            data: {
                labels: this.lineChartLabels,
                datasets: [
                    {
                        data: dataGrafica,
                        borderColor: '#3A96FD',
                        backgroundColor: '#f6f9fd',
                        pointBackgroundColor: '#3A96FD'
                    }
                ]
            },
            options: {
                layout: {
                    padding: 45
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    displayColors: false,
                    backgroundColor: '#EAF0FB',
                    bodyFontColor: '#343F61',
                    titleFontColor: '#343F61',
                    xPadding: 25,
                    yPadding: 15,
                    callbacks: {
                        title(tooltipItem): string | string[] {
                            return 'Total ' + data.labels[tooltipItem[0].index];
                        },
                        label(tooltipItems): string | string[] {
                            return (
                                '$' +
                                Number(tooltipItems.yLabel.toString())
                                    .toFixed(0)
                                    .replace(/./g, (c, i, a) => {
                                        return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
                                    })
                            );
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            gridLines: {
                                display: true
                            },
                            ticks: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            gridLines: {
                                display: true
                            },
                            ticks: {
                                display: false
                            }
                        }
                    ]
                }
            }
        });
        Chart.defaults.global.defaultFontSize = 14;

        // tslint:disable-next-line: no-string-literal
        this.yAxisElements = this.ScatterChart['scales']['y-axis-0'].ticks;

        if (this.yAxisElements.length === 6) {
            this.estiloEjeY = '130%';
        } else if (this.yAxisElements.length === 7) {
            this.estiloEjeY = '95%';
        } else if (this.yAxisElements.length === 9) {
            this.estiloEjeY = '51%';
        } else if (this.yAxisElements.length === 8) {
            this.estiloEjeY = '70%';
        } else if (this.yAxisElements.length === 11) {
            this.estiloEjeY = '26%';
        }
    }

    // ************************************************************************************************************************************

    tablaDatos1(data): void {
        let notasFacturas = data;

        this.llenarDatosFacturas(notasFacturas);

        notasFacturas = notasFacturas.sort((a, b) => {
            if (a.fechaFinPeriodo > b.fechaFinPeriodo) {
                return -1;
            } else {
                return 1;
            }
        });

        this.dataSource = new MatTableDataSource<Factura>(notasFacturas);
    }

    private llenarDatosFacturas(notasFacturas: Factura[]): void {
        notasFacturas.forEach((element) => {
            switch (element.claveTipoComprobanteFiscal) {
                case 'FAVI':
                    element.ramo = 'Vida';
                    element.claveTipoComprobanteFiscal = 'Factura';
                    break;
                case 'FANV':
                    element.ramo = 'No vida';
                    element.claveTipoComprobanteFiscal = 'Factura';
                    break;
                case 'NOVI':
                    element.ramo = 'Vida';
                    element.claveTipoComprobanteFiscal = 'Nota de Crédito';
                    break;
                case 'NONV':
                    element.ramo = 'No vida';
                    element.claveTipoComprobanteFiscal = 'Nota de Crédito';
                    break;
                default:
            }
        });
    }

    private agrupacionEspecialComprobantes(facturas: Factura[]): Factura[] {
        let newNotasFacturas: Factura[] = [];
        // si tiene las mismas comisiones, bonos y otras percepciones regresar un array nuevo

        facturas.forEach((element) => {
            const factura = newNotasFacturas.find(
                (facturaAgrupada) =>
                    facturaAgrupada.fechaInicioPeriodo === element.fechaInicioPeriodo &&
                    facturaAgrupada.fechaFinPeriodo === element.fechaFinPeriodo &&
                    facturaAgrupada.comisiones === element.comisiones &&
                    facturaAgrupada.bonos === element.bonos &&
                    facturaAgrupada.otrosMovimientos === element.otrosMovimientos &&
                    facturaAgrupada.fechaInicioPeriodo === element.fechaInicioPeriodo &&
                    facturaAgrupada.fechaFinPeriodo === element.fechaFinPeriodo
            );

            if (!factura) {
                const newfactura: Factura = {
                    agenteId: element.agenteId,
                    anio: element.anio,
                    bonos: element.bonos,
                    claveEstatusComprobante: element.claveEstatusComprobante,
                    claveTipoComprobanteFiscal: element.claveTipoComprobanteFiscal,
                    comisiones: element.comisiones,
                    fechaInicioPeriodo: element.fechaInicioPeriodo,
                    fechaFinPeriodo: element.fechaFinPeriodo,
                    importeISRRetenido: element.importeISRRetenido,
                    importeIVAAcreditado: element.importeIVAAcreditado,
                    importeIVARetenido: element.importeIVARetenido,
                    importeImpuestoCedular: element.importeImpuestoCedular,
                    importeIngresoAntesImpuestos: element.importeIngresoAntesImpuestos,
                    importeTotal: element.importeTotal,
                    mes: element.mes,
                    otrosMovimientos: element.otrosMovimientos,
                    uuidComprobanteFiscal: element.uuidComprobanteFiscal,
                    otrosMovimientosNoFacturables: element.otrosMovimientosNoFacturables,
                    pagosNoFacturables: element.pagosNoFacturables
                };
                newNotasFacturas.push(newfactura);
            }
        });
        newNotasFacturas = this.agruparPorUid(newNotasFacturas);
        return newNotasFacturas;
    }

    private agruparPorUid(facturas: Factura[]): Factura[] {
        const facturasAgrupadas: Factura[] = [];

        facturas.forEach((element) => {
            const factura = facturasAgrupadas.find(
                (facturaAgrupada) => facturaAgrupada.fechaFinPeriodo === element.fechaFinPeriodo
            );
            if (factura) {
                factura.bonos = factura.bonos + element.bonos;
                factura.comisiones = factura.comisiones + element.comisiones;
                factura.otrosMovimientos = factura.otrosMovimientos + element.otrosMovimientos;
                factura.importeISRRetenido = factura.importeISRRetenido + element.importeISRRetenido;
                factura.importeIVAAcreditado = factura.importeIVAAcreditado + element.importeIVAAcreditado;
                factura.importeIVARetenido = factura.importeIVARetenido + element.importeIVARetenido;
                factura.importeImpuestoCedular =
                    factura.importeImpuestoCedular + element.importeImpuestoCedular;
                factura.importeIngresoAntesImpuestos =
                    factura.importeIngresoAntesImpuestos + element.importeIngresoAntesImpuestos;
                factura.importeTotal = factura.importeTotal + element.importeTotal;
            } else {
                const newfactura: Factura = {
                    agenteId: element.agenteId,
                    anio: element.anio,
                    bonos: element.bonos,
                    claveEstatusComprobante: element.claveEstatusComprobante,
                    claveTipoComprobanteFiscal: element.claveTipoComprobanteFiscal,
                    comisiones: element.comisiones,
                    fechaInicioPeriodo: element.fechaInicioPeriodo,
                    fechaFinPeriodo: element.fechaFinPeriodo,
                    importeISRRetenido: element.importeISRRetenido,
                    importeIVAAcreditado: element.importeIVAAcreditado,
                    importeIVARetenido: element.importeIVARetenido,
                    importeImpuestoCedular: element.importeImpuestoCedular,
                    importeIngresoAntesImpuestos: element.importeIngresoAntesImpuestos,
                    importeTotal: element.importeTotal,
                    mes: element.mes,
                    otrosMovimientos: element.otrosMovimientos,
                    uuidComprobanteFiscal: element.uuidComprobanteFiscal,
                    otrosMovimientosNoFacturables: element.otrosMovimientosNoFacturables,
                    pagosNoFacturables: element.pagosNoFacturables
                };
                facturasAgrupadas.push(newfactura);
            }
        });
        return facturasAgrupadas;
    }

    // ************************************************************************************************************************************

    sortPeriodos(e: Sort): void {
        const data = this.dataSource.data;
        if (!e.active || e.direction === '') {
            this.dataSource.data = data;
            return;
        }

        this.dataSource.data = { ...data }.sort((a: any, b: any) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'fechaInicioPeriodo':
                    return this.compare(a.fechaInicioPeriodo, b.fechaInicioPeriodo, isAsc);
                case 'fechaFinPeriodo':
                    return this.compare(a.fechaFinPeriodo, b.fechaFinPeriodo, isAsc);
                case 'comisiones':
                    return this.compare(a.comisiones, b.comisiones, isAsc);
                case 'bonos':
                    return this.compare(a.bonos, b.bonos, isAsc);
                case 'otrosMovimientos':
                    return this.compare(a.otrosMovimientos, b.otrosMovimientos, isAsc);
                case 'total':
                    return this.compare(a.total, b.total, isAsc);
                case 'saldoPagado':
                    return this.compare(a.saldoPagado, b.saldoPagado, isAsc);
                default:
                    return 0;
            }
        });
    }

    sortComprobantes(e: Sort): void {
        const data = this.dataSource.data;
        if (!e.active || e.direction === '') {
            this.dataSource.data = data;
            return;
        }

        this.dataSource.data = [...data].sort((a: any, b: any) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'tipoComision':
                    return this.compare(a.tipoComision, b.tipoComision, isAsc);
                case 'ramo':
                    return this.compare(a.ramo, b.ramo, isAsc);
                case 'facturaUID':
                    return this.compare(a.facturaUID, b.facturaUID, isAsc);
                case 'importeAntesImpuestos':
                    return this.compare(a.importeAntesImpuestos, b.importeAntesImpuestos, isAsc);
                case 'ivaAcreditado':
                    return this.compare(a.ivaAcreditado, b.ivaAcreditado, isAsc);
                case 'ivaRetenido':
                    return this.compare(a.ivaRetenido, b.ivaRetenido, isAsc);
                case 'isr':
                    return this.compare(a.isr, b.isr, isAsc);
                case 'cedular':
                    return this.compare(a.cedular, b.cedular, isAsc);
                case 'importeDespuesImpuestos':
                    return this.compare(a.importeDespuesImpuestos, b.importeDespuesImpuestos, isAsc);
                default:
                    return 0;
            }
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    // ************************************************************************************************************************************

    cuadro(e): void {
        const index = this.lineChartLabels.findIndex((element) => element === e);

        this.resetButtonsMesesSelected();

        this.mesesSelected[index] = true;

        this.message = true;
        this.mes = e;

        this.estilos(e);
        /************************************************************************************************/
        let mes = '';
        if (e === 'Enero') {
            mes = '01';
        }
        if (e === 'Febrero') {
            mes = '02';
        }
        if (e === 'Marzo') {
            mes = '03';
        }
        if (e === 'Abril') {
            mes = '04';
        }
        if (e === 'Mayo') {
            mes = '05';
        }
        if (e === 'Junio') {
            mes = '06';
        }
        if (e === 'Julio') {
            mes = '07';
        }
        if (e === 'Agosto') {
            mes = '08';
        }
        if (e === 'Septiembre') {
            mes = '09';
        }
        if (e === 'Octubre') {
            mes = '10';
        }
        if (e === 'Noviembre') {
            mes = '11';
        }
        if (e === 'Diciembre') {
            mes = '12';
        }

        const filtroMes = JSON.parse(localStorage.getItem('data2016')).filter((item) => item.mes === mes);

        // Agrupacion
        const notasFacturasAgrupadasMes = this.agrupacionEspecialComprobantes(filtroMes);

        this.tablaDatos1(notasFacturasAgrupadasMes);
        this.tiempoEspera();
        this.tiempoEspera();
        /************************************************************************************************/

        localStorage.setItem('mesAnio5', e);
    }

    private estilos(e: any): void {
        if (e === 'Enero') {
            this.estilo1 = '6.5%';
            this.estilo2 = '23%';
            this.mesSeleccion = this.mes1;
        }
        if (e === 'Febrero') {
            this.estilo1 = '8%';
            this.estilo2 = '23%';
            this.mesSeleccion = this.mes2;
        }
        if (e === 'Marzo') {
            this.estilo1 = '15%';
            this.estilo2 = '23%';
            this.mesSeleccion = this.mes3;
        }
        if (e === 'Abril') {
            this.estilo1 = '21%';
            this.estilo2 = '23%';
            this.mesSeleccion = this.mes4;
        }
        if (e === 'Mayo') {
            this.estilo1 = '28%';
            this.estilo2 = '23%';
            this.mesSeleccion = this.mes5;
        }
        if (e === 'Junio') {
            this.estilo1 = '35%';
            this.estilo2 = '23%';
            this.mesSeleccion = this.mes6;
        }
        if (e === 'Julio') {
            this.estilo1 = '42%';
            this.estilo2 = '23%';
            this.mesSeleccion = this.mes7;
        }
        if (e === 'Agosto') {
            this.estilo1 = '48%';
            this.estilo2 = '23%';
            this.mesSeleccion = this.mes8;
        }
        if (e === 'Septiembre') {
            this.estilo1 = '55%';
            this.estilo2 = '11%';
            this.mesSeleccion = this.mes9;
        }
        if (e === 'Octubre') {
            this.estilo1 = '62%';
            this.estilo2 = '17%';
            this.mesSeleccion = this.mes10;
        }
        if (e === 'Noviembre') {
            this.estilo1 = '68%';
            this.estilo2 = '17%';
            this.mesSeleccion = this.mes11;
        }
        if (e === 'Diciembre') {
            this.estilo1 = '69.5%';
            this.estilo2 = '17%';
            this.mesSeleccion = this.mes12;
        }

        if (this.mesSeleccion === 0) {
            this.estilo3 = '40%';
        }
        if (this.mesSeleccion !== 0) {
            this.estilo3 = '22%';
        }
    }

    tiempoEspera(): void {
        setTimeout(() => {
            this.message = false;
        }, 2500);
    }

    openModal(id: string): void {
        this.modalService.open(id);
    }

    closeModal(id: string): void {
        this.modalService.close(id);
    }

    returnComisiones(factura): void {
        localStorage.setItem('verDetalleFactura', JSON.stringify(factura));
        this.router.navigate(['/']);
    }

    idSeleccionado(id): void {
        const idSeleccionado = this.notasFacturasDataOriginal.filter((item) => item.fechaFinPeriodo === id);
        this.selectionDataSource = new MatTableDataSource<Factura>(idSeleccionado);
        this.openModal('custom-modal-comprobante-5');
    }

    resetButtonsMesesSelected(): void {
        this.mesesSelected = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false
        ];
    }
    // ************************************************************************************************************************************
}

export interface DatosHistorico {
    anio: number;
    mes: number;
    acumuladoFacturasNotasCreditoPeriodo: number;
}

export interface DatosTabla {
    agenteID: number;
    claveTransaccionMovimiento: string;
    fechaInicioPeriodoReservado: number;
    fechaInicioPeriodoOrigen: number;
    fechaIngresoComprobante: number;
    folioIntermoComprobante: number;
    claveEstatusComprobante: string;
    fechaEmisionComprobante: number;
    claveTipoComprobanteFiscal: string;
    importeIngresoAntesImpuestos: number;
    importeIVAAcreditado: number;
    importeIVARetenido: number;
    importeISRRetenido: number;
    importeImpuestoCedular: number;
    importeTotal: number;
    uuidComprobanteFiscal: string;
    claveDocumentoResguardaBovedaDigital: string;
    fechaUltimaActualizacion: number;
}

export interface Facturas {
    notas: Factura[];
    facturas: Factura[];
    tiempoRespuestaConsulta: number;
}

export interface Factura {
    agenteId: number;
    anio: string;
    bonos: number;
    claveEstatusComprobante: string;
    claveTipoComprobanteFiscal: string;
    comisiones: number;
    fechaInicioPeriodo: number;
    fechaFinPeriodo: number;
    importeISRRetenido: number;
    importeIVAAcreditado: number;
    importeIVARetenido: number;
    importeImpuestoCedular: number;
    importeIngresoAntesImpuestos: number;
    importeTotal: number;
    mes: string;
    otrosMovimientos: number;
    uuidComprobanteFiscal: string;
    otrosMovimientosNoFacturables: any;
    pagosNoFacturables: any;
    ramo?: string;
}

export interface DatosSimplificados {
    mes: number;
    otrosMovimientos: number;
    comisiones: number;
    bonos: number;
}
