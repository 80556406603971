import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog-agentes',
    templateUrl: './dialog-agentes.component.html',
    styleUrls: ['./dialog-agentes.component.css']
})
export class DialogAgentesComponent implements OnInit {
    habilitarBotonAceptar = true;
    agenteSeleccionado: string;
    agentes: any[];

    constructor(
        public dialogRef: MatDialogRef<DialogAgentesComponent>,
        @Inject(MAT_DIALOG_DATA) public dataInput: any[]
    ) {}

    ngOnInit(): void {
        this.agruparDatos();
    }

    onOkClick(): void {
        this.dialogRef.close();
    }

    onCloseClick(): void {
        this.agenteSeleccionado = undefined;
        this.dialogRef.close();
    }

    private agruparDatos(): void {
        this.agentes = [];

        for (const element of this.dataInput) {
            if (!this.agentes.find((agente) => agente.cua === element.cua)) {
                this.agentes.push(element);
            }
        }

        this.agenteSeleccionado = this.agentes[0].cua;

        if (this.agentes.length === 1) {
            this.dialogRef.close(this.agenteSeleccionado);
        }
    }

    public asignarAgente(cua: string): void {
        if (!this.habilitarBotonAceptar) {
            this.agenteSeleccionado = cua;
            this.dialogRef.close(this.agenteSeleccionado);
        }
    }
}
