import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComparacionPeriodosComponent } from './comparacion-periodos';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { LoginEmpleadosComponent } from './login empleados';
import { PeriodosAnterioresComponent } from './periodos-anteriores';
import { AuthGuard } from './_helpers';

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'login-empleados', component: LoginEmpleadosComponent },
    { path: 'periodos-anteriores', component: PeriodosAnterioresComponent },
    { path: 'comparacion-periodos', component: ComparacionPeriodosComponent },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
export const routingComponents = [
    HomeComponent,
    LoginComponent,
    LoginEmpleadosComponent,
    PeriodosAnterioresComponent,
    ComparacionPeriodosComponent
];
