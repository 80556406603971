import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
const EXCEL_EXT = '.xlsx';

@Injectable()
export class ExporterService {
    constructor() {}

    exportExcel(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };

        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Llamar al metodo buffer y el fileName
        this.saveExcel(excelBuffer, excelFileName);
    }

    private saveExcel(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + EXCEL_EXT);
    }

    formateaFecha(fechaLong): string {
        const date = new Date(fechaLong);
        const anio = date.getFullYear();
        const mes = date.getMonth() + 1;
        const dia = date.getDate();
        let diaFormat;
        let mesFormat;

        if (dia < 10) {
            diaFormat = '0' + dia;
        } else {
            diaFormat = dia;
        }
        if (mes < 10) {
            mesFormat = '0' + mes;
        } else {
            mesFormat = mes;
        }
        return diaFormat + '/' + mesFormat + '/' + anio;
    }
}
