import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { AlertService, AuthenticationService } from '../_services';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    password: string;
    portal: string;
    message: any;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService,
        @Inject(DOCUMENT) private document: Document
    ) {
        const cua = this.document.location.href;
        const sURLVariables = cua.split('cua');
        const sParametro = sURLVariables[1].split('%');
        const idCua = sParametro[1].substring(4, 9);
        localStorage.setItem('cua', idCua);

        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit(): void {
        this.portal = this.route.snapshot.queryParamMap.get('returnUrl');

        if (this.portal.includes('intermediario')) {
            this.router.navigateByUrl('/login');
        } else {
            const cua = this.document.location.href;
            const sURLVariables = cua.split('cua');
            const sParametro = sURLVariables[1].split('%');
            const idCua = sParametro[1].substring(4, 9);

            if (localStorage.getItem('cua') && idCua !== localStorage.getItem('cua')) {
                sessionStorage.clear();
                localStorage.clear();

                this.authenticationService.setNullCurrentUser();
                this.router.navigateByUrl('/login-empleados');
            } else {
                this.router.navigateByUrl('/login-empleados');
            }
        }
        localStorage.setItem('portal', this.portal);

        this.loginForm = this.formBuilder.group({
            password: ['', Validators.required]
        });
        this.returnUrl = this.route.snapshot.queryParams.reutrnUrl || '/';
    }

    get f(): any {
        return this.loginForm.controls;
    }

    closeMessage(e): any {
        this.message = false;
        return e;
    }

    onSubmit(): void {
        this.spinner.show();
        this.submitted = true;
        this.alertService.clear();

        this.authenticationService
            .login(this.f.password.value)
            .pipe(first())
            .subscribe(
                (data) => {
                    if (data === null || data === undefined || this.f.password.value === '') {
                        setTimeout(() => {
                            this.message = 'Contraseña incorrecta';
                        }, 5000);
                    } else {
                        this.router.navigate([this.returnUrl]);
                    }
                    return data;
                },
                (error) => {
                    console.log('error ', error);
                    this.alertService.error(error);
                    this.loading = false;
                }
            );
        this.spinner.hide();
    }
}
