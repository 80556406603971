export const environment = {
    production: true,

    apiURL: 'https://api.service.gnp.com.mx/servicio/complemento/detalle/validar/nip',
    apiCUA: 'https://api.service.gnp.com.mx/servicio/complemento/detalle/consulta/agente/cua/',

    apiNombre: 'https://api.service.gnp.com.mx/servicio/complemento/detalle/consulta/agente/nombres/',
    apiRazonSocial:
        'https://api.service.gnp.com.mx/servicio/complemento/detalle/consulta/agente/razon/social/',

    apiDetalleIngresos:
        'https://api.service.gnp.com.mx/agentes/ingresos/saldo/disponible/concentrado/agente/',
    apiComisiones:
        'https://api.service.gnp.com.mx/agentes/ingresos/detalle/ingresos/otros/movimientos/agente',

    apiBonos: 'https://api.service.gnp.com.mx/agentes/ingresos/bonos/agrupados/agente',
    apiOtrosMovimientos: 'https://api.service.gnp.com.mx/agentes/ingresos/otros/movimientos/resumen/agente',

    apiFiltro: 'https://api.service.gnp.com.mx/agentes/ingresos/comprobante/',
    apiPeriodoFacturar:
        'https://api.service.gnp.com.mx/servicio/complemento/detalle/puente/consultar/obtener/saldos/cua/',

    apiBeneficioIVA:
        'https://api.service.gnp.com.mx/servicio/complemento/detalle/puente/consultar/beneficio/iva/cua/',
    apiBuzonE: 'https://api.service.gnp.com.mx/servicio/complemento/detalle/puente/agente/buzone/cua/',

    apiComprobantesNominales:
        'https://api.service.gnp.com.mx/servicio/complemento/detalle/puente/comprobantes/nominales/agente/',
    apiComprobanteXML:
        'https://api.service.gnp.com.mx/servicio/complemento/detalle/puente/validar/comprobante',

    apiXML: 'https://api.service.gnp.com.mx/servicio/complemento/detalle/puente/validar/comprobante/agente/',
    // Servicio para facturas
    apiHistorico3:
        'https://api.service.gnp.com.mx/agentes/ingresos/comprobantes/bonos/comisiones/facturas/agente/',

    // Consulta de agentes
    apiAgentes: 'https://bca-ws.gnp.com.mx/app/consulta-agente-por-nombre/',
    apiAgentesCriterios: 'https://bca-ws.gnp.com.mx/app/consulta-agente-criterios/',

    apiPagos: 'https://api.service.gnp.com.mx/agentes/ingresos/pagos/resumen/agente/',
    apiGuardarIva:
        'https://api.service.gnp.com.mx/servicio/complemento/detalle/puente/guadar/beneficio/iva/cua/',

    apiVentanaServicio:
        'https://api.service.gnp.com.mx/servicio/complemento/detalle/puente/consultar/ventana/servicio/',
    apiComprobantesReales:
        'https://api.service.gnp.com.mx/servicio/complemento/detalle/puente/comprobantes/reales/agente/',

    apikey: 'l7xxe6d4d4b0a8b049f5a8931d971bf0b53a',
    xApiKeyAgentes: '9a780a70-c5fc-4bee-86cf-5650cce16516'
};
