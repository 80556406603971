import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import './_contents/app.less';
import { User } from './_models';
import { AuthenticationService } from './_services';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit {
    currentUser: User;
    private paramSubscription: Subscription;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.paramSubscription = this.route.queryParams.subscribe((params) => {
            let param: string = null;
            const url = window.location.href;

            console.log('Url al iniciar componente principal:' + window.location.href);

            if (params && params.returnUrl) {
                param = params.returnUrl;
            } else if (params && params.idParticipante) {
                param = `/?portal=${params.portal}&idParticipante=${params.idParticipante}&da=${params.da}&cua=${params.cua}&correo=${params.correo}`;
            }

            if (
                param &&
                params &&
                (params.returnUrl || params.idParticipante) &&
                !url.includes('localhost:') &&
                !url.includes('127.0.0.1:')
            ) {
                localStorage.clear();
                sessionStorage.clear();
                this.authenticationService.setNullCurrentUser();

                const navigationExtras: NavigationExtras = {
                    queryParams: { returnUrl: param }
                };

                this.router.navigate(['/login'], navigationExtras);
                this.paramSubscription.unsubscribe();
            }
        });
    }

    logout(): void {
        this.authenticationService.logout();
        this.router.navigate(['/']);
    }

    @HostListener('window:beforeunload', ['$event'])
    logoutRecharge(): void {
        const url = window.location.href;
        console.log('Url al borrar cache:' + window.location.href);

        if (!url.includes('localhost:') && !url.includes('127.0.0.1:')) {
            const portal = localStorage.getItem('portal');

            sessionStorage.clear();
            localStorage.clear();

            localStorage.setItem('portal', portal);

            this.authenticationService.setNullCurrentUser();
            this.authenticationService.logout();
        }
    }
}
