import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, Sort } from '@angular/material';
import { Chart } from 'chart.js';
import { Label } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({ selector: 'app-comparativo-mensual', templateUrl: './comparativo-mensual.component.html' })
export class ComparativoMensualComponent implements OnInit {
    @ViewChild('generalSort', { static: false }) set matGeneralesSort(generalSort: MatSort) {
        if (this.MontosTotalesAnualesMensuales !== null && this.MontosTotalesAnualesMensuales !== undefined) {
            this.MontosTotalesAnualesMensuales.sort = generalSort;
        }
    }

    meses: any;
    filtroMes: DatosHistorico[] = [];
    filtroMes2: FiltroMensual[] = [];
    filtroAnual: FiltroMensual[] = [];
    filtrosVentana = false;
    btnComisiones2 = false;
    btnBonos2 = false;
    btnMovimientos2 = false;
    btnVida2 = false;
    btnSalud2 = false;
    btnAutos2 = false;
    btnDanos2 = false;
    btnMes: number;
    data: DatosHistorico[];
    Linechart: any;
    tablaDatos = [];
    datos: DatosSimplificados[] = [];
    displayedColumns: string[] = ['circulo', 'a', 'm', 'i'];
    totalAnualAcumulado: any;
    acumulado: any;
    totales: any;
    MontosTotalesAnualesMensuales: any;
    incremento: any;
    year1: any;
    year2: any;
    year3: any;
    year4: any;
    year5: any;
    elementos: any;
    meses2020: any[];
    meses2019: any[];
    meses2018: any[];
    meses2017: any[];
    meses2016: any[];
    mesSelect: any;
    mes: any;
    incremento1: any;
    incremento2: any;
    incremento3: any;
    incremento4: any;
    incremento5: any;
    message: boolean;
    anio1: number;
    anio2: number;
    anio3: number;
    anio4: number;
    anio5: number;
    signo1: string;
    signo2: string;
    signo3: string;
    signo4: string;
    signo5: string;
    estilo: string;
    estiloEjeY: string;
    filtros: MatChipFiltro[];
    conceptoMatChip: string;
    ramoMatChip: string;
    mesMatChip: string;
    mesesSelected: boolean[];
    private backgroundColorDefault = 'rgba(0, 0, 0, 0.0)';

    public yAxisElements: string[] = [];
    public lineChartLabels: Label[] = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];

    selectable = true;
    removable = true;

    constructor(private http: HttpClient, private spinner: NgxSpinnerService) {}

    ngOnInit(): void {
        const fecha = new Date();
        this.year1 = fecha.getFullYear();
        this.year2 = this.year1 - 1;
        this.year3 = this.year1 - 2;
        this.year4 = this.year1 - 3;
        this.year5 = this.year1 - 4;

        this.MontosTotalesAnualesMensuales = new MatTableDataSource<Tablas>([
            { circulo: '#D1EBFF', a: this.year1, m: '0', i: '0' },
            { circulo: '#CEC0D8', a: this.year2, m: '0', i: '0' },
            { circulo: '#FCB383', a: this.year3, m: '0', i: '0' },
            { circulo: '#CE96C0', a: this.year4, m: '0', i: '0' },
            { circulo: '#F6A2A9', a: this.year5, m: '0', i: '0' }
        ]);

        this.armarGraficaTablaInicio();
    }

    private armarGraficaTablaInicio(): void {
        this.conceptoMatChip = null;
        this.ramoMatChip = null;
        this.mesMatChip = null;
        this.filtros = [];
        this.resetButtonsMesesSelected();
        this.obtenerDatosFiltro('vacio', 'vacio');
    }

    cambioLabel(tooltipItems, data): string {
        const datos = this.obtenerDatosMensuales(tooltipItems.label);

        if (tooltipItems.datasetIndex === 0) {
            const element = datos.filter((item) => item.a === this.year1);
            return element[0].a + '    ' + element[0].signo + '   ' + element[0].i + '%';
        }

        if (tooltipItems.datasetIndex === 1) {
            const element = datos.filter((item) => item.a === this.year2);
            return element[0].a + '    ' + element[0].signo + '   ' + element[0].i + '%';
        }

        if (tooltipItems.datasetIndex === 2) {
            const element = datos.filter((item) => item.a === this.year3);
            return element[0].a + '    ' + element[0].signo + '   ' + element[0].i + '%';
        }

        if (tooltipItems.datasetIndex === 3) {
            const element = datos.filter((item) => item.a === this.year4);
            return element[0].a + '    ' + element[0].signo + '   ' + element[0].i + '%';
        }

        if (tooltipItems.datasetIndex === 4) {
            const element = datos.filter((item) => item.a === this.year5);
            return element[0].a + '    ' + element[0].signo + '   ' + element[0].i + '%';
        }
    }

    validarFiltroConcepto2(i: number): void {
        switch (i) {
            case 1:
                this.btnComisiones2 = !this.btnComisiones2;
                this.btnBonos2 = false;
                this.btnMovimientos2 = false;
                break;
            case 2:
                this.btnComisiones2 = false;
                this.btnBonos2 = false;
                this.btnMovimientos2 = !this.btnMovimientos2;
                break;
            case 3:
                this.btnComisiones2 = false;
                this.btnBonos2 = !this.btnBonos2;
                this.btnMovimientos2 = false;
                break;
            default:
                this.btnComisiones2 = false;
                this.btnBonos2 = false;
                this.btnMovimientos2 = false;
        }
    }

    validarFiltroRamo2(i: number): void {
        switch (i) {
            case 1:
                this.btnVida2 = !this.btnVida2;
                this.btnSalud2 = false;
                this.btnAutos2 = false;
                this.btnDanos2 = false;
                break;
            case 2:
                this.btnVida2 = false;
                this.btnSalud2 = !this.btnSalud2;
                this.btnAutos2 = false;
                this.btnDanos2 = false;
                break;
            case 3:
                this.btnVida2 = false;
                this.btnSalud2 = false;
                this.btnAutos2 = !this.btnAutos2;
                this.btnDanos2 = false;
                break;
            case 4:
                this.btnVida2 = false;
                this.btnSalud2 = false;
                this.btnAutos2 = false;
                this.btnDanos2 = !this.btnDanos2;
                break;
            default:
                this.btnVida2 = false;
                this.btnSalud2 = false;
                this.btnAutos2 = false;
                this.btnDanos2 = false;
        }
    }

    borrarFiltros2(): void {
        this.armarGraficaTablaInicio();
        this.validarFiltroConcepto2(0);
        this.validarFiltroRamo2(0);
        this.mesSelect = '';
        this.filtrosVentana = false;
    }

    // tslint:disable-next-line: no-big-function cognitive-complexity
    cuadro(e): void {
        const index = this.lineChartLabels.findIndex((element) => element === e);

        this.resetButtonsMesesSelected();

        this.mesesSelected[index] = true;

        this.datos = [];
        this.mes = e;
        this.mesSelect = e;

        this.filtros = this.filtros.filter((element) => element.valor !== this.mesMatChip);
        this.mesMatChip = this.mesSelect;
        this.filtros.push({
            valor: this.mesMatChip,
            tipo: 'mes'
        });

        if (this.mes === 'Enero') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 1);
        }
        if (this.mes === 'Febrero') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 2);
        }
        if (this.mes === 'Marzo') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 3);
        }
        if (this.mes === 'Abril') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 4);
        }
        if (this.mes === 'Mayo') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 5);
        }
        if (this.mes === 'Junio') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 6);
        }
        if (this.mes === 'Julio') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 7);
        }
        if (this.mes === 'Agosto') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 8);
        }
        if (this.mes === 'Septiembre') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 9);
        }
        if (this.mes === 'Octubre') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 10);
        }
        if (this.mes === 'Noviembre') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 11);
        }
        if (this.mes === 'Diciembre') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 12);
        }

        const comisionesMensual2015 = this.filtroMes2.filter((item) => item.anio === this.year1 - 5);
        let sumaComisionesMensual2015 = 0;
        comisionesMensual2015.forEach((element) => {
            sumaComisionesMensual2015 = sumaComisionesMensual2015 + element.valor;
        });
        /**********************************************************************************/

        const comisionesMensualVida1 = this.filtroMes2.filter((item) => item.anio === this.year5);

        let sumaComisionesMensual1 = 0;
        comisionesMensualVida1.forEach((element) => {
            sumaComisionesMensual1 = sumaComisionesMensual1 + element.valor;
        });
        this.MontosTotalesAnualesMensuales.data[4].m = sumaComisionesMensual1.toFixed(2);

        let incrementoMensual2016: number;

        if (sumaComisionesMensual2015 <= 0 && sumaComisionesMensual1 > 0) {
            incrementoMensual2016 = 100;
        } else if (sumaComisionesMensual2015 > 0 && sumaComisionesMensual1 <= 0) {
            incrementoMensual2016 = -100;
        } else if (sumaComisionesMensual2015 <= 0 && sumaComisionesMensual1 <= 0) {
            incrementoMensual2016 = 0;
        } else if (sumaComisionesMensual2015 > 0 && sumaComisionesMensual1 > 0) {
            incrementoMensual2016 = (sumaComisionesMensual1 / sumaComisionesMensual2015 - 1) * 100;
        } else {
            incrementoMensual2016 = -100;
        }

        this.MontosTotalesAnualesMensuales.data[4].i = incrementoMensual2016.toFixed(2);

        /**********************************************************************************/
        const comisionesMensualVida2 = this.filtroMes2.filter((item) => item.anio === this.year4);

        let sumaComisionesMensual2 = 0;
        comisionesMensualVida2.forEach((element) => {
            sumaComisionesMensual2 = sumaComisionesMensual2 + element.valor;
        });
        this.MontosTotalesAnualesMensuales.data[3].m = sumaComisionesMensual2.toFixed(2);

        let incrementoMensual2017: number;

        if (sumaComisionesMensual1 <= 0 && sumaComisionesMensual2 > 0) {
            incrementoMensual2017 = 100;
        } else if (sumaComisionesMensual1 > 0 && sumaComisionesMensual2 <= 0) {
            incrementoMensual2017 = -100;
        } else if (sumaComisionesMensual1 <= 0 && sumaComisionesMensual2 <= 0) {
            incrementoMensual2017 = 0;
        } else if (sumaComisionesMensual1 > 0 && sumaComisionesMensual2 > 0) {
            incrementoMensual2017 = (sumaComisionesMensual2 / sumaComisionesMensual1 - 1) * 100;
        } else {
            incrementoMensual2017 = -100;
        }

        this.MontosTotalesAnualesMensuales.data[3].i = incrementoMensual2017.toFixed(2);

        /**********************************************************************************/
        const comisionesMensualVida3 = this.filtroMes2.filter((item) => item.anio === this.year3);

        let sumaComisionesMensual3 = 0;
        comisionesMensualVida3.forEach((element) => {
            sumaComisionesMensual3 = sumaComisionesMensual3 + element.valor;
        });
        this.MontosTotalesAnualesMensuales.data[2].m = sumaComisionesMensual3.toFixed(2);

        let incrementoMensual2018: number;

        if (sumaComisionesMensual2 <= 0 && sumaComisionesMensual3 > 0) {
            incrementoMensual2018 = 100;
        } else if (sumaComisionesMensual2 > 0 && sumaComisionesMensual3 <= 0) {
            incrementoMensual2018 = -100;
        } else if (sumaComisionesMensual2 <= 0 && sumaComisionesMensual3 <= 0) {
            incrementoMensual2018 = 0;
        } else if (sumaComisionesMensual2 > 0 && sumaComisionesMensual3 > 0) {
            incrementoMensual2018 = (sumaComisionesMensual3 / sumaComisionesMensual2 - 1) * 100;
        } else {
            incrementoMensual2018 = -100;
        }

        this.MontosTotalesAnualesMensuales.data[2].i = incrementoMensual2018.toFixed(2);

        /**********************************************************************************/
        const comisionesMensualVida4 = this.filtroMes2.filter((item) => item.anio === this.year2);

        let sumaComisionesMensual4 = 0;
        comisionesMensualVida4.forEach((element) => {
            sumaComisionesMensual4 = sumaComisionesMensual4 + element.valor;
        });
        this.MontosTotalesAnualesMensuales.data[1].m = sumaComisionesMensual4.toFixed(2);

        let incrementoMensual2019: number;

        if (sumaComisionesMensual3 <= 0 && sumaComisionesMensual4 > 0) {
            incrementoMensual2019 = 100;
        } else if (sumaComisionesMensual3 > 0 && sumaComisionesMensual4 <= 0) {
            incrementoMensual2019 = -100;
        } else if (sumaComisionesMensual3 <= 0 && sumaComisionesMensual4 <= 0) {
            incrementoMensual2019 = 0;
        } else if (sumaComisionesMensual3 > 0 && sumaComisionesMensual4 > 0) {
            incrementoMensual2019 = (sumaComisionesMensual4 / sumaComisionesMensual3 - 1) * 100;
        } else {
            incrementoMensual2019 = -100;
        }

        this.MontosTotalesAnualesMensuales.data[1].i = incrementoMensual2019.toFixed(2);

        /**********************************************************************************/
        const comisionesMensualVida5 = this.filtroMes2.filter((item) => item.anio === this.year1);

        let sumaComisionesMensual5 = 0;
        comisionesMensualVida5.forEach((element) => {
            sumaComisionesMensual5 = sumaComisionesMensual5 + element.valor;
        });
        this.MontosTotalesAnualesMensuales.data[0].m = sumaComisionesMensual5.toFixed(2);

        let incrementoMensual2020: number;

        if (sumaComisionesMensual4 <= 0 && sumaComisionesMensual5 > 0) {
            incrementoMensual2020 = 100;
        } else if (sumaComisionesMensual4 > 0 && sumaComisionesMensual5 <= 0) {
            incrementoMensual2020 = -100;
        } else if (sumaComisionesMensual4 <= 0 && sumaComisionesMensual5 <= 0) {
            incrementoMensual2020 = 0;
        } else if (sumaComisionesMensual4 > 0 && sumaComisionesMensual5 > 0) {
            incrementoMensual2020 = (sumaComisionesMensual5 / sumaComisionesMensual4 - 1) * 100;
        } else {
            incrementoMensual2020 = -100;
        }

        this.MontosTotalesAnualesMensuales.data[0].i = incrementoMensual2020.toFixed(2);
        /**********************************************************************************/

        this.message = true;
        const anio = new Date().getFullYear();
        this.signo1 = '';
        this.signo2 = '';
        this.signo3 = '';
        this.signo4 = '';
        this.signo5 = '';
        this.anio1 = anio;
        this.anio2 = anio - 1;
        this.anio3 = anio - 2;
        this.anio4 = anio - 3;
        this.anio5 = anio - 4;

        if (this.mes === 'Enero') {
            this.estilo = '6%';
        }
        if (this.mes === 'Febrero') {
            this.estilo = '7%';
        }
        if (this.mes === 'Marzo') {
            this.estilo = '11%';
        }
        if (this.mes === 'Abril') {
            this.estilo = '17%';
        }
        if (this.mes === 'Mayo') {
            this.estilo = '22%';
        }
        if (this.mes === 'Junio') {
            this.estilo = '27%';
        }
        if (this.mes === 'Julio') {
            this.estilo = '32%';
        }
        if (this.mes === 'Agosto') {
            this.estilo = '37%';
        }
        if (this.mes === 'Septiembre') {
            this.estilo = '42%';
        }
        if (this.mes === 'Octubre') {
            this.estilo = '48%';
        }
        if (this.mes === 'Noviembre') {
            this.estilo = '54%';
        }
        if (this.mes === 'Diciembre') {
            this.estilo = '53.4%';
        }

        if (this.MontosTotalesAnualesMensuales.data[0].i < 0) {
            this.incremento1 = this.MontosTotalesAnualesMensuales.data[0].i * -1;
            this.signo1 = '(-)';
        } else {
            this.incremento1 = this.MontosTotalesAnualesMensuales.data[0].i;
            this.signo1 = '(+)';
        }
        if (this.MontosTotalesAnualesMensuales.data[1].i < 0) {
            this.incremento2 = this.MontosTotalesAnualesMensuales.data[1].i * -1;
            this.signo2 = '(-)';
        } else {
            this.incremento2 = this.MontosTotalesAnualesMensuales.data[1].i;
            this.signo2 = '(+)';
        }
        if (this.MontosTotalesAnualesMensuales.data[2].i < 0) {
            this.incremento3 = this.MontosTotalesAnualesMensuales.data[2].i * -1;
            this.signo3 = '(-)';
        } else {
            this.incremento3 = this.MontosTotalesAnualesMensuales.data[2].i;
            this.signo3 = '(+)';
        }
        if (this.MontosTotalesAnualesMensuales.data[3].i < 0) {
            this.incremento4 = this.MontosTotalesAnualesMensuales.data[3].i * -1;
            this.signo4 = '(-)';
        } else {
            this.incremento4 = this.MontosTotalesAnualesMensuales.data[3].i;
            this.signo4 = '(+)';
        }
        if (this.MontosTotalesAnualesMensuales.data[4].i < 0) {
            this.incremento5 = this.MontosTotalesAnualesMensuales.data[4].i * -1;
            this.signo5 = '(-)';
        } else {
            this.incremento5 = this.MontosTotalesAnualesMensuales.data[4].i;
            this.signo5 = '(+)';
        }
        setTimeout(() => {
            this.message = false;
        }, 5000);
    }

    private obtenerRamoConcepto(): any {
        let concepto;
        this.filtros = this.filtros.filter((element) => element.valor !== this.conceptoMatChip);
        if (this.btnComisiones2) {
            concepto = 'comisiones';
            this.conceptoMatChip = 'Comisiones';
        }
        if (this.btnBonos2) {
            concepto = 'bonos';
            this.conceptoMatChip = 'Bonos';
        }
        if (this.btnMovimientos2) {
            concepto = 'otros';
            this.conceptoMatChip = 'Otras percepciones';
        }
        if (concepto === undefined || concepto === null || concepto === '') {
            concepto = 'vacio';
            this.conceptoMatChip = null;
        } else {
            this.filtros.push({
                valor: this.conceptoMatChip,
                tipo: 'concepto'
            });
        }

        let ramo;
        this.filtros = this.filtros.filter((element) => element.valor !== this.ramoMatChip);
        if (this.btnVida2) {
            ramo = 'VIDA';
            this.ramoMatChip = 'Vida';
        }
        if (this.btnSalud2) {
            ramo = 'SA';
            this.ramoMatChip = 'Salud';
        }
        if (this.btnAutos2) {
            ramo = 'AU';
            this.ramoMatChip = 'Autos';
        }
        if (this.btnDanos2) {
            ramo = 'DA';
            this.ramoMatChip = 'Daños';
        }
        if (ramo === undefined || ramo === null || ramo === '') {
            ramo = 'vacio';
            this.ramoMatChip = null;
        } else {
            this.filtros.push({
                valor: this.ramoMatChip,
                tipo: 'ramo'
            });
        }

        return {
            ramo,
            concepto
        };
    }

    filtroAnualMensual(): void {
        const ramoConcepto = this.obtenerRamoConcepto();

        if (ramoConcepto.ramo === 'vacio' && ramoConcepto.concepto === 'vacio') {
            return;
        }

        this.obtenerDatosFiltro(ramoConcepto.concepto, ramoConcepto.ramo);
    }

    // tslint:disable-next-line: no-big-function cognitive-complexity
    private obtenerDatosFiltro(concepto: string, ramo: string): void {
        this.spinner.show();

        const cua = localStorage.getItem('cua');
        const httpOptions = {
            headers: new HttpHeaders({ ['Content-Type']: 'application/json', ['apiKey']: environment.apikey })
        };
        this.http
            .get(
                environment.apiFiltro + concepto + '/agente/' + cua + '/unidad/negocio/' + ramo + '/tiempo/5',
                httpOptions
            )
            .pipe(first())
            .subscribe((data: FiltroMensual[]) => {
                this.filtroAnual = data;

                const comisionesMensual2015 = data.filter((item) => item.anio === this.year1 - 5);
                let sumaComisionesMensual2015 = 0;
                comisionesMensual2015.forEach((element) => {
                    sumaComisionesMensual2015 = sumaComisionesMensual2015 + element.valor;
                });

                /**********************************************************************************/
                const comisionesMensualVida1 = data.filter((item) => item.anio === this.year5);
                const meses2016 = this.obtenerMeses(comisionesMensualVida1);
                let sumaComisionesMensual1 = 0;
                comisionesMensualVida1.forEach((element) => {
                    sumaComisionesMensual1 = sumaComisionesMensual1 + element.valor;
                });
                this.MontosTotalesAnualesMensuales.data[4].m = sumaComisionesMensual1.toFixed(2);

                let incrementoMensual2016: number;

                if (sumaComisionesMensual2015 <= 0 && sumaComisionesMensual1 > 0) {
                    incrementoMensual2016 = 100;
                } else if (sumaComisionesMensual2015 > 0 && sumaComisionesMensual1 <= 0) {
                    incrementoMensual2016 = -100;
                } else if (sumaComisionesMensual2015 <= 0 && sumaComisionesMensual1 <= 0) {
                    incrementoMensual2016 = 0;
                } else if (sumaComisionesMensual2015 > 0 && sumaComisionesMensual1 > 0) {
                    incrementoMensual2016 = (sumaComisionesMensual1 / sumaComisionesMensual2015 - 1) * 100;
                } else {
                    incrementoMensual2016 = -100;
                }

                this.MontosTotalesAnualesMensuales.data[4].i = incrementoMensual2016.toFixed(2);

                /**********************************************************************************/
                const comisionesMensualVida2 = data.filter((item) => item.anio === this.year4);
                const meses2017 = this.obtenerMeses(comisionesMensualVida2);
                let sumaComisionesMensual2 = 0;
                comisionesMensualVida2.forEach((element) => {
                    sumaComisionesMensual2 = sumaComisionesMensual2 + element.valor;
                });
                this.MontosTotalesAnualesMensuales.data[3].m = sumaComisionesMensual2.toFixed(2);

                let incrementoMensual2017: number;

                if (sumaComisionesMensual1 <= 0 && sumaComisionesMensual2 > 0) {
                    incrementoMensual2017 = 100;
                } else if (sumaComisionesMensual1 > 0 && sumaComisionesMensual2 <= 0) {
                    incrementoMensual2017 = -100;
                } else if (sumaComisionesMensual1 <= 0 && sumaComisionesMensual2 <= 0) {
                    incrementoMensual2017 = 0;
                } else if (sumaComisionesMensual1 > 0 && sumaComisionesMensual2 > 0) {
                    incrementoMensual2017 = (sumaComisionesMensual2 / sumaComisionesMensual1 - 1) * 100;
                } else {
                    incrementoMensual2017 = -100;
                }

                this.MontosTotalesAnualesMensuales.data[3].i = incrementoMensual2017.toFixed(2);

                /**********************************************************************************/
                const comisionesMensualVida3 = data.filter((item) => item.anio === this.year3);
                const meses2018 = this.obtenerMeses(comisionesMensualVida3);
                let sumaComisionesMensual3 = 0;
                comisionesMensualVida3.forEach((element) => {
                    sumaComisionesMensual3 = sumaComisionesMensual3 + element.valor;
                });
                this.MontosTotalesAnualesMensuales.data[2].m = sumaComisionesMensual3.toFixed(2);

                let incrementoMensual2018: number;

                if (sumaComisionesMensual2 <= 0 && sumaComisionesMensual3 > 0) {
                    incrementoMensual2018 = 100;
                } else if (sumaComisionesMensual2 > 0 && sumaComisionesMensual3 <= 0) {
                    incrementoMensual2018 = -100;
                } else if (sumaComisionesMensual2 <= 0 && sumaComisionesMensual3 <= 0) {
                    incrementoMensual2018 = 0;
                } else if (sumaComisionesMensual2 > 0 && sumaComisionesMensual3 > 0) {
                    incrementoMensual2018 = (sumaComisionesMensual3 / sumaComisionesMensual2 - 1) * 100;
                } else {
                    incrementoMensual2018 = -100;
                }

                this.MontosTotalesAnualesMensuales.data[2].i = incrementoMensual2018.toFixed(2);

                /**********************************************************************************/
                const comisionesMensualVida4 = data.filter((item) => item.anio === this.year2);
                const meses2019 = this.obtenerMeses(comisionesMensualVida4);
                let sumaComisionesMensual4 = 0;
                comisionesMensualVida4.forEach((element) => {
                    sumaComisionesMensual4 = sumaComisionesMensual4 + element.valor;
                });
                this.MontosTotalesAnualesMensuales.data[1].m = sumaComisionesMensual4.toFixed(2);

                let incrementoMensual2019: number;

                if (sumaComisionesMensual3 <= 0 && sumaComisionesMensual4 > 0) {
                    incrementoMensual2019 = 100;
                } else if (sumaComisionesMensual3 > 0 && sumaComisionesMensual4 <= 0) {
                    incrementoMensual2019 = -100;
                } else if (sumaComisionesMensual3 <= 0 && sumaComisionesMensual4 <= 0) {
                    incrementoMensual2019 = 0;
                } else if (sumaComisionesMensual3 > 0 && sumaComisionesMensual4 > 0) {
                    incrementoMensual2019 = (sumaComisionesMensual4 / sumaComisionesMensual3 - 1) * 100;
                } else {
                    incrementoMensual2019 = -100;
                }

                this.MontosTotalesAnualesMensuales.data[1].i = incrementoMensual2019.toFixed(2);

                /**********************************************************************************/
                const comisionesMensualVida5 = data.filter((item) => item.anio === this.year1);
                const meses2020 = this.obtenerMeses(comisionesMensualVida5);
                let sumaComisionesMensual5 = 0;
                comisionesMensualVida5.forEach((element) => {
                    sumaComisionesMensual5 = sumaComisionesMensual5 + element.valor;
                });
                this.MontosTotalesAnualesMensuales.data[0].m = sumaComisionesMensual5.toFixed(2);

                let incrementoMensual2020: number;

                if (sumaComisionesMensual4 <= 0 && sumaComisionesMensual5 > 0) {
                    incrementoMensual2020 = 100;
                } else if (sumaComisionesMensual4 > 0 && sumaComisionesMensual5 <= 0) {
                    incrementoMensual2020 = -100;
                } else if (sumaComisionesMensual4 <= 0 && sumaComisionesMensual5 <= 0) {
                    incrementoMensual2020 = 0;
                } else if (sumaComisionesMensual4 > 0 && sumaComisionesMensual5 > 0) {
                    incrementoMensual2020 = (sumaComisionesMensual5 / sumaComisionesMensual4 - 1) * 100;
                } else {
                    incrementoMensual2020 = -100;
                }

                this.MontosTotalesAnualesMensuales.data[0].i = incrementoMensual2020.toFixed(2);

                /**********************************************************************************/

                if (this.Linechart) {
                    this.Linechart.destroy();
                }

                const meses = {
                    meses2020,
                    meses2019,
                    meses2018,
                    meses2017,
                    meses2016
                };

                this.Linechart = this.inicializarChart(meses);
                this.Linechart.update();

                Chart.defaults.global.defaultFontSize = 18;
                this.yAxisElements = this.Linechart.scales['y-axis-0'].ticks;

                console.log('mensual', this.yAxisElements.length);

                if (this.yAxisElements.length === 10) {
                    this.estiloEjeY = '10.5%';
                } else if (this.yAxisElements.length === 9) {
                    this.estiloEjeY = '24.4%';
                } else if (this.yAxisElements.length === 8) {
                    this.estiloEjeY = '38%';
                } else if (this.yAxisElements.length === 7) {
                    this.estiloEjeY = '57%';
                } else if (this.yAxisElements.length === 11) {
                    this.estiloEjeY = '3%';
                } else if (this.yAxisElements.length === 6) {
                    this.estiloEjeY = '86%';
                }

                if (this.mesSelect && this.mesSelect !== '') {
                    this.cuadro(this.mesSelect);
                }

                this.filtrosVentana = false;
                this.spinner.hide();
            });
    }

    private inicializarChart(meses): Chart {
        return new Chart('line-canvas', {
            type: 'line',
            data: {
                labels: this.lineChartLabels,
                datasets: [
                    {
                        data: meses.meses2020,
                        borderColor: '#cdebff',
                        backgroundColor: this.backgroundColorDefault,
                        borderWidth: 3
                    },
                    {
                        data: meses.meses2019,
                        borderColor: '#d2beda',
                        backgroundColor: this.backgroundColorDefault,
                        borderWidth: 3
                    },
                    {
                        data: meses.meses2018,
                        borderColor: '#ffb177',
                        backgroundColor: this.backgroundColorDefault,
                        borderWidth: 3
                    },
                    {
                        data: meses.meses2017,
                        borderColor: '#d88fc2',
                        backgroundColor: this.backgroundColorDefault,
                        borderWidth: 3
                    },
                    {
                        data: meses.meses2016,
                        borderColor: '#ff9ca6',
                        backgroundColor: this.backgroundColorDefault,
                        borderWidth: 3
                    }
                ]
            },
            options: {
                responsive: true,
                layout: {
                    padding: 45
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    mode: 'index',
                    displayColors: false,
                    backgroundColor: '#EAF0FB',
                    bodyFontColor: '#343F61',
                    titleFontColor: '#343F61',
                    xPadding: 25,
                    yPadding: 15,
                    callbacks: {
                        label: (tooltipItems, data) => this.cambioLabel(tooltipItems, data),
                        title(tooltipItem, data): string | string[] {
                            let etiqueta = data.labels[tooltipItem[0].index];
                            if (etiqueta === 'Sept.') {
                                etiqueta = 'Septiembre';
                            }
                            if (etiqueta === 'Nov.') {
                                etiqueta = 'Noviembre';
                            }
                            return etiqueta.toString();
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            gridLines: {
                                display: true
                            },
                            ticks: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: false
                            }
                        }
                    ]
                }
            }
        });
    }

    private obtenerMeses(acumuladosVida: any[]): number[] {
        let enero = 0;
        let febrero = 0;
        let marzo = 0;
        let abril = 0;
        let mayo = 0;
        let junio = 0;
        let julio = 0;
        let agosto = 0;
        let septiembre = 0;
        let octubre = 0;
        let noviembre = 0;
        let diciembre = 0;

        acumuladosVida.forEach((acumulado) => {
            switch (acumulado.mes) {
                case 1:
                    enero +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 2:
                    febrero +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 3:
                    marzo +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 4:
                    abril +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 5:
                    mayo +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 6:
                    junio +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 7:
                    julio +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 8:
                    agosto +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 9:
                    septiembre +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 10:
                    octubre +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 11:
                    noviembre +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 12:
                    diciembre +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                default:
                    break;
            }
        });

        return [
            enero,
            febrero,
            marzo,
            abril,
            mayo,
            junio,
            julio,
            agosto,
            septiembre,
            octubre,
            noviembre,
            diciembre
        ];
    }

    // tslint:disable-next-line: no-big-function cognitive-complexity
    private obtenerDatosMensuales(mes): any {
        let filtroMes: any;

        const MontosTotalesAnualesMensuales = [
            { a: this.year1, m: '0', i: '0', signo: '' },
            { a: this.year2, m: '0', i: '0', signo: '' },
            { a: this.year3, m: '0', i: '0', signo: '' },
            { a: this.year4, m: '0', i: '0', signo: '' },
            { a: this.year5, m: '0', i: '0', signo: '' }
        ];

        if (mes === 'Enero') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 1);
        }
        if (mes === 'Febrero') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 2);
        }
        if (mes === 'Marzo') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 3);
        }
        if (mes === 'Abril') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 4);
        }
        if (mes === 'Mayo') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 5);
        }
        if (mes === 'Junio') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 6);
        }
        if (mes === 'Julio') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 7);
        }
        if (mes === 'Agosto') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 8);
        }
        if (mes === 'Septiembre') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 9);
        }
        if (mes === 'Octubre') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 10);
        }
        if (mes === 'Noviembre') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 11);
        }
        if (mes === 'Diciembre') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 12);
        }

        const comisionesMensual2015 = filtroMes.filter((item) => item.anio === this.year1 - 5);
        let sumaComisionesMensual2015 = 0;
        comisionesMensual2015.forEach((element) => {
            sumaComisionesMensual2015 =
                sumaComisionesMensual2015 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });

        /**********************************************************************************/
        const comisionesMensualVida1 = filtroMes.filter((item) => item.anio === this.year5);
        let sumaComisionesMensual1 = 0;
        comisionesMensualVida1.forEach((element) => {
            sumaComisionesMensual1 =
                sumaComisionesMensual1 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });
        MontosTotalesAnualesMensuales[4].m = sumaComisionesMensual1.toFixed(2);

        let incrementoMensual2016: number;

        if (sumaComisionesMensual2015 <= 0 && sumaComisionesMensual1 > 0) {
            incrementoMensual2016 = 100;
        } else if (sumaComisionesMensual2015 > 0 && sumaComisionesMensual1 <= 0) {
            incrementoMensual2016 = -100;
        } else if (sumaComisionesMensual2015 <= 0 && sumaComisionesMensual1 <= 0) {
            incrementoMensual2016 = 0;
        } else if (sumaComisionesMensual2015 > 0 && sumaComisionesMensual1 > 0) {
            incrementoMensual2016 = (sumaComisionesMensual1 / sumaComisionesMensual2015 - 1) * 100;
        } else {
            incrementoMensual2016 = -100;
        }

        MontosTotalesAnualesMensuales[4].i = incrementoMensual2016.toFixed(2);

        /**********************************************************************************/
        const comisionesMensualVida2 = filtroMes.filter((item) => item.anio === this.year4);
        let sumaComisionesMensual2 = 0;
        comisionesMensualVida2.forEach((element) => {
            sumaComisionesMensual2 =
                sumaComisionesMensual2 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });
        MontosTotalesAnualesMensuales[3].m = sumaComisionesMensual2.toFixed(2);

        let incrementoMensual2017: number;

        if (sumaComisionesMensual1 <= 0 && sumaComisionesMensual2 > 0) {
            incrementoMensual2017 = 100;
        } else if (sumaComisionesMensual1 > 0 && sumaComisionesMensual2 <= 0) {
            incrementoMensual2017 = -100;
        } else if (sumaComisionesMensual1 <= 0 && sumaComisionesMensual2 <= 0) {
            incrementoMensual2017 = 0;
        } else if (sumaComisionesMensual1 > 0 && sumaComisionesMensual2 > 0) {
            incrementoMensual2017 = (sumaComisionesMensual2 / sumaComisionesMensual1 - 1) * 100;
        } else {
            incrementoMensual2017 = -100;
        }

        MontosTotalesAnualesMensuales[3].i = incrementoMensual2017.toFixed(2);

        /**********************************************************************************/
        const comisionesMensualVida3 = filtroMes.filter((item) => item.anio === this.year3);
        let sumaComisionesMensual3 = 0;
        comisionesMensualVida3.forEach((element) => {
            sumaComisionesMensual3 =
                sumaComisionesMensual3 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });
        MontosTotalesAnualesMensuales[2].m = sumaComisionesMensual3.toFixed(2);

        let incrementoMensual2018: number;

        if (sumaComisionesMensual2 <= 0 && sumaComisionesMensual3 > 0) {
            incrementoMensual2018 = 100;
        } else if (sumaComisionesMensual2 > 0 && sumaComisionesMensual3 <= 0) {
            incrementoMensual2018 = -100;
        } else if (sumaComisionesMensual2 <= 0 && sumaComisionesMensual3 <= 0) {
            incrementoMensual2018 = 0;
        } else if (sumaComisionesMensual2 > 0 && sumaComisionesMensual3 > 0) {
            incrementoMensual2018 = (sumaComisionesMensual3 / sumaComisionesMensual2 - 1) * 100;
        } else {
            incrementoMensual2018 = -100;
        }

        MontosTotalesAnualesMensuales[2].i = incrementoMensual2018.toFixed(2);

        /**********************************************************************************/

        const comisionesMensualVida4 = filtroMes.filter((item) => item.anio === this.year2);
        let sumaComisionesMensual4 = 0;
        comisionesMensualVida4.forEach((element) => {
            sumaComisionesMensual4 =
                sumaComisionesMensual4 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });
        MontosTotalesAnualesMensuales[1].m = sumaComisionesMensual4.toFixed(2);

        let incrementoMensual2019: number;

        if (sumaComisionesMensual3 <= 0 && sumaComisionesMensual4 > 0) {
            incrementoMensual2019 = 100;
        } else if (sumaComisionesMensual3 > 0 && sumaComisionesMensual4 <= 0) {
            incrementoMensual2019 = -100;
        } else if (sumaComisionesMensual3 <= 0 && sumaComisionesMensual4 <= 0) {
            incrementoMensual2019 = 0;
        } else if (sumaComisionesMensual3 > 0 && sumaComisionesMensual4 > 0) {
            incrementoMensual2019 = (sumaComisionesMensual4 / sumaComisionesMensual3 - 1) * 100;
        } else {
            incrementoMensual2019 = -100;
        }

        MontosTotalesAnualesMensuales[1].i = incrementoMensual2019.toFixed(2);

        /**********************************************************************************/
        const comisionesMensualVida5 = filtroMes.filter((item) => item.anio === this.year1);
        let sumaComisionesMensual5 = 0;
        comisionesMensualVida5.forEach((element) => {
            sumaComisionesMensual5 =
                sumaComisionesMensual5 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });
        MontosTotalesAnualesMensuales[0].m = sumaComisionesMensual5.toFixed(2);

        let incrementoMensual2020: number;

        if (sumaComisionesMensual4 <= 0 && sumaComisionesMensual5 > 0) {
            incrementoMensual2020 = 100;
        } else if (sumaComisionesMensual4 > 0 && sumaComisionesMensual5 <= 0) {
            incrementoMensual2020 = -100;
        } else if (sumaComisionesMensual4 <= 0 && sumaComisionesMensual5 <= 0) {
            incrementoMensual2020 = 0;
        } else if (sumaComisionesMensual4 > 0 && sumaComisionesMensual5 > 0) {
            incrementoMensual2020 = (sumaComisionesMensual5 / sumaComisionesMensual4 - 1) * 100;
        } else {
            incrementoMensual2020 = -100;
        }

        MontosTotalesAnualesMensuales[0].i = incrementoMensual2020.toFixed(2);

        /**********************************************************************************/

        if (parseFloat(MontosTotalesAnualesMensuales[0].i) < 0) {
            MontosTotalesAnualesMensuales[0].signo = '(-)';
        } else {
            MontosTotalesAnualesMensuales[0].signo = '(+)';
        }
        if (parseFloat(MontosTotalesAnualesMensuales[1].i) < 0) {
            MontosTotalesAnualesMensuales[1].signo = '(-)';
        } else {
            MontosTotalesAnualesMensuales[1].signo = '(+)';
        }
        if (parseFloat(MontosTotalesAnualesMensuales[2].i) < 0) {
            MontosTotalesAnualesMensuales[2].signo = '(-)';
        } else {
            MontosTotalesAnualesMensuales[2].signo = '(+)';
        }
        if (parseFloat(MontosTotalesAnualesMensuales[3].i) < 0) {
            MontosTotalesAnualesMensuales[3].signo = '(-)';
        } else {
            MontosTotalesAnualesMensuales[3].signo = '(+)';
        }
        if (parseFloat(MontosTotalesAnualesMensuales[4].i) < 0) {
            MontosTotalesAnualesMensuales[4].signo = '(-)';
        } else {
            MontosTotalesAnualesMensuales[4].signo = '(+)';
        }

        return MontosTotalesAnualesMensuales;
    }

    sortGenerales(e: Sort): void {
        const data = this.MontosTotalesAnualesMensuales.data;
        if (!e.active || e.direction === '') {
            this.MontosTotalesAnualesMensuales.data = data;
            return;
        }

        this.MontosTotalesAnualesMensuales.data = data.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'a':
                    return this.compare(a.a, b.a, isAsc);
                case 'm':
                    return this.compare(a.m, b.m, isAsc);
                case 'i':
                    return this.compare(a.i, b.i, isAsc);
                default:
                    return 0;
            }
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    resetButtonsMesesSelected(): void {
        this.mesesSelected = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false
        ];
    }

    removeMatChipFiltro(matChipItem): void {
        console.log(matChipItem);

        if (matChipItem.tipo === 'mes') {
            this.filtros = this.filtros.filter((element) => element.valor !== matChipItem.valor);
            this.mesSelect = null;
            this.resetButtonsMesesSelected();
            this.filtroAnualMensualSinRestriccion();
        }

        if (matChipItem.tipo === 'concepto') {
            this.filtros = this.filtros.filter((element) => element.valor !== matChipItem.valor);
            this.validarFiltroConcepto2(0);
            this.filtroAnualMensualSinRestriccion();
        }

        if (matChipItem.tipo === 'ramo') {
            this.filtros = this.filtros.filter((element) => element.valor !== matChipItem.valor);
            this.validarFiltroRamo2(0);
            this.filtroAnualMensualSinRestriccion();
        }
    }

    filtroAnualMensualSinRestriccion(): void {
        const ramoConcepto = this.obtenerRamoConcepto();

        this.obtenerDatosFiltro(ramoConcepto.concepto, ramoConcepto.ramo);
    }
}

export interface Tablas {
    a: number;
    m: string;
    i: string;
    circulo: string;
}

export interface FiltroMensual {
    anio: number;
    comprobante: string;
    mes: number;
    unidadNegocio: string;
    valor: number;
}

export interface DatosHistorico {
    anio: number;
    mes: number;
    acumuladoFacturasNotasCreditoPeriodo: number;
    importeTotalAcumuladoFacturasPeriodo: number;
}

export interface DatosSimplificados {
    anio: number;
    mes: number;
    cantidad: number;
    incremento: number;
}

interface MatChipFiltro {
    valor: string;
    tipo: string;
}
