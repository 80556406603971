import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, Sort } from '@angular/material';
import { Chart } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({ selector: 'app-comparativo-anual', templateUrl: './comparativo-anual.component.html' })
export class ComparativoAnualComponent implements OnInit {
    constructor(private http: HttpClient, private spinner: NgxSpinnerService) {}
    filtrosVentana = false;
    btnComisiones = false;
    btnBonos = false;
    btnMovimientos = false;
    btnVida = false;
    btnSalud = false;
    btnAutos = false;
    btnDanos = false;
    data: DatosHistorico[];
    barchart: Chart;
    tablaDatos = [];
    MontosTotalesAnuales: any;
    datos: DatosSimplificados[] = [];
    displayedColumns: string[] = ['circulo', 'a', 'm', 'i'];
    totalAnualAcumulado: any;
    acumulado: any;
    totales: any;
    incremento: any;
    tamanio: string;
    year1: any;
    year2: any;
    year3: any;
    year4: any;
    year5: any;
    estiloEjeY: string;

    filtros: MatChipFiltro[];
    conceptoMatChip: string;
    ramoMatChip: string;

    public yAxisElements: string[] = [];

    selectable = true;
    removable = true;

    ngOnInit(): void {
        const fecha = new Date();
        this.year1 = fecha.getFullYear();
        this.year2 = this.year1 - 1;
        this.year3 = this.year1 - 2;
        this.year4 = this.year1 - 3;
        this.year5 = this.year1 - 4;

        this.MontosTotalesAnuales = new MatTableDataSource<Tablas>([
            { circulo: '#D1EBFF', a: this.year1, m: '0', i: '0' },
            { circulo: '#CEC0D8', a: this.year2, m: '0', i: '0' },
            { circulo: '#FCB383', a: this.year3, m: '0', i: '0' },
            { circulo: '#CE96C0', a: this.year4, m: '0', i: '0' },
            { circulo: '#F6A2A9', a: this.year5, m: '0', i: '0' }
        ]);

        this.armarGraficaTablaInicio();
    }

    private armarGraficaTablaInicio(): void {
        this.conceptoMatChip = null;
        this.ramoMatChip = null;
        this.filtros = [];
        this.obtenerDatosFiltro('vacio', 'vacio');
    }

    validarFiltroConcepto(i: number): void {
        switch (i) {
            case 1:
                this.btnComisiones = !this.btnComisiones;
                this.btnBonos = false;
                this.btnMovimientos = false;
                break;
            case 2:
                this.btnComisiones = false;
                this.btnBonos = false;
                this.btnMovimientos = !this.btnMovimientos;
                break;
            case 3:
                this.btnComisiones = false;
                this.btnBonos = !this.btnBonos;
                this.btnMovimientos = false;
                break;
            default:
                this.btnComisiones = false;
                this.btnBonos = false;
                this.btnMovimientos = false;
        }
    }

    validarFiltroRamo(i: number): void {
        switch (i) {
            case 1:
                this.btnVida = !this.btnVida;
                this.btnSalud = false;
                this.btnAutos = false;
                this.btnDanos = false;
                break;
            case 2:
                this.btnVida = false;
                this.btnSalud = !this.btnSalud;
                this.btnAutos = false;
                this.btnDanos = false;
                break;
            case 3:
                this.btnVida = false;
                this.btnSalud = false;
                this.btnAutos = !this.btnAutos;
                this.btnDanos = false;
                break;
            case 4:
                this.btnVida = false;
                this.btnSalud = false;
                this.btnAutos = false;
                this.btnDanos = !this.btnDanos;
                break;
            default:
                this.btnVida = false;
                this.btnSalud = false;
                this.btnAutos = false;
                this.btnDanos = false;
        }
    }

    borrarFiltros(): void {
        this.armarGraficaTablaInicio();
        this.validarFiltroConcepto(0);
        this.validarFiltroRamo(0);
        this.filtrosVentana = false;
    }

    private obtenerRamoConcepto(): any {
        let concepto;
        this.filtros = this.filtros.filter((element) => element.valor !== this.conceptoMatChip);
        if (this.btnComisiones) {
            concepto = 'comisiones';
            this.conceptoMatChip = 'Comisiones';
        }
        if (this.btnBonos) {
            concepto = 'bonos';
            this.conceptoMatChip = 'Bonos';
        }
        if (this.btnMovimientos) {
            concepto = 'otros';
            this.conceptoMatChip = 'Otros movimientos';
        }
        if (concepto === undefined || concepto === null || concepto === '') {
            concepto = 'vacio';
            this.conceptoMatChip = null;
        } else {
            this.filtros.push({
                valor: this.conceptoMatChip,
                tipo: 'concepto'
            });
        }

        let ramo;
        this.filtros = this.filtros.filter((element) => element.valor !== this.ramoMatChip);
        if (this.btnVida) {
            ramo = 'VIDA';
            this.ramoMatChip = 'Vida';
        }
        if (this.btnSalud) {
            ramo = 'SA';
            this.ramoMatChip = 'Salud';
        }
        if (this.btnAutos) {
            ramo = 'AU';
            this.ramoMatChip = 'Autos';
        }
        if (this.btnDanos) {
            ramo = 'DA';
            this.ramoMatChip = 'Daños';
        }
        if (ramo === undefined || ramo === null || ramo === '') {
            ramo = 'vacio';
            this.ramoMatChip = null;
        } else {
            this.filtros.push({
                valor: this.ramoMatChip,
                tipo: 'ramo'
            });
        }

        return {
            ramo,
            concepto
        };
    }

    filtroAnual(): void {
        const ramoConcepto = this.obtenerRamoConcepto();

        if (ramoConcepto.ramo === 'vacio' && ramoConcepto.concepto === 'vacio') {
            return;
        }

        this.obtenerDatosFiltro(ramoConcepto.concepto, ramoConcepto.ramo);
    }

    private obtenerDatosFiltro(concepto: string, ramo: string): void {
        this.spinner.show();

        const cua = localStorage.getItem('cua');
        const httpOptions = {
            headers: new HttpHeaders({ ['Content-Type']: 'application/json', ['apiKey']: environment.apikey })
        };
        this.http
            .get(
                environment.apiFiltro + concepto + '/agente/' + cua + '/unidad/negocio/' + ramo + '/tiempo/5',
                httpOptions
            )
            .pipe(first())
            .subscribe((data: Filtro[]) => {
                console.log(data);

                this.calculateYears(data);

                const total2020 = this.MontosTotalesAnuales.data[0].m;
                const total2019 = this.MontosTotalesAnuales.data[1].m;
                const total2018 = this.MontosTotalesAnuales.data[2].m;
                const total2017 = this.MontosTotalesAnuales.data[3].m;
                const total2016 = this.MontosTotalesAnuales.data[4].m;

                const totalAnualAcumulado = [total2020, total2019, total2018, total2017, total2016];

                if (this.barchart) {
                    this.barchart.destroy();
                }
                this.barchart = this.inicializarChart(totalAnualAcumulado);
                this.barchart.update();

                Chart.defaults.global.defaultFontSize = 18;
                this.yAxisElements = null;
                // tslint:disable-next-line: no-string-literal
                this.yAxisElements = this.barchart['scales']['y-axis-0'].ticks;

                console.log('anual', this.yAxisElements.length);

                if (this.yAxisElements.length === 10) {
                    this.estiloEjeY = '9%';
                } else if (this.yAxisElements.length === 11) {
                    this.estiloEjeY = '0%';
                } else if (this.yAxisElements.length === 8) {
                    this.estiloEjeY = '33%';
                } else if (this.yAxisElements.length === 9) {
                    this.estiloEjeY = '19%';
                } else if (this.yAxisElements.length === 6) {
                    this.estiloEjeY = '80%';
                } else if (this.yAxisElements.length === 7) {
                    this.estiloEjeY = '51%';
                }

                this.spinner.hide();
                this.filtrosVentana = false;
            });
    }

    // tslint:disable-next-line: cognitive-complexity
    private calculateYears(data): void {
        /*********************** 2015 ***********************************************************/
        const comisionesMensual2015 = data.filter((item) => item.anio === this.year1 - 5);
        let sumaComisionesMensual2015 = 0;
        comisionesMensual2015.forEach((element) => {
            sumaComisionesMensual2015 = sumaComisionesMensual2015 + element.valor;
        });

        /************************ 2016 **********************************************************/
        const comisionesMensualVida1 = data.filter((item) => item.anio === this.year5);
        let sumaComisionesMensual1 = 0;
        comisionesMensualVida1.forEach((element) => {
            sumaComisionesMensual1 = sumaComisionesMensual1 + element.valor;
        });
        this.MontosTotalesAnuales.data[4].m = sumaComisionesMensual1.toFixed(2);

        let incrementoMensual2016: number;
        let suma1 = sumaComisionesMensual1 / sumaComisionesMensual2015;
        if (sumaComisionesMensual1 === 0 && sumaComisionesMensual2015 === 0) {
            incrementoMensual2016 = 0;
        } else if (sumaComisionesMensual1 < sumaComisionesMensual2015 && sumaComisionesMensual2015 < 0) {
            incrementoMensual2016 = (sumaComisionesMensual1 / sumaComisionesMensual2015 - 1) * 100;
        } else if (sumaComisionesMensual1 === 0 && sumaComisionesMensual2015 < 0) {
            incrementoMensual2016 = -100;
        } else if (sumaComisionesMensual2015 === 0) {
            incrementoMensual2016 = 100;
        } else if (sumaComisionesMensual1 === 0) {
            incrementoMensual2016 = 100;
        } else if (
            suma1 === Infinity ||
            suma1 === -Infinity ||
            Object.is(suma1, NaN) ||
            suma1 === 0 ||
            suma1 === -0
        ) {
            suma1 = 0;
            incrementoMensual2016 = (suma1 - 1) * 100;
        } else {
            incrementoMensual2016 = (sumaComisionesMensual1 / sumaComisionesMensual2015 - 1) * 100;
        }
        this.MontosTotalesAnuales.data[4].i = incrementoMensual2016.toFixed(2);

        /**************************** 2017 ******************************************************/
        const comisionesMensualVida2 = data.filter((item) => item.anio === this.year4);
        let sumaComisionesMensual2 = 0;
        comisionesMensualVida2.forEach((element) => {
            sumaComisionesMensual2 = sumaComisionesMensual2 + element.valor;
        });
        this.MontosTotalesAnuales.data[3].m = sumaComisionesMensual2.toFixed(2);

        let incrementoMensual2017: number;
        let suma2 = sumaComisionesMensual2 / sumaComisionesMensual1;
        if (sumaComisionesMensual1 === 0 && sumaComisionesMensual2 === 0) {
            incrementoMensual2017 = 0;
        } else if (sumaComisionesMensual2 < sumaComisionesMensual1 && sumaComisionesMensual1 < 0) {
            incrementoMensual2017 = (sumaComisionesMensual2 / sumaComisionesMensual1 - 1) * 100;
        } else if (sumaComisionesMensual1 === 0 && sumaComisionesMensual2 < 0) {
            incrementoMensual2017 = -100;
        } else if (sumaComisionesMensual1 === 0) {
            incrementoMensual2017 = 100;
        } else if (
            suma2 === Infinity ||
            suma2 === -Infinity ||
            Object.is(suma2, NaN) ||
            suma2 === 0 ||
            suma2 === -0
        ) {
            suma2 = 0;
            incrementoMensual2017 = (suma2 - 1) * 100;
        } else {
            incrementoMensual2017 = (sumaComisionesMensual2 / sumaComisionesMensual1 - 1) * 100;
        }
        this.MontosTotalesAnuales.data[3].i = incrementoMensual2017.toFixed(2);

        /*************************** 2018 *******************************************************/
        const comisionesMensualVida3 = data.filter((item) => item.anio === this.year3);
        let sumaComisionesMensual3 = 0;
        comisionesMensualVida3.forEach((element) => {
            sumaComisionesMensual3 = sumaComisionesMensual3 + element.valor;
        });
        this.MontosTotalesAnuales.data[2].m = sumaComisionesMensual3.toFixed(2);

        let incrementoMensual2018: number;
        let suma3 = sumaComisionesMensual3 / sumaComisionesMensual2;
        if (sumaComisionesMensual2 === 0 && sumaComisionesMensual3 === 0) {
            incrementoMensual2018 = 0;
        } else if (sumaComisionesMensual3 < sumaComisionesMensual2) {
            incrementoMensual2018 = (sumaComisionesMensual3 / sumaComisionesMensual2 - 1) * 100;
        } else if (sumaComisionesMensual2 === 0 && sumaComisionesMensual3 < 0) {
            incrementoMensual2018 = -100;
        } else if (sumaComisionesMensual2 === 0) {
            incrementoMensual2018 = 100;
        } else if (
            suma3 === Infinity ||
            suma3 === -Infinity ||
            Object.is(suma3, NaN) ||
            suma3 === 0 ||
            suma3 === -0
        ) {
            suma3 = 0;
            incrementoMensual2018 = (suma3 - 1) * 100;
        } else {
            incrementoMensual2018 = (sumaComisionesMensual3 / sumaComisionesMensual2 - 1) * 100;
            console.log(incrementoMensual2018);
        }
        this.MontosTotalesAnuales.data[2].i = incrementoMensual2018.toFixed(2);

        /************************** 2019 ********************************************************/
        const comisionesMensualVida4 = data.filter((item) => item.anio === this.year2);
        let sumaComisionesMensual4 = 0;
        comisionesMensualVida4.forEach((element) => {
            sumaComisionesMensual4 = sumaComisionesMensual4 + element.valor;
        });
        this.MontosTotalesAnuales.data[1].m = sumaComisionesMensual4.toFixed(2);

        let incrementoMensual2019: number;
        let suma4 = sumaComisionesMensual4 / sumaComisionesMensual3;
        if (sumaComisionesMensual3 === 0 && sumaComisionesMensual4 === 0) {
            incrementoMensual2019 = 0;
        } else if (sumaComisionesMensual4 < sumaComisionesMensual3) {
            incrementoMensual2019 = (sumaComisionesMensual4 / sumaComisionesMensual3 - 1) * 100;
        } else if (sumaComisionesMensual3 === 0 && sumaComisionesMensual4 < 0) {
            incrementoMensual2019 = -100;
        } else if (sumaComisionesMensual3 === 0) {
            incrementoMensual2019 = 100;
        } else if (
            suma4 === Infinity ||
            suma4 === -Infinity ||
            Object.is(suma4, NaN) ||
            suma4 === 0 ||
            suma4 === -0
        ) {
            suma4 = 0;
            incrementoMensual2019 = (suma4 - 1) * 100;
        } else {
            incrementoMensual2019 = (sumaComisionesMensual4 / sumaComisionesMensual3 - 1) * 100;
        }
        this.MontosTotalesAnuales.data[1].i = incrementoMensual2019.toFixed(2);

        /************************** 2020 ********************************************************/
        const comisionesMensualVida5 = data.filter((item) => item.anio === this.year1);
        let sumaComisionesMensual5 = 0;
        comisionesMensualVida5.forEach((element) => {
            sumaComisionesMensual5 = sumaComisionesMensual5 + element.valor;
        });
        this.MontosTotalesAnuales.data[0].m = sumaComisionesMensual5.toFixed(2);

        let incrementoMensual2020: number;
        let suma5 = sumaComisionesMensual5 / sumaComisionesMensual4;

        if (sumaComisionesMensual5 === 0 && sumaComisionesMensual4 === 0) {
            incrementoMensual2020 = 0;
        } else if (sumaComisionesMensual5 < sumaComisionesMensual4 && sumaComisionesMensual4 < 0) {
            incrementoMensual2020 = (sumaComisionesMensual5 / sumaComisionesMensual4 - 1) * 100;
        } else if (sumaComisionesMensual4 === 0 && sumaComisionesMensual5 < 0) {
            incrementoMensual2020 = -100;
        } else if (sumaComisionesMensual4 === 0) {
            incrementoMensual2020 = 100;
        } else if (
            suma5 === Infinity ||
            suma5 === -Infinity ||
            Object.is(suma5, NaN) ||
            suma5 === 0 ||
            suma5 === -0
        ) {
            suma5 = 0;
            incrementoMensual2020 = (suma5 - 1) * 100;
        } else {
            incrementoMensual2020 = (sumaComisionesMensual5 / sumaComisionesMensual4 - 1) * 100;
        }
        this.MontosTotalesAnuales.data[0].i = incrementoMensual2020.toFixed(2);

        /**********************************************************************************************/
    }

    private inicializarChart(data: number[]): Chart {
        return new Chart('bar canvas', {
            type: 'bar',
            data: {
                labels: [this.year1, this.year2, this.year3, this.year4, this.year5],
                datasets: [
                    {
                        data,
                        borderColor: '#3cba9f',
                        backgroundColor: ['#cdebff', '#d2beda', '#ffb177', '#d88fc2', '#ff9ca6'],
                        fill: true
                    }
                ]
            },
            options: {
                layout: {
                    padding: 45
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    displayColors: false,
                    backgroundColor: '#EAF0FB',
                    bodyFontColor: '#343F61',
                    titleFontColor: '#343F61',
                    xPadding: 25,
                    yPadding: 15,
                    callbacks: {
                        title(tooltipItem, dataX): string | string[] {
                            return 'Total ' + dataX.labels[tooltipItem[0].index];
                        },
                        label(tooltipItems): string | string[] {
                            return (
                                '$' +
                                Number(tooltipItems.yLabel.toString())
                                    .toFixed(0)
                                    .replace(/./g, (c, i, a) => {
                                        return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c;
                                    })
                            );
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            display: true
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: false,
                                beginAtZero: true
                            }
                        }
                    ]
                }
            }
        });
    }

    sortGenerales(e: Sort): void {
        const data = this.MontosTotalesAnuales.data;
        if (!e.active || e.direction === '') {
            this.MontosTotalesAnuales.data = data;
            return;
        }

        this.MontosTotalesAnuales.data = data.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'a':
                    return this.compare(a.a, b.a, isAsc);
                case 'm':
                    return this.compare(a.m, b.m, isAsc);
                case 'i':
                    return this.compare(a.i, b.i, isAsc);
                default:
                    return 0;
            }
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    removeMatChipFiltro(matChipItem): void {
        if (matChipItem.tipo === 'concepto') {
            this.filtros = this.filtros.filter((element) => element.valor !== matChipItem.valor);
            this.validarFiltroConcepto(0);
            this.filtroAnualSinRestriccion();
        }

        if (matChipItem.tipo === 'ramo') {
            this.filtros = this.filtros.filter((element) => element.valor !== matChipItem.valor);
            this.validarFiltroRamo(0);
            this.filtroAnualSinRestriccion();
        }
    }

    filtroAnualSinRestriccion(): void {
        const ramoConcepto = this.obtenerRamoConcepto();

        this.obtenerDatosFiltro(ramoConcepto.concepto, ramoConcepto.ramo);
    }
}

export interface Tablas {
    a: number;
    m: string;
    i: string;
    circulo: string;
}

export interface Filtro {
    anio: number;
    comprobante: string;
    mes: number;
    unidadNegocio: string;
    valor: number;
}

export interface DatosHistorico {
    anio: number;
    mes: number;
    acumuladoFacturasNotasCreditoPeriodo: number;
    importeTotalAcumuladoFacturasPeriodo: number;
}

export interface DatosSimplificados {
    anio: number;
    mes: number;
    cantidad: number;
    incremento: number;
}

interface MatChipFiltro {
    valor: string;
    tipo: string;
}
