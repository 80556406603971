import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, Sort } from '@angular/material';
import { Chart } from 'chart.js';
import { Label } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-comparativo-total-acumulado',
    templateUrl: './comparativo-total-acumulado.component.html'
})
export class ComparativoTotalAcumuladoComponent implements OnInit {
    @ViewChild('mensualSort', { static: false }) set matMensualSort(mensualSort: MatSort) {
        if (
            this.MontosTotalesAnualesMensualesAcumulados !== null &&
            this.MontosTotalesAnualesMensualesAcumulados !== undefined
        ) {
            this.MontosTotalesAnualesMensualesAcumulados.sort = mensualSort;
        }
    }

    filtroMes: DatosHistorico[];
    mesSelect: any;
    filtrosVentana = false;
    btnComisiones3 = false;
    btnBonos3 = false;
    btnMovimientos3 = false;
    btnVida3 = false;
    btnSalud3 = false;
    btnAutos3 = false;
    btnDanos3 = false;
    data: DatosHistorico[];
    Linechart: Chart;
    tablaDatos = [];
    datos: DatosSimplificados[] = [];
    displayedColumns: string[] = ['circulo', 'a', 'm', 'i'];
    totalAnualAcumulado: any;
    acumulado: any;
    totales: any;
    MontosTotalesAnualesMensualesAcumulados: any;
    incremento: any;
    year1: any;
    year2: any;
    year3: any;
    year4: any;
    year5: any;
    tamanio: string;
    message: boolean;
    mes: any;
    signo1: string;
    signo2: string;
    signo3: string;
    signo4: string;
    signo5: string;
    anio1: number;
    anio2: number;
    anio3: number;
    anio4: number;
    anio5: number;
    estilo: string;
    incremento1: number;
    incremento5: number;
    incremento4: any;
    incremento3: any;
    incremento2: any;
    filtroMes2: FiltroMensualAcumulado[] = [];
    filtroAnual: FiltroMensualAcumulado[] = [];
    estiloEjeY: string;
    private backgroundColorDefault = 'rgba(0, 0, 0, 0.0)';

    public yAxisElements: string[] = [];
    public lineChartLabels: Label[] = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];

    filtros: MatChipFiltro[];
    conceptoMatChip: string;
    ramoMatChip: string;
    mesMatChip: string;

    mesesSelected: boolean[];

    selectable = true;
    removable = true;

    constructor(private http: HttpClient, private spinner: NgxSpinnerService) {}

    ngOnInit(): void {
        const fecha = new Date();
        this.year1 = fecha.getFullYear();
        this.year2 = this.year1 - 1;
        this.year3 = this.year1 - 2;
        this.year4 = this.year1 - 3;
        this.year5 = this.year1 - 4;

        this.MontosTotalesAnualesMensualesAcumulados = new MatTableDataSource<Tablas>([
            { circulo: '#D1EBFF', a: this.year1, m: '0', i: '0' },
            { circulo: '#CEC0D8', a: this.year2, m: '0', i: '0' },
            { circulo: '#FCB383', a: this.year3, m: '0', i: '0' },
            { circulo: '#CE96C0', a: this.year4, m: '0', i: '0' },
            { circulo: '#F6A2A9', a: this.year5, m: '0', i: '0' }
        ]);

        this.armarGraficaTablaInicio();
    }

    private armarGraficaTablaInicio(): void {
        this.conceptoMatChip = null;
        this.ramoMatChip = null;
        this.mesMatChip = null;
        this.filtros = [];
        this.resetButtonsMesesSelected();
        this.obtenerDatosFiltro('vacio', 'vacio');
    }

    cambioLabel(tooltipItems): string {
        const datos = this.obtenerDatosMensuales(tooltipItems.label);

        if (tooltipItems.datasetIndex === 0) {
            const element = datos.filter((item) => item.a === this.year1);
            return element[0].a + '    ' + element[0].signo + '   ' + element[0].i + '%';
        }

        if (tooltipItems.datasetIndex === 1) {
            const element = datos.filter((item) => item.a === this.year2);
            return element[0].a + '    ' + element[0].signo + '   ' + element[0].i + '%';
        }

        if (tooltipItems.datasetIndex === 2) {
            const element = datos.filter((item) => item.a === this.year3);
            return element[0].a + '    ' + element[0].signo + '   ' + element[0].i + '%';
        }

        if (tooltipItems.datasetIndex === 3) {
            const element = datos.filter((item) => item.a === this.year4);
            return element[0].a + '    ' + element[0].signo + '   ' + element[0].i + '%';
        }

        if (tooltipItems.datasetIndex === 4) {
            const element = datos.filter((item) => item.a === this.year5);
            return element[0].a + '    ' + element[0].signo + '   ' + element[0].i + '%';
        }
    }

    validarFiltroConcepto3(i: number): void {
        switch (i) {
            case 1:
                this.btnComisiones3 = !this.btnComisiones3;
                this.btnBonos3 = false;
                this.btnMovimientos3 = false;
                break;
            case 2:
                this.btnComisiones3 = false;
                this.btnBonos3 = false;
                this.btnMovimientos3 = !this.btnMovimientos3;
                break;
            case 3:
                this.btnComisiones3 = false;
                this.btnBonos3 = !this.btnBonos3;
                this.btnMovimientos3 = false;
                break;
            default:
                this.btnComisiones3 = false;
                this.btnBonos3 = false;
                this.btnMovimientos3 = false;
        }
    }

    validarFiltroRamo3(i: number): void {
        switch (i) {
            case 1:
                this.btnVida3 = !this.btnVida3;
                this.btnSalud3 = false;
                this.btnAutos3 = false;
                this.btnDanos3 = false;
                break;
            case 2:
                this.btnVida3 = false;
                this.btnSalud3 = !this.btnSalud3;
                this.btnAutos3 = false;
                this.btnDanos3 = false;
                break;
            case 3:
                this.btnVida3 = false;
                this.btnSalud3 = false;
                this.btnAutos3 = !this.btnAutos3;
                this.btnDanos3 = false;
                break;
            case 4:
                this.btnVida3 = false;
                this.btnSalud3 = false;
                this.btnAutos3 = false;
                this.btnDanos3 = !this.btnDanos3;
                break;
            default:
                this.btnVida3 = false;
                this.btnSalud3 = false;
                this.btnAutos3 = false;
                this.btnDanos3 = false;
        }
    }

    borrarFiltros3(): void {
        this.armarGraficaTablaInicio();
        this.validarFiltroConcepto3(0);
        this.validarFiltroRamo3(0);
        this.mesSelect = '';
        this.filtrosVentana = false;
    }

    private obtenerRamoConcepto(): any {
        let concepto;
        this.filtros = this.filtros.filter((element) => element.valor !== this.conceptoMatChip);
        if (this.btnComisiones3) {
            concepto = 'comisiones';
            this.conceptoMatChip = 'Comisiones';
        }
        if (this.btnBonos3) {
            concepto = 'bonos';
            this.conceptoMatChip = 'Bonos';
        }
        if (this.btnMovimientos3) {
            concepto = 'otros';
            this.conceptoMatChip = 'Otras percepciones';
        }
        if (concepto === undefined || concepto === null || concepto === '') {
            concepto = 'vacio';
            this.conceptoMatChip = null;
        } else {
            this.filtros.push({
                valor: this.conceptoMatChip,
                tipo: 'concepto'
            });
        }

        let ramo;
        this.filtros = this.filtros.filter((element) => element.valor !== this.ramoMatChip);
        if (this.btnVida3) {
            ramo = 'VIDA';
            this.ramoMatChip = 'Vida';
        }
        if (this.btnSalud3) {
            ramo = 'SA';
            this.ramoMatChip = 'Salud';
        }
        if (this.btnAutos3) {
            ramo = 'AU';
            this.ramoMatChip = 'Autos';
        }
        if (this.btnDanos3) {
            ramo = 'DA';
            this.ramoMatChip = 'Daños';
        }
        if (ramo === undefined || ramo === null || ramo === '') {
            ramo = 'vacio';
            this.ramoMatChip = null;
        } else {
            this.filtros.push({
                valor: this.ramoMatChip,
                tipo: 'ramo'
            });
        }

        return {
            ramo,
            concepto
        };
    }

    filtroAnualMensualAcumulado(): void {
        const ramoConcepto = this.obtenerRamoConcepto();

        if (ramoConcepto.ramo === 'vacio' && ramoConcepto.concepto === 'vacio') {
            return;
        }

        this.obtenerDatosFiltro(ramoConcepto.concepto, ramoConcepto.ramo);
    }

    // tslint:disable-next-line: no-big-function cognitive-complexity
    private obtenerDatosFiltro(concepto: string, ramo: string): void {
        this.spinner.show();

        const cua = localStorage.getItem('cua');
        const httpOptions = {
            headers: new HttpHeaders({ ['Content-Type']: 'application/json', ['apiKey']: environment.apikey })
        };
        this.http
            .get(
                environment.apiFiltro + concepto + '/agente/' + cua + '/unidad/negocio/' + ramo + '/tiempo/5',
                httpOptions
            )
            .pipe(first())
            .subscribe((data: FiltroMensualAcumulado[]) => {
                this.filtroAnual = data;

                const comisionesAcumulado2015 = this.filtroAnual.filter(
                    (item) => item.anio === this.year1 - 5
                );
                this.obtenerMeses(comisionesAcumulado2015);
                let sumaComisionesAcumulado2015 = 0;
                comisionesAcumulado2015.forEach((element) => {
                    sumaComisionesAcumulado2015 = sumaComisionesAcumulado2015 + element.valor;
                });

                /**********************************************************************************/
                const comisionesAcumuladoVida1 = this.filtroAnual.filter((item) => item.anio === this.year5);
                const meses2016 = this.obtenerMeses(comisionesAcumuladoVida1);
                let sumaComisionesAcumulado1 = 0;
                comisionesAcumuladoVida1.forEach((element) => {
                    sumaComisionesAcumulado1 = sumaComisionesAcumulado1 + element.valor;
                });
                this.MontosTotalesAnualesMensualesAcumulados.data[4].m = sumaComisionesAcumulado1.toFixed(2);

                let incrementoAcumulado2016: number;

                if (sumaComisionesAcumulado2015 <= 0 && sumaComisionesAcumulado1 > 0) {
                    incrementoAcumulado2016 = 100;
                } else if (sumaComisionesAcumulado2015 > 0 && sumaComisionesAcumulado1 <= 0) {
                    incrementoAcumulado2016 = -100;
                } else if (sumaComisionesAcumulado2015 <= 0 && sumaComisionesAcumulado1 <= 0) {
                    incrementoAcumulado2016 = 0;
                } else if (sumaComisionesAcumulado2015 > 0 && sumaComisionesAcumulado1 > 0) {
                    incrementoAcumulado2016 =
                        (sumaComisionesAcumulado1 / sumaComisionesAcumulado2015 - 1) * 100;
                } else {
                    incrementoAcumulado2016 = -100;
                }

                this.MontosTotalesAnualesMensualesAcumulados.data[4].i = incrementoAcumulado2016.toFixed(2);

                /**********************************************************************************/
                const comisionesAcumuladoVida2 = this.filtroAnual.filter((item) => item.anio === this.year4);
                const meses2017 = this.obtenerMeses(comisionesAcumuladoVida2);
                let sumaComisionesAcumulado2 = 0;
                comisionesAcumuladoVida2.forEach((element) => {
                    sumaComisionesAcumulado2 = sumaComisionesAcumulado2 + element.valor;
                });
                this.MontosTotalesAnualesMensualesAcumulados.data[3].m = sumaComisionesAcumulado2.toFixed(2);

                let incrementoAcumulado2017: number;

                if (sumaComisionesAcumulado1 <= 0 && sumaComisionesAcumulado2 > 0) {
                    incrementoAcumulado2017 = 100;
                } else if (sumaComisionesAcumulado1 > 0 && sumaComisionesAcumulado2 <= 0) {
                    incrementoAcumulado2017 = -100;
                } else if (sumaComisionesAcumulado1 <= 0 && sumaComisionesAcumulado2 <= 0) {
                    incrementoAcumulado2017 = 0;
                } else if (sumaComisionesAcumulado1 > 0 && sumaComisionesAcumulado2 > 0) {
                    incrementoAcumulado2017 = (sumaComisionesAcumulado2 / sumaComisionesAcumulado1 - 1) * 100;
                } else {
                    incrementoAcumulado2017 = -100;
                }

                this.MontosTotalesAnualesMensualesAcumulados.data[3].i = incrementoAcumulado2017.toFixed(2);

                /**********************************************************************************/
                const comisionesAcumuladoVida3 = this.filtroAnual.filter((item) => item.anio === this.year3);
                const meses2018 = this.obtenerMeses(comisionesAcumuladoVida3);
                let sumaComisionesAcumulado3 = 0;
                comisionesAcumuladoVida3.forEach((element) => {
                    sumaComisionesAcumulado3 = sumaComisionesAcumulado3 + element.valor;
                });
                this.MontosTotalesAnualesMensualesAcumulados.data[2].m = sumaComisionesAcumulado3.toFixed(2);

                let incrementoAcumulado2018: number;

                if (sumaComisionesAcumulado2 <= 0 && sumaComisionesAcumulado3 > 0) {
                    incrementoAcumulado2018 = 100;
                } else if (sumaComisionesAcumulado2 > 0 && sumaComisionesAcumulado3 <= 0) {
                    incrementoAcumulado2018 = -100;
                } else if (sumaComisionesAcumulado2 <= 0 && sumaComisionesAcumulado3 <= 0) {
                    incrementoAcumulado2018 = 0;
                } else if (sumaComisionesAcumulado2 > 0 && sumaComisionesAcumulado3 > 0) {
                    incrementoAcumulado2018 = (sumaComisionesAcumulado3 / sumaComisionesAcumulado2 - 1) * 100;
                } else {
                    incrementoAcumulado2018 = -100;
                }

                this.MontosTotalesAnualesMensualesAcumulados.data[2].i = incrementoAcumulado2018.toFixed(2);

                /**********************************************************************************/

                const comisionesAcumuladoVida4 = this.filtroAnual.filter((item) => item.anio === this.year2);
                const meses2019 = this.obtenerMeses(comisionesAcumuladoVida4);
                let sumaComisionesAcumulado4 = 0;
                comisionesAcumuladoVida4.forEach((element) => {
                    sumaComisionesAcumulado4 = sumaComisionesAcumulado4 + element.valor;
                });
                this.MontosTotalesAnualesMensualesAcumulados.data[1].m = sumaComisionesAcumulado4.toFixed(2);

                let incrementoAcumulado2019: number;

                if (sumaComisionesAcumulado3 <= 0 && sumaComisionesAcumulado4 > 0) {
                    incrementoAcumulado2019 = 100;
                } else if (sumaComisionesAcumulado3 > 0 && sumaComisionesAcumulado4 <= 0) {
                    incrementoAcumulado2019 = -100;
                } else if (sumaComisionesAcumulado3 <= 0 && sumaComisionesAcumulado4 <= 0) {
                    incrementoAcumulado2019 = 0;
                } else if (sumaComisionesAcumulado3 > 0 && sumaComisionesAcumulado4 > 0) {
                    incrementoAcumulado2019 = (sumaComisionesAcumulado4 / sumaComisionesAcumulado3 - 1) * 100;
                } else {
                    incrementoAcumulado2019 = -100;
                }

                this.MontosTotalesAnualesMensualesAcumulados.data[1].i = incrementoAcumulado2019.toFixed(2);

                /**********************************************************************************/
                const comisionesAcumuladoVida5 = this.filtroAnual.filter((item) => item.anio === this.year1);
                const meses2020 = this.obtenerMeses(comisionesAcumuladoVida5);
                let sumaComisionesAcumulado5 = 0;
                comisionesAcumuladoVida5.forEach((element) => {
                    sumaComisionesAcumulado5 = sumaComisionesAcumulado5 + element.valor;
                });
                this.MontosTotalesAnualesMensualesAcumulados.data[0].m = sumaComisionesAcumulado5.toFixed(2);

                let incrementoAcumulado2020: number;

                if (sumaComisionesAcumulado4 <= 0 && sumaComisionesAcumulado5 > 0) {
                    incrementoAcumulado2020 = 100;
                } else if (sumaComisionesAcumulado4 > 0 && sumaComisionesAcumulado5 <= 0) {
                    incrementoAcumulado2020 = -100;
                } else if (sumaComisionesAcumulado4 <= 0 && sumaComisionesAcumulado5 <= 0) {
                    incrementoAcumulado2020 = 0;
                } else if (sumaComisionesAcumulado4 > 0 && sumaComisionesAcumulado5 > 0) {
                    incrementoAcumulado2020 = (sumaComisionesAcumulado5 / sumaComisionesAcumulado4 - 1) * 100;
                } else {
                    incrementoAcumulado2020 = -100;
                }

                this.MontosTotalesAnualesMensualesAcumulados.data[0].i = incrementoAcumulado2020.toFixed(2);

                /**********************************************************************************/

                if (this.Linechart) {
                    this.Linechart.destroy();
                }

                const meses = {
                    meses2020,
                    meses2019,
                    meses2018,
                    meses2017,
                    meses2016
                };

                this.Linechart = this.inicializarChart(meses);
                this.Linechart.update();

                Chart.defaults.global.defaultFontSize = 18;

                // tslint:disable-next-line: no-string-literal
                this.yAxisElements = this.Linechart['scales']['y-axis-0'].ticks;

                console.log('acumulado', this.yAxisElements.length);

                if (this.yAxisElements.length === 10) {
                    this.estiloEjeY = '10.5%';
                } else if (this.yAxisElements.length === 9) {
                    this.estiloEjeY = '24.4%';
                } else if (this.yAxisElements.length === 8) {
                    this.estiloEjeY = '38%';
                } else if (this.yAxisElements.length === 7) {
                    this.estiloEjeY = '57%';
                } else if (this.yAxisElements.length === 11) {
                    this.estiloEjeY = '3%';
                } else if (this.yAxisElements.length === 6) {
                    this.estiloEjeY = '86%';
                }

                if (this.mesSelect && this.mesSelect !== '') {
                    this.cuadro(this.mesSelect);
                }

                this.filtrosVentana = false;
                this.spinner.hide();
            });
    }

    private inicializarChart(meses): Chart {
        return new Chart('line-acumulado-canvas', {
            type: 'line',
            data: {
                labels: this.lineChartLabels,
                datasets: [
                    {
                        data: meses.meses2020,
                        borderColor: '#cdebff',
                        backgroundColor: this.backgroundColorDefault,
                        borderWidth: 3
                    },
                    {
                        data: meses.meses2019,
                        borderColor: '#d2beda',
                        backgroundColor: this.backgroundColorDefault,
                        borderWidth: 3
                    },
                    {
                        data: meses.meses2018,
                        borderColor: '#ffb177',
                        backgroundColor: this.backgroundColorDefault,
                        borderWidth: 3
                    },
                    {
                        data: meses.meses2017,
                        borderColor: '#d88fc2',
                        backgroundColor: this.backgroundColorDefault,
                        borderWidth: 3
                    },
                    {
                        data: meses.meses2016,
                        borderColor: '#ff9ca6',
                        backgroundColor: this.backgroundColorDefault,
                        borderWidth: 3
                    }
                ]
            },
            options: {
                responsive: true,
                layout: {
                    padding: 45
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    mode: 'index',
                    displayColors: false,
                    backgroundColor: '#EAF0FB',
                    bodyFontColor: '#343F61',
                    titleFontColor: '#343F61',
                    xPadding: 25,
                    yPadding: 15,
                    callbacks: {
                        label: (tooltipItems) => this.cambioLabel(tooltipItems),
                        title(tooltipItem, data): string | string[] {
                            let etiqueta = data.labels[tooltipItem[0].index];
                            if (etiqueta === 'Sept.') {
                                etiqueta = 'Septiembre';
                            }
                            if (etiqueta === 'Nov.') {
                                etiqueta = 'Noviembre';
                            }
                            return etiqueta.toString();
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            gridLines: {
                                display: true
                            },
                            ticks: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: false
                            }
                        }
                    ]
                }
            }
        });
    }

    private obtenerMeses(acumuladosVida: any[]): number[] {
        let enero = 0;
        let febrero = 0;
        let marzo = 0;
        let abril = 0;
        let mayo = 0;
        let junio = 0;
        let julio = 0;
        let agosto = 0;
        let septiembre = 0;
        let octubre = 0;
        let noviembre = 0;
        let diciembre = 0;

        acumuladosVida.forEach((acumulado) => {
            switch (acumulado.mes) {
                case 1:
                    enero +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 2:
                    febrero +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 3:
                    marzo +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 4:
                    abril +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 5:
                    mayo +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 6:
                    junio +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 7:
                    julio +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 8:
                    agosto +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 9:
                    septiembre +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 10:
                    octubre +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 11:
                    noviembre +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                case 12:
                    diciembre +=
                        acumulado.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
                    break;
                default:
                    break;
            }
        });

        return [
            enero,
            febrero,
            marzo,
            abril,
            mayo,
            junio,
            julio,
            agosto,
            septiembre,
            octubre,
            noviembre,
            diciembre
        ];
    }

    // tslint:disable-next-line: no-big-function cognitive-complexity
    private obtenerDatosMensuales(mes): any {
        let filtroMes: any;

        const MontosTotalesAnualesMensualesAcumulados = [
            { a: this.year1, m: '0', i: '0', signo: '' },
            { a: this.year2, m: '0', i: '0', signo: '' },
            { a: this.year3, m: '0', i: '0', signo: '' },
            { a: this.year4, m: '0', i: '0', signo: '' },
            { a: this.year5, m: '0', i: '0', signo: '' }
        ];

        if (mes === 'Enero') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 1);
        }
        if (mes === 'Febrero') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 2);
        }
        if (mes === 'Marzo') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 3);
        }
        if (mes === 'Abril') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 4);
        }
        if (mes === 'Mayo') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 5);
        }
        if (mes === 'Junio') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 6);
        }
        if (mes === 'Julio') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 7);
        }
        if (mes === 'Agosto') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 8);
        }
        if (mes === 'Septiembre') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 9);
        }
        if (mes === 'Octubre') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 10);
        }
        if (mes === 'Noviembre') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 11);
        }
        if (mes === 'Diciembre') {
            filtroMes = this.filtroAnual.filter((item) => item.mes === 12);
        }

        const comisionesAcumulado2015 = filtroMes.filter((item) => item.anio === this.year1 - 5);
        let sumaComisionesAcumulado2015 = 0;
        comisionesAcumulado2015.forEach((element) => {
            sumaComisionesAcumulado2015 =
                sumaComisionesAcumulado2015 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });

        /**********************************************************************************/
        const comisionesAcumuladoVida1 = filtroMes.filter((item) => item.anio === this.year5);
        let sumaComisionesAcumulado1 = 0;
        comisionesAcumuladoVida1.forEach((element) => {
            sumaComisionesAcumulado1 =
                sumaComisionesAcumulado1 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });
        MontosTotalesAnualesMensualesAcumulados[4].m = sumaComisionesAcumulado1.toFixed(2);

        let incrementoAcumulado2016: number;

        if (sumaComisionesAcumulado2015 <= 0 && sumaComisionesAcumulado1 > 0) {
            incrementoAcumulado2016 = 100;
        } else if (sumaComisionesAcumulado2015 > 0 && sumaComisionesAcumulado1 <= 0) {
            incrementoAcumulado2016 = -100;
        } else if (sumaComisionesAcumulado2015 <= 0 && sumaComisionesAcumulado1 <= 0) {
            incrementoAcumulado2016 = 0;
        } else if (sumaComisionesAcumulado2015 > 0 && sumaComisionesAcumulado1 > 0) {
            incrementoAcumulado2016 = (sumaComisionesAcumulado1 / sumaComisionesAcumulado2015 - 1) * 100;
        } else {
            incrementoAcumulado2016 = -100;
        }

        MontosTotalesAnualesMensualesAcumulados[4].i = incrementoAcumulado2016.toFixed(2);

        /**********************************************************************************/
        const comisionesAcumuladoVida2 = filtroMes.filter((item) => item.anio === this.year4);
        let sumaComisionesAcumulado2 = 0;
        comisionesAcumuladoVida2.forEach((element) => {
            sumaComisionesAcumulado2 =
                sumaComisionesAcumulado2 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });
        MontosTotalesAnualesMensualesAcumulados[3].m = sumaComisionesAcumulado2.toFixed(2);

        let incrementoAcumulado2017: number;

        if (sumaComisionesAcumulado1 <= 0 && sumaComisionesAcumulado2 > 0) {
            incrementoAcumulado2017 = 100;
        } else if (sumaComisionesAcumulado1 > 0 && sumaComisionesAcumulado2 <= 0) {
            incrementoAcumulado2017 = -100;
        } else if (sumaComisionesAcumulado1 <= 0 && sumaComisionesAcumulado2 <= 0) {
            incrementoAcumulado2017 = 0;
        } else if (sumaComisionesAcumulado1 > 0 && sumaComisionesAcumulado2 > 0) {
            incrementoAcumulado2017 = (sumaComisionesAcumulado2 / sumaComisionesAcumulado1 - 1) * 100;
        } else {
            incrementoAcumulado2017 = -100;
        }

        MontosTotalesAnualesMensualesAcumulados[3].i = incrementoAcumulado2017.toFixed(2);

        /**********************************************************************************/
        const comisionesAcumuladoVida3 = filtroMes.filter((item) => item.anio === this.year3);
        let sumaComisionesAcumulado3 = 0;
        comisionesAcumuladoVida3.forEach((element) => {
            sumaComisionesAcumulado3 =
                sumaComisionesAcumulado3 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });
        MontosTotalesAnualesMensualesAcumulados[2].m = sumaComisionesAcumulado3.toFixed(2);

        let incrementoAcumulado2018: number;

        if (sumaComisionesAcumulado2 <= 0 && sumaComisionesAcumulado3 > 0) {
            incrementoAcumulado2018 = 100;
        } else if (sumaComisionesAcumulado2 > 0 && sumaComisionesAcumulado3 <= 0) {
            incrementoAcumulado2018 = -100;
        } else if (sumaComisionesAcumulado2 <= 0 && sumaComisionesAcumulado3 <= 0) {
            incrementoAcumulado2018 = 0;
        } else if (sumaComisionesAcumulado2 > 0 && sumaComisionesAcumulado3 > 0) {
            incrementoAcumulado2018 = (sumaComisionesAcumulado3 / sumaComisionesAcumulado2 - 1) * 100;
        } else {
            incrementoAcumulado2018 = -100;
        }

        MontosTotalesAnualesMensualesAcumulados[2].i = incrementoAcumulado2018.toFixed(2);

        /**********************************************************************************/

        const comisionesAcumuladoVida4 = filtroMes.filter((item) => item.anio === this.year2);
        let sumaComisionesAcumulado4 = 0;
        comisionesAcumuladoVida4.forEach((element) => {
            sumaComisionesAcumulado4 =
                sumaComisionesAcumulado4 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });
        MontosTotalesAnualesMensualesAcumulados[1].m = sumaComisionesAcumulado4.toFixed(2);

        let incrementoAcumulado2019: number;

        if (sumaComisionesAcumulado3 <= 0 && sumaComisionesAcumulado4 > 0) {
            incrementoAcumulado2019 = 100;
        } else if (sumaComisionesAcumulado3 > 0 && sumaComisionesAcumulado4 <= 0) {
            incrementoAcumulado2019 = -100;
        } else if (sumaComisionesAcumulado3 <= 0 && sumaComisionesAcumulado4 <= 0) {
            incrementoAcumulado2019 = 0;
        } else if (sumaComisionesAcumulado3 > 0 && sumaComisionesAcumulado4 > 0) {
            incrementoAcumulado2019 = (sumaComisionesAcumulado4 / sumaComisionesAcumulado3 - 1) * 100;
        } else {
            incrementoAcumulado2019 = -100;
        }

        MontosTotalesAnualesMensualesAcumulados[1].i = incrementoAcumulado2019.toFixed(2);

        /**********************************************************************************/
        const comisionesAcumuladoVida5 = filtroMes.filter((item) => item.anio === this.year1);
        let sumaComisionesAcumulado5 = 0;
        comisionesAcumuladoVida5.forEach((element) => {
            sumaComisionesAcumulado5 =
                sumaComisionesAcumulado5 +
                element.acumuladoFacturasNotasCreditoPeriodo.importeTotalAcumuladoFacturasPeriodo;
        });
        MontosTotalesAnualesMensualesAcumulados[0].m = sumaComisionesAcumulado5.toFixed(2);

        let incrementoAcumulado2020: number;

        if (sumaComisionesAcumulado4 <= 0 && sumaComisionesAcumulado5 > 0) {
            incrementoAcumulado2020 = 100;
        } else if (sumaComisionesAcumulado4 > 0 && sumaComisionesAcumulado5 <= 0) {
            incrementoAcumulado2020 = -100;
        } else if (sumaComisionesAcumulado4 <= 0 && sumaComisionesAcumulado5 <= 0) {
            incrementoAcumulado2020 = 0;
        } else if (sumaComisionesAcumulado4 > 0 && sumaComisionesAcumulado5 > 0) {
            incrementoAcumulado2020 = (sumaComisionesAcumulado5 / sumaComisionesAcumulado4 - 1) * 100;
        } else {
            incrementoAcumulado2020 = -100;
        }

        MontosTotalesAnualesMensualesAcumulados[0].i = incrementoAcumulado2020.toFixed(2);

        /**********************************************************************************/

        if (parseFloat(MontosTotalesAnualesMensualesAcumulados[0].i) < 0) {
            MontosTotalesAnualesMensualesAcumulados[0].signo = '(-)';
        } else {
            MontosTotalesAnualesMensualesAcumulados[0].signo = '(+)';
        }
        if (parseFloat(MontosTotalesAnualesMensualesAcumulados[1].i) < 0) {
            MontosTotalesAnualesMensualesAcumulados[1].signo = '(-)';
        } else {
            MontosTotalesAnualesMensualesAcumulados[1].signo = '(+)';
        }
        if (parseFloat(MontosTotalesAnualesMensualesAcumulados[2].i) < 0) {
            MontosTotalesAnualesMensualesAcumulados[2].signo = '(-)';
        } else {
            MontosTotalesAnualesMensualesAcumulados[2].signo = '(+)';
        }
        if (parseFloat(MontosTotalesAnualesMensualesAcumulados[3].i) < 0) {
            MontosTotalesAnualesMensualesAcumulados[3].signo = '(-)';
        } else {
            MontosTotalesAnualesMensualesAcumulados[3].signo = '(+)';
        }
        if (parseFloat(MontosTotalesAnualesMensualesAcumulados[4].i) < 0) {
            MontosTotalesAnualesMensualesAcumulados[4].signo = '(-)';
        } else {
            MontosTotalesAnualesMensualesAcumulados[4].signo = '(+)';
        }

        return MontosTotalesAnualesMensualesAcumulados;
    }

    sortGenerales(e: Sort): void {
        const data = this.MontosTotalesAnualesMensualesAcumulados.data;
        if (!e.active || e.direction === '') {
            this.MontosTotalesAnualesMensualesAcumulados.data = data;
            return;
        }

        this.MontosTotalesAnualesMensualesAcumulados.data = data.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'a':
                    return this.compare(a.a, b.a, isAsc);
                case 'm':
                    return this.compare(a.m, b.m, isAsc);
                case 'i':
                    return this.compare(a.i, b.i, isAsc);
                default:
                    return 0;
            }
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    // tslint:disable-next-line: no-big-function cognitive-complexity
    cuadro(e): void {
        const index = this.lineChartLabels.findIndex((element) => element === e);

        this.resetButtonsMesesSelected();

        this.mesesSelected[index] = true;

        this.mes = e;
        this.mesSelect = e;

        this.filtros = this.filtros.filter((element) => element.valor !== this.mesMatChip);
        this.mesMatChip = this.mesSelect;
        this.filtros.push({
            valor: this.mesMatChip,
            tipo: 'mes'
        });

        if (this.mes === 'Enero') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 1);
        }
        if (this.mes === 'Febrero') {
            this.filtroMes2 = this.filtroAnual.filter((item) => item.mes === 1 || item.mes === 2);
        }
        if (this.mes === 'Marzo') {
            this.filtroMes2 = this.filtroAnual.filter(
                (item) => item.mes === 1 || item.mes === 2 || item.mes === 3
            );
        }
        if (this.mes === 'Abril') {
            this.filtroMes2 = this.filtroAnual.filter(
                (item) => item.mes === 1 || item.mes === 2 || item.mes === 3 || item.mes === 4
            );
        }
        if (this.mes === 'Mayo') {
            this.filtroMes2 = this.filtroAnual.filter(
                (item) =>
                    item.mes === 1 || item.mes === 2 || item.mes === 3 || item.mes === 4 || item.mes === 5
            );
        }
        if (this.mes === 'Junio') {
            this.filtroMes2 = this.filtroAnual.filter(
                (item) =>
                    item.mes === 1 ||
                    item.mes === 2 ||
                    item.mes === 3 ||
                    item.mes === 4 ||
                    item.mes === 5 ||
                    item.mes === 6
            );
        }
        if (this.mes === 'Julio') {
            this.filtroMes2 = this.filtroAnual.filter(
                (item) =>
                    item.mes === 1 ||
                    item.mes === 2 ||
                    item.mes === 3 ||
                    item.mes === 4 ||
                    item.mes === 5 ||
                    item.mes === 6 ||
                    item.mes === 7
            );
        }
        if (this.mes === 'Agosto') {
            this.filtroMes2 = this.filtroAnual.filter(
                (item) =>
                    item.mes === 1 ||
                    item.mes === 2 ||
                    item.mes === 3 ||
                    item.mes === 4 ||
                    item.mes === 5 ||
                    item.mes === 6 ||
                    item.mes === 7 ||
                    item.mes === 8
            );
        }
        if (this.mes === 'Septiembre') {
            this.filtroMes2 = this.filtroAnual.filter(
                (item) =>
                    item.mes === 1 ||
                    item.mes === 2 ||
                    item.mes === 3 ||
                    item.mes === 4 ||
                    item.mes === 5 ||
                    item.mes === 6 ||
                    item.mes === 7 ||
                    item.mes === 8 ||
                    item.mes === 9
            );
        }
        if (this.mes === 'Octubre') {
            this.filtroMes2 = this.filtroAnual.filter(
                (item) =>
                    item.mes === 1 ||
                    item.mes === 2 ||
                    item.mes === 3 ||
                    item.mes === 4 ||
                    item.mes === 5 ||
                    item.mes === 6 ||
                    item.mes === 7 ||
                    item.mes === 8 ||
                    item.mes === 9 ||
                    item.mes === 10
            );
        }
        if (this.mes === 'Noviembre') {
            this.filtroMes2 = this.filtroAnual.filter(
                (item) =>
                    item.mes === 1 ||
                    item.mes === 2 ||
                    item.mes === 3 ||
                    item.mes === 4 ||
                    item.mes === 5 ||
                    item.mes === 6 ||
                    item.mes === 7 ||
                    item.mes === 8 ||
                    item.mes === 9 ||
                    item.mes === 10 ||
                    item.mes === 11
            );
        }
        if (this.mes === 'Diciembre') {
            this.filtroMes2 = this.filtroAnual.filter(
                (item) =>
                    item.mes === 1 ||
                    item.mes === 2 ||
                    item.mes === 3 ||
                    item.mes === 4 ||
                    item.mes === 5 ||
                    item.mes === 6 ||
                    item.mes === 7 ||
                    item.mes === 8 ||
                    item.mes === 9 ||
                    item.mes === 10 ||
                    item.mes === 11 ||
                    item.mes === 12
            );
        }

        const comisionesAcumulado2015 = this.filtroMes2.filter((item) => item.anio === this.year1 - 5);
        let sumaComisionesAcumulado2015 = 0;
        comisionesAcumulado2015.forEach((element) => {
            sumaComisionesAcumulado2015 = sumaComisionesAcumulado2015 + element.valor;
        });

        /**********************************************************************************/
        const comisionesAcumuladoVida1 = this.filtroMes2.filter((item) => item.anio === this.year5);
        let sumaComisionesAcumulado1 = 0;
        comisionesAcumuladoVida1.forEach((element) => {
            sumaComisionesAcumulado1 = sumaComisionesAcumulado1 + element.valor;
        });
        this.MontosTotalesAnualesMensualesAcumulados.data[4].m = sumaComisionesAcumulado1.toFixed(2);

        let incrementoAcumulado2016: number;

        if (sumaComisionesAcumulado2015 <= 0 && sumaComisionesAcumulado1 > 0) {
            incrementoAcumulado2016 = 100;
        } else if (sumaComisionesAcumulado2015 > 0 && sumaComisionesAcumulado1 <= 0) {
            incrementoAcumulado2016 = -100;
        } else if (sumaComisionesAcumulado2015 <= 0 && sumaComisionesAcumulado1 <= 0) {
            incrementoAcumulado2016 = 0;
        } else if (sumaComisionesAcumulado2015 > 0 && sumaComisionesAcumulado1 > 0) {
            incrementoAcumulado2016 = (sumaComisionesAcumulado1 / sumaComisionesAcumulado2015 - 1) * 100;
        } else {
            incrementoAcumulado2016 = -100;
        }

        this.MontosTotalesAnualesMensualesAcumulados.data[4].i = incrementoAcumulado2016.toFixed(2);

        /**********************************************************************************/
        const comisionesAcumuladoVida2 = this.filtroMes2.filter((item) => item.anio === this.year4);
        let sumaComisionesAcumulado2 = 0;
        comisionesAcumuladoVida2.forEach((element) => {
            sumaComisionesAcumulado2 = sumaComisionesAcumulado2 + element.valor;
        });
        this.MontosTotalesAnualesMensualesAcumulados.data[3].m = sumaComisionesAcumulado2.toFixed(2);

        let incrementoAcumulado2017: number;

        if (sumaComisionesAcumulado1 <= 0 && sumaComisionesAcumulado2 > 0) {
            incrementoAcumulado2017 = 100;
        } else if (sumaComisionesAcumulado1 > 0 && sumaComisionesAcumulado2 <= 0) {
            incrementoAcumulado2017 = -100;
        } else if (sumaComisionesAcumulado1 <= 0 && sumaComisionesAcumulado2 <= 0) {
            incrementoAcumulado2017 = 0;
        } else if (sumaComisionesAcumulado1 > 0 && sumaComisionesAcumulado2 > 0) {
            incrementoAcumulado2017 = (sumaComisionesAcumulado2 / sumaComisionesAcumulado1 - 1) * 100;
        } else {
            incrementoAcumulado2017 = -100;
        }

        this.MontosTotalesAnualesMensualesAcumulados.data[3].i = incrementoAcumulado2017.toFixed(2);

        /**********************************************************************************/
        const comisionesAcumuladoVida3 = this.filtroMes2.filter((item) => item.anio === this.year3);
        let sumaComisionesAcumulado3 = 0;
        comisionesAcumuladoVida3.forEach((element) => {
            sumaComisionesAcumulado3 = sumaComisionesAcumulado3 + element.valor;
        });
        this.MontosTotalesAnualesMensualesAcumulados.data[2].m = sumaComisionesAcumulado3.toFixed(2);

        let incrementoAcumulado2018: number;

        if (sumaComisionesAcumulado2 <= 0 && sumaComisionesAcumulado3 > 0) {
            incrementoAcumulado2018 = 100;
        } else if (sumaComisionesAcumulado2 > 0 && sumaComisionesAcumulado3 <= 0) {
            incrementoAcumulado2018 = -100;
        } else if (sumaComisionesAcumulado2 <= 0 && sumaComisionesAcumulado3 <= 0) {
            incrementoAcumulado2018 = 0;
        } else if (sumaComisionesAcumulado2 > 0 && sumaComisionesAcumulado3 > 0) {
            incrementoAcumulado2018 = (sumaComisionesAcumulado3 / sumaComisionesAcumulado2 - 1) * 100;
        } else {
            incrementoAcumulado2018 = -100;
        }

        this.MontosTotalesAnualesMensualesAcumulados.data[2].i = incrementoAcumulado2018.toFixed(2);

        /**********************************************************************************/

        const comisionesAcumuladoVida4 = this.filtroMes2.filter((item) => item.anio === this.year2);
        let sumaComisionesAcumulado4 = 0;
        comisionesAcumuladoVida4.forEach((element) => {
            sumaComisionesAcumulado4 = sumaComisionesAcumulado4 + element.valor;
        });
        this.MontosTotalesAnualesMensualesAcumulados.data[1].m = sumaComisionesAcumulado4.toFixed(2);

        let incrementoAcumulado2019: number;

        if (sumaComisionesAcumulado3 <= 0 && sumaComisionesAcumulado4 > 0) {
            incrementoAcumulado2019 = 100;
        } else if (sumaComisionesAcumulado3 > 0 && sumaComisionesAcumulado4 <= 0) {
            incrementoAcumulado2019 = -100;
        } else if (sumaComisionesAcumulado3 <= 0 && sumaComisionesAcumulado4 <= 0) {
            incrementoAcumulado2019 = 0;
        } else if (sumaComisionesAcumulado3 > 0 && sumaComisionesAcumulado4 > 0) {
            incrementoAcumulado2019 = (sumaComisionesAcumulado4 / sumaComisionesAcumulado3 - 1) * 100;
        } else {
            incrementoAcumulado2019 = -100;
        }

        this.MontosTotalesAnualesMensualesAcumulados.data[1].i = incrementoAcumulado2019.toFixed(2);

        /**********************************************************************************/
        const comisionesAcumuladoVida5 = this.filtroMes2.filter((item) => item.anio === this.year1);
        let sumaComisionesAcumulado5 = 0;
        comisionesAcumuladoVida5.forEach((element) => {
            sumaComisionesAcumulado5 = sumaComisionesAcumulado5 + element.valor;
        });
        this.MontosTotalesAnualesMensualesAcumulados.data[0].m = sumaComisionesAcumulado5.toFixed(2);

        let incrementoAcumulado2020: number;

        if (sumaComisionesAcumulado4 <= 0 && sumaComisionesAcumulado5 > 0) {
            incrementoAcumulado2020 = 100;
        } else if (sumaComisionesAcumulado4 > 0 && sumaComisionesAcumulado5 <= 0) {
            incrementoAcumulado2020 = -100;
        } else if (sumaComisionesAcumulado4 <= 0 && sumaComisionesAcumulado5 <= 0) {
            incrementoAcumulado2020 = 0;
        } else if (sumaComisionesAcumulado4 > 0 && sumaComisionesAcumulado5 > 0) {
            incrementoAcumulado2020 = (sumaComisionesAcumulado5 / sumaComisionesAcumulado4 - 1) * 100;
        } else {
            incrementoAcumulado2020 = -100;
        }

        this.MontosTotalesAnualesMensualesAcumulados.data[0].i = incrementoAcumulado2020.toFixed(2);

        /**********************************************************************************/

        this.message = true;
        const anio = new Date().getFullYear();
        this.signo1 = '';
        this.signo2 = '';
        this.signo3 = '';
        this.signo4 = '';
        this.signo5 = '';
        this.anio1 = anio;
        this.anio2 = anio - 1;
        this.anio3 = anio - 2;
        this.anio4 = anio - 3;
        this.anio5 = anio - 4;

        if (this.mes === 'Enero') {
            this.estilo = '6%';
        }
        if (this.mes === 'Febrero') {
            this.estilo = '7%';
        }
        if (this.mes === 'Marzo') {
            this.estilo = '11%';
        }
        if (this.mes === 'Abril') {
            this.estilo = '17%';
        }
        if (this.mes === 'Mayo') {
            this.estilo = '22%';
        }
        if (this.mes === 'Junio') {
            this.estilo = '27%';
        }
        if (this.mes === 'Julio') {
            this.estilo = '32%';
        }
        if (this.mes === 'Agosto') {
            this.estilo = '37%';
        }
        if (this.mes === 'Septiembre') {
            this.estilo = '42%';
        }
        if (this.mes === 'Octubre') {
            this.estilo = '48%';
        }
        if (this.mes === 'Noviembre') {
            this.estilo = '54%';
        }
        if (this.mes === 'Diciembre') {
            this.estilo = '53.4%';
        }

        if (this.MontosTotalesAnualesMensualesAcumulados.data[0].i < 0) {
            this.incremento1 = this.MontosTotalesAnualesMensualesAcumulados.data[0].i * -1;
            this.signo1 = '(-)';
        } else {
            this.incremento1 = this.MontosTotalesAnualesMensualesAcumulados.data[0].i;
            this.signo1 = '(+)';
        }
        if (this.MontosTotalesAnualesMensualesAcumulados.data[1].i < 0) {
            this.incremento2 = this.MontosTotalesAnualesMensualesAcumulados.data[1].i * -1;
            this.signo2 = '(-)';
        } else {
            this.incremento2 = this.MontosTotalesAnualesMensualesAcumulados.data[1].i;
            this.signo2 = '(+)';
        }
        if (this.MontosTotalesAnualesMensualesAcumulados.data[2].i < 0) {
            this.incremento3 = this.MontosTotalesAnualesMensualesAcumulados.data[2].i * -1;
            this.signo3 = '(-)';
        } else {
            this.incremento3 = this.MontosTotalesAnualesMensualesAcumulados.data[2].i;
            this.signo3 = '(+)';
        }
        if (this.MontosTotalesAnualesMensualesAcumulados.data[3].i < 0) {
            this.incremento4 = this.MontosTotalesAnualesMensualesAcumulados.data[3].i * -1;
            this.signo4 = '(-)';
        } else {
            this.incremento4 = this.MontosTotalesAnualesMensualesAcumulados.data[3].i;
            this.signo4 = '(+)';
        }
        if (this.MontosTotalesAnualesMensualesAcumulados.data[4].i < 0) {
            this.incremento5 = this.MontosTotalesAnualesMensualesAcumulados.data[4].i * -1;
            this.signo5 = '(-)';
        } else {
            this.incremento5 = this.MontosTotalesAnualesMensualesAcumulados.data[4].i;
            this.signo5 = '(+)';
        }

        setTimeout(() => {
            this.message = false;
        }, 5000);
    }

    resetButtonsMesesSelected(): void {
        this.mesesSelected = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false
        ];
    }

    removeMatChipFiltro(matChipItem): void {
        console.log(matChipItem);

        if (matChipItem.tipo === 'mes') {
            this.filtros = this.filtros.filter((element) => element.valor !== matChipItem.valor);
            this.mesSelect = null;
            this.resetButtonsMesesSelected();
            this.filtroAnualMensualSinRestriccion();
        }

        if (matChipItem.tipo === 'concepto') {
            this.filtros = this.filtros.filter((element) => element.valor !== matChipItem.valor);
            this.validarFiltroConcepto3(0);
            this.filtroAnualMensualSinRestriccion();
        }

        if (matChipItem.tipo === 'ramo') {
            this.filtros = this.filtros.filter((element) => element.valor !== matChipItem.valor);
            this.validarFiltroRamo3(0);
            this.filtroAnualMensualSinRestriccion();
        }
    }

    filtroAnualMensualSinRestriccion(): void {
        const ramoConcepto = this.obtenerRamoConcepto();

        this.obtenerDatosFiltro(ramoConcepto.concepto, ramoConcepto.ramo);
    }
}

export interface Tablas {
    a: number;
    m: string;
    i: string;
    circulo: string;
}

export interface FiltroMensualAcumulado {
    anio: number;
    comprobante: string;
    mes: number;
    unidadNegocio: string;
    valor: number;
}

export interface DatosHistorico {
    anio: number;
    mes: number;
    acumuladoFacturasNotasCreditoPeriodo: number;
    importeTotalAcumuladoFacturasPeriodo: number;
}

export interface DatosSimplificados {
    anio: number;
    mes: number;
    cantidad: number;
    incremento: number;
}

interface MatChipFiltro {
    valor: string;
    tipo: string;
}
