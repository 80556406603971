import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../_models';
import { AlertService, AuthenticationService, UserService } from '../_services';

export interface BonosAgrupacion {
    lineaNegocio: string;
    unidadesNegocio: string[];
}

@Component({ selector: 'app-bonos', templateUrl: 'totalBonos.component.html' })
@Injectable()
export class BonosComponent implements OnInit, OnDestroy {
    @Input() fechaInicioPeriodo: number;
    @Input() fechaFinPeriodo: number;
    mensajeShow = true;
    bonos: Bono[] = [];
    lineasNegocio: string[] = [];
    portal: any;

    colorVida = '#3F9C31';
    colorSalud = '#0D68E0';
    colorAutos = '#FF9C00';
    colorDanios = '#9F76D3';

    [x: string]: any;
    users: User[];
    currentUser: User;
    currentUserSubscription: Subscription;

    private agrupacion: BonosAgrupacion[];

    constructor(
        private authenticationService: AuthenticationService,
        public userService: UserService,
        private http: HttpClient,
        private alertService: AlertService,
        private spinner: NgxSpinnerService
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe((user) => {
            this.currentUser = user;
        });
    }

    ngOnInit(): void {
        if (localStorage.getItem('portal').includes('intermediario')) {
            this.portal = true;
        } else {
            this.portal = false;
        }
        this.agrupacion = [];
        this.alertService.clear();
        this.desgloceBonos();
    }

    desgloceBonos(): Subscription {
        this.spinner.show();

        const cua = localStorage.getItem('cua');
        let inicio = '';
        let fin = '';

        if (this.fechaInicioPeriodo && this.fechaFinPeriodo) {
            inicio = this.fechaInicioPeriodo.toString();
            fin = this.fechaFinPeriodo.toString();
        } else {
            inicio = localStorage.getItem('fechaInicial');
            fin = localStorage.getItem('fechaFinal');
        }

        const httpOptions = {
            headers: new HttpHeaders({ ['Content-Type']: 'application/json', ['apiKey']: environment.apikey })
        };
        return this.http
            .get(
                environment.apiBonos + '/' + cua + '/periodoinicio/' + inicio + '/periodofin/' + fin,
                httpOptions
            )
            .pipe(first())
            .subscribe((data: Response[]) => {
                try {
                    this.formarBonos(data);

                    this.bonos.forEach((bono) => {
                        bono.desgloce.forEach((desgloce) => {
                            let suma = 0;
                            desgloce.bonos.forEach((detalle) => {
                                suma += detalle.saldo;
                            });
                            desgloce.saldo = suma;
                        });
                    });

                    // Ordenar bonos
                    this.ordenarBonos();

                    // Agrupar bonos
                    this.agruparBonos();
                } catch (error) {
                    console.log(error);
                }

                this.spinner.hide();
            });
    }

    private formarBonos(data): void {
        data.forEach((item) => {
            const agrupacion = this.agrupacion.find(
                (agrupador) => agrupador.lineaNegocio === item.lineaNegocio
            );

            if (!this.lineasNegocio.includes(item.lineaNegocio)) {
                this.lineasNegocio.push(item.lineaNegocio);

                const tmpBono: Bono = {
                    lineaNegocio: item.lineaNegocio,
                    desgloce: []
                };

                const tmpDesgloce = this.obtenerDesgloce(item);

                tmpBono.desgloce.push(tmpDesgloce);
                this.bonos.push(tmpBono);

                this.agrupacion.push({
                    lineaNegocio: item.lineaNegocio,
                    unidadesNegocio: [item.unidadEstrategiaNegocioClave]
                });
            } else if (
                this.lineasNegocio.includes(item.lineaNegocio) &&
                agrupacion &&
                !agrupacion.unidadesNegocio.find(
                    (agrupador) => agrupador === item.unidadEstrategiaNegocioClave
                )
            ) {
                const tmpDesgloce = this.obtenerDesgloce(item);

                this.bonos.find((bono) => bono.lineaNegocio === item.lineaNegocio).desgloce.push(tmpDesgloce);

                this.agrupacion
                    .find((agrupador) => agrupador.lineaNegocio === item.lineaNegocio)
                    .unidadesNegocio.push(item.unidadEstrategiaNegocioClave);
            } else {
                this.bonos.forEach((search) => {
                    if (search.lineaNegocio === item.lineaNegocio) {
                        const titulo = this.getTituloRamo(item.unidadEstrategiaNegocioClave);

                        const tmpDefinicion: Definicion = {
                            titulo: item.tipoBono,
                            saldo: item.movimientoPercepcionImporteDespuesDeImpuesto,
                            tipo: item.tipoPago,
                            fecha: item.periodo,
                            saldoAntesImpuestos: item.movimientoPercepcionImporteAntesImpuesto,
                            isr: item.isr,
                            ivaAcreditado: item.ivaAcreditado,
                            ivaRetenido: item.ivaRetenido,
                            impCedular: item.impCedular
                        };

                        search.desgloce.forEach((desgloce) => {
                            if (desgloce.titulo === titulo) {
                                desgloce.bonos.push(tmpDefinicion);
                            }
                        });
                    }
                });
            }
        });
    }

    private obtenerDesgloce(item: any): Desgloce {
        const titulo = this.getTituloRamo(item.unidadEstrategiaNegocioClave);
        const color = this.getColor(item.unidadEstrategiaNegocioClave);

        const tmpDesgloce: Desgloce = {
            titulo,
            saldo: 0,
            color,
            bonos: []
        };

        const tmpDefinicion: Definicion = {
            titulo: item.tipoBono,
            saldo: item.movimientoPercepcionImporteDespuesDeImpuesto,
            tipo: item.tipoPago,
            fecha: item.periodo,
            saldoAntesImpuestos: item.movimientoPercepcionImporteAntesImpuesto,
            isr: item.isr,
            ivaAcreditado: item.ivaAcreditado,
            ivaRetenido: item.ivaRetenido,
            impCedular: item.impCedular
        };

        tmpDesgloce.bonos.push(tmpDefinicion);

        return tmpDesgloce;
    }

    private ordenarBonos(): void {
        this.bonos.sort((a, b) => {
            if (a.lineaNegocio < b.lineaNegocio) {
                return 1;
            } else {
                return -1;
            }
        });

        this.bonos.forEach((bono) => {
            bono.desgloce.sort((a, b) => {
                if (a.titulo < b.titulo) {
                    return 1;
                } else {
                    return -1;
                }
            });

            // Se mueve Danios al final
            const danio = bono.desgloce.find((ramo) => ramo.titulo === 'Daños');
            if (danio) {
                const indexDanios = bono.desgloce.findIndex((ramo) => ramo.titulo === 'Daños');
                if (indexDanios !== bono.desgloce.length - 1) {
                    const auxBono1 = bono.desgloce.slice(0, indexDanios);
                    const danioAux = danio;
                    const auxBono2 = bono.desgloce.slice(indexDanios + 1);
                    bono.desgloce = auxBono1.concat(auxBono2);
                    bono.desgloce.push(danioAux);
                }
            }
        });
    }

    private agruparBonos(): void {
        const agrupacionBonos: Bono[] = this.bonos;

        agrupacionBonos.forEach((negocio) => {
            negocio.desgloce.forEach((detalleBonos) => {
                const definiciones: Definicion[] = [];
                detalleBonos.bonos.forEach((bono) => {
                    const definicion = definiciones.find(
                        (definicionInt) =>
                            definicionInt.titulo === bono.titulo &&
                            definicionInt.tipo === bono.tipo &&
                            definicionInt.fecha === bono.fecha
                    );

                    if (definicion) {
                        definicion.saldoAntesImpuestos += bono.saldoAntesImpuestos;
                        definicion.isr += bono.isr;
                        definicion.ivaAcreditado += bono.ivaAcreditado;
                        definicion.ivaRetenido += bono.ivaRetenido;
                        definicion.impCedular += bono.impCedular;
                        definicion.saldo += bono.saldo;
                    } else {
                        definiciones.push({
                            titulo: bono.titulo,
                            saldo: bono.saldo,
                            tipo: bono.tipo,
                            fecha: bono.fecha,
                            saldoAntesImpuestos: bono.saldoAntesImpuestos,
                            isr: bono.isr,
                            ivaAcreditado: bono.ivaAcreditado,
                            ivaRetenido: bono.ivaRetenido,
                            impCedular: bono.impCedular
                        });
                    }
                });
                detalleBonos.bonos = definiciones;
            });
        });
    }

    getColor(ramo: string): string {
        let color = null;
        switch (ramo) {
            case 'VI':
            case 'VC':
            case 'VG':
                color = this.colorVida;
                break;
            case 'SA':
                color = this.colorSalud;
                break;
            case 'AU':
                color = this.colorAutos;
                break;
            case 'DA':
                color = this.colorDanios;
                break;
            default:
                color = '#FFFFFF';
        }
        return color;
    }

    getTituloRamo(ramo: string): string {
        let titulo = '';
        switch (ramo) {
            case 'VI':
            case 'VC':
            case 'VG':
                titulo = 'Vida';
                break;
            case 'SA':
                titulo = 'Salud';
                break;
            case 'AU':
                titulo = 'Autos';
                break;
            case 'DA':
                titulo = 'Daños';
                break;
            default:
                titulo = 'Error';
        }
        return titulo;
    }

    ngOnDestroy(): void {
        this.currentUserSubscription.unsubscribe();
    }

    redirectBonos(): void {
        const link = document.createElement('a');
        link.target = '_blank';

        link.href =
            'https://intermediarios.gnp.com.mx/wps/myportal/corporativos/Home/Desempe%C3%B1o/Logros/selodigital/';

        link.setAttribute('visibility', 'hidden');
        link.click();
    }
}

export interface Definicion {
    titulo: string;
    saldo: number;
    tipo: string;
    fecha: string;
    saldoAntesImpuestos: number;
    isr: number;
    ivaAcreditado: number;
    ivaRetenido: number;
    impCedular: number;
}

export interface Desgloce {
    titulo: string;
    saldo: number;
    color: string;
    bonos: Definicion[];
}

export interface Bono {
    lineaNegocio: string;
    desgloce: Desgloce[];
}

export interface Response {
    agenteID: number;
    claveTransaccion: string;
    isr: number;
    ivaAcreditado: number;
    ivaRetenido: number;
    lineaNegocio: string;
    movimientoPercepcionDescripcionComplemento: number;
    movimientoPercepcionImporteAntesImpuesto: number;
    movimientoPercepcionImporteDespuesDeImpuesto: number;
    periodo: string;
    tipoBono: string;
    tipoPago: string;
    trnDes: string;
    unidadEstrategiaNegocioClave: string;
    impCedular: number;
}
