import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

const users = JSON.parse(sessionStorage.getItem('users')) || [];

@Injectable()
export class Auth implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, body } = request;

        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize())
            .pipe(delay(500))
            .pipe(dematerialize());

        function handleRoute(): Observable<HttpEvent<any>> {
            if (url.endsWith('/auth/login') && method === 'POST') {
                return authenticate();
            } else {
                return next.handle(request);
            }
        }

        function authenticate(): Observable<never> {
            const { password } = body;
            const user = users.find((x) => x.password === password);
            console.log('jwt', this.jwt);

            if (!user) {
                return error('Contraseña incorrecta');
            }
            return error({
                id: user.id,
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                jwt: 'jwt-token'
            });
        }

        function error(message): Observable<never> {
            return throwError({ error: { message } });
        }
    }
}

export const auth = {
    provide: HTTP_INTERCEPTORS,
    useClass: Auth,
    multi: true
};
