import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as parser from 'xml-js';
import { ExporterService } from '../services/exporter.service';
import { ModalService } from '../_modal';
import { AlertService } from '../_services';

@Component({
    selector: 'app-datos-facturacion',
    templateUrl: 'datosFacturacion.component.html',
    styleUrls: ['datos-facturacion-overview.css']
})
export class DatosFacturacionComponent implements OnInit {
    @Input() fechaInicioPeriodo: number;
    @Input() fechaFinPeriodo: number;

    loading = false;
    checked = false;
    xmlBase64: string;
    detalles: Detalle[] = [];
    comprobantesDataSource = null;
    obtenerSaldos: Saldo = {
        estado: '',
        fechaInicial: '',
        fechaFinal: '',
        dias: '',
        pago: '',
        reporte: ''
    };
    resultado: any;
    resultado2: any;
    dataSourceFacturas = null;
    nominalesVida: Comprobante[];
    nominalesNoVida: Comprobante[];
    comprobanteNominal: ObtenerComprobantesNominalesResponse[];
    comprobantesFiscales: ObtenerComprobantesNominalesResponseClass;
    detalleComprobantesFiscales: Comprobante[];
    detalleComprobantesFiscales2: Comprobante[];
    datos: DatosFiscales[] = [];
    detallesFiscales: ComprobanteNominal[] = [];
    detallesFiscales2: ComprobanteNominal[] = [];
    comprobantes: ComprobanteNominal[] = [];
    comprobantes2: ComprobanteNominal[] = [];
    comprobanteReales: ComprobanteReal[] = [];
    comprobantesFiscalesDataSource = null;
    detalleComprobantesFiscalesDataSource = null;
    comprobantesNominalesVidaDataSource = null;
    comprobantesNominalesNoVidaDataSource = null;
    comprobantesRealesDataSource = null;
    tipoComprobanteNominalRamo: string;
    tipoDeBuzon: any;
    buzon: string;
    buzonE: string;
    estilo: any;
    date: any;
    comprobantesNominalesResponse: ObtenerComprobantesNominalesResponse[];
    selection = new SelectionModel<ComprobanteNominal>(true, []);
    selection2 = new SelectionModel<ComprobanteNominal>(true, []);
    selectionDataSource: MatTableDataSource<ComprobanteNominal>;
    msgExito: boolean;
    msgError: boolean;
    messageError: any;
    messageExito: any;
    datosExcel: DatosSimplificados[] = [];
    nombreXml: string;
    mensajeShow: boolean;
    MENSAJE_MENOS_CINCO_DIAS: string;
    status: boolean;
    status2: boolean;
    statusVida = false;
    statusNoVida = false;
    disponible: any;
    mensajeError: any;
    isChecked = false;
    estatus: any;
    desactivar = false;
    archivosXMLDataSource: MatTableDataSource<any>;
    comprobantesXMLDataSource: MatTableDataSource<any>;
    estatusDeVida: boolean;
    estatusDeNoVida: boolean;
    disabledButtonXml: boolean;
    dataValidarComprobante: any;
    estiloIva: any;
    private contentTypeLabel = 'Content-Type';
    private contentTypeValue = 'application/json';
    private fechainicioLabel = '/fechainicio/';
    private fechafinLabel = '/fechafin/';

    @ViewChild('xmlFileInput', { static: false }) xmlFileInput;

    @ViewChild('detallesSort', { static: false }) set matDetallesSort(detallesSort: MatSort) {
        if (
            this.detalleComprobantesFiscalesDataSource !== null &&
            this.detalleComprobantesFiscalesDataSource !== undefined
        ) {
            this.detalleComprobantesFiscalesDataSource.sort = detallesSort;
        }
    }

    @ViewChild('facturacionSort', { static: false }) set matFacturacionSort(facturacionSort: MatSort) {
        if (
            this.comprobantesFiscalesDataSource !== null &&
            this.comprobantesFiscalesDataSource !== undefined
        ) {
            this.comprobantesFiscalesDataSource.sort = facturacionSort;
        }
    }

    @ViewChild('facturasVidaSort', { static: false }) set matFacturasVidaSort(facturasVidaSort: MatSort) {
        if (
            this.comprobantesNominalesVidaDataSource !== null &&
            this.comprobantesNominalesVidaDataSource !== undefined
        ) {
            this.comprobantesNominalesVidaDataSource.sort = facturasVidaSort;
        }
    }

    @ViewChild('facturasNoVidaSort', { static: false }) set matFacturasNoVidaSort(
        facturasNoVidaSort: MatSort
    ) {
        if (
            this.comprobantesNominalesNoVidaDataSource !== null &&
            this.comprobantesNominalesNoVidaDataSource !== undefined
        ) {
            this.comprobantesNominalesNoVidaDataSource.sort = facturasNoVidaSort;
        }
    }

    @ViewChild('comprobantesFiscalesSort', { static: false }) set matComprobantesFiscalesSort(
        comprobantesFiscalesSort: MatSort
    ) {
        if (this.selectionDataSource !== null && this.selectionDataSource !== undefined) {
            this.selectionDataSource.sort = comprobantesFiscalesSort;
        }
    }

    @ViewChild('archivoXMLSort', { static: false }) set matArchivoXMLSort(archivoXMLSort: MatSort) {
        if (
            this.comprobantesNominalesNoVidaDataSource !== null &&
            this.comprobantesNominalesNoVidaDataSource !== undefined
        ) {
            this.comprobantesNominalesNoVidaDataSource.sort = archivoXMLSort;
        }
    }

    @ViewChild('submitButton', { static: false }) submitButton: ElementRef;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private modalService: ModalService,
        private http: HttpClient,
        private excelService: ExporterService,
        private spinner: NgxSpinnerService
    ) {
        this.route.snapshot.paramMap.get('datos-facturacion');
    }

    displayedColumns2: string[] = [
        'ramo',
        'facturaComprobar',
        'notasComprobar',
        'percepciones',
        'saldoPendiente',
        'pendienteProximoPeriodo'
    ];

    displayedColumns3: string[] = [
        'select',
        'tipoComprobante',
        'fechaInicioPeriodo',
        'fechaFinPeriodo',
        'comisionesBonos',
        'ivaAcreditado',
        'ivaRetenido',
        'isrRetenido',
        'cedular',
        'totalDespuesImpuestos',
        'estadoComprobante',
        'tipoPeriodo'
    ];

    displayedColumns4: string[] = [
        'clave',
        'folio',
        'contrato',
        'fechaMovimiento',
        'ramo',
        'tipoComprobante',
        'totalAntesImpuestos',
        'ivaAcreditado',
        'ivaRetenido',
        'isrRetenido',
        'totalDespuesImpuestos'
    ];

    displayedColumns5: string[] = ['tipoComprobante', 'ramo', 'fechaInicio', 'fechaFin', 'totalComprobar'];

    displayedColumns6: string[] = [
        'tipoComision',
        'ramo',
        'facturaUID',
        'importeAntesImpuestos',
        'IVARetenido',
        'IVAAcreditado',
        'impuestoCedular',
        'importeDespuesImpuestos',
        'isr',
        'fechaIngreso',
        'fechaRechazo',
        'fechaPago'
    ];

    ngOnInit(): void {
        this.spinner.show();
        this.isChecked = false;
        this.nombreXml = '';
        this.alertService.clear();
        this.msgError = false;
        this.msgExito = false;
        this.disabledButtonXml = false;

        if (localStorage.getItem('portal') === 'intermediarios') {
            this.estiloIva = '5%';
        } else {
            this.estiloIva = '12.5%';
        }

        if (localStorage.getItem('comprobantesXML')) {
            this.comprobantesXMLDataSource = new MatTableDataSource<any>(
                JSON.parse(localStorage.getItem('comprobantesXML'))
            );
        }

        this.cargarInfo();
    }

    // tslint:disable-next-line: no-big-function cognitive-complexity
    private cargarInfo(): Subscription {
        this.detalles = [];
        this.datos = [];
        this.detallesFiscales = [];
        this.detallesFiscales2 = [];
        this.comprobantes = [];
        this.comprobantes2 = [];
        this.comprobanteReales = [];
        this.comprobantesFiscalesDataSource = null;
        this.detalleComprobantesFiscalesDataSource = null;
        this.comprobantesNominalesVidaDataSource = null;
        this.comprobantesNominalesNoVidaDataSource = null;
        this.comprobantesRealesDataSource = null;
        this.selection = new SelectionModel<ComprobanteNominal>(true, []);
        this.selection2 = new SelectionModel<ComprobanteNominal>(true, []);
        this.datosExcel = [];
        this.statusVida = false;
        this.statusNoVida = false;

        this.beneficioIVA();
        this.comprobantesNominales();
        this.periodoFacturar();
        this.comprobantesReales();

        const date = new Date();
        const ultimoDia: any = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const d: any = new Date();
        const DIA_ACTUAL = d.getDate();
        const CINCO_DIAS = ultimoDia.getDate() - DIA_ACTUAL;

        if (CINCO_DIAS === 5 || CINCO_DIAS === 4 || CINCO_DIAS === 3) {
            this.mensajeShow = true;
            this.status2 = false;
            this.MENSAJE_MENOS_CINCO_DIAS =
                'FAC04 - Le recordamos que está próximo el fin de mes, favor de ingresar sus Comprobantes Fiscales máximo 5 días hábiles antes de fin de mes.';
        } else if (CINCO_DIAS === 2 || CINCO_DIAS === 1 || CINCO_DIAS === 0 || DIA_ACTUAL === 1) {
            this.mensajeShow = true;
            this.status2 = true;
            this.MENSAJE_MENOS_CINCO_DIAS =
                'FAC04 - Le recordamos que por cierre mensual los dos últimos días del mes no podrá ingresar comprobantes fiscales.';
        } else {
            this.mensajeShow = false;
            this.status2 = false;
        }

        const cua = localStorage.getItem('cua');
        const httpOptions = {
            headers: new HttpHeaders({
                [this.contentTypeLabel]: this.contentTypeValue,
                ['apiKey']: environment.apikey
            })
        };
        return this.http
            .get(environment.apiBuzonE + cua, httpOptions)
            .pipe(first())
            .subscribe((data: any) => {
                this.buzonE = data.consultaAgentesResponse.buzonE;

                if (this.buzonE === 'X' || this.buzonE === 'N') {
                    this.estatus = true;
                } else {
                    this.estatus = false;
                }

                const numeroCuenta = data.consultaAgentesResponse.numeroCuenta;

                const inicio = localStorage.getItem('fechaInicial');
                const fin = localStorage.getItem('fechaFinal');

                const status2Aux = this.status2;
                this.status2 = true;

                this.http
                    .get(
                        environment.apiHistorico3 + cua + '/periodoinicio/' + inicio + '/periodofin/' + fin,
                        httpOptions
                    )
                    .pipe(first())
                    .subscribe((comprobantes: any) => {
                        if (
                            !this.estatus &&
                            ((comprobantes.notas && comprobantes.notas.length > 0) ||
                                (comprobantes.facturas && comprobantes.facturas.length > 0))
                        ) {
                            this.status2 = true;
                        } else {
                            this.status2 = status2Aux;
                        }

                        if (numeroCuenta === '') {
                            this.mensajeError =
                                'FAC24 - Tu cuenta bancaria está deshabilitada o tienes adeudos de comprobantes fiscales. Favor de contactar a Soporte.';
                            this.status2 = true;
                            console.log(this.status2);
                        } else {
                            this.ventanaServicio();
                        }
                    });

                this.spinner.hide();
            });
    }

    ventanaServicio(): Subscription {
        const httpOptions = {
            headers: new HttpHeaders({
                [this.contentTypeLabel]: this.contentTypeValue,
                ['apiKey']: environment.apikey
            })
        };
        return this.http
            .get(environment.apiVentanaServicio + 0, httpOptions)
            .pipe(first())
            .subscribe((data: any) => {
                this.disponible = data;

                if (this.disponible.ventanaServcioResponse.servicio === 'N') {
                    this.status2 = true;
                    this.mensajeError =
                        'FAC33 - Los horarios de servicio son: lunes a viernes de 08:00 a 22:00 horas, sábados de 08:00 a 14:30 horas y domingos de 10:00 a 16:00 horas. El primer día de mes no habrá servicio por cierre mensual.';
                }
            });
    }

    solicitarFactura(): Subscription {
        const cua = localStorage.getItem('cua');
        const inicio = localStorage.getItem('fechaInicial');
        const fin = localStorage.getItem('fechaFinal');

        let tipo;
        if (this.buzonE === 'S') {
            tipo = 'BUZON';
        } else {
            tipo = 'PORT';
        }

        const httpOptions = {
            headers: new HttpHeaders({
                [this.contentTypeLabel]: this.contentTypeValue,
                ['apiKey']: environment.apikey
            })
        };
        return this.http
            .get(
                environment.apiComprobantesNominales +
                    cua +
                    this.fechainicioLabel +
                    inicio +
                    this.fechafinLabel +
                    fin +
                    '/tipo/' +
                    tipo,
                httpOptions
            )
            .pipe(first())
            .subscribe(
                (data: any) => {
                    if (
                        data.error ||
                        (data.obtenerComprobantesNominalesResponse &&
                            !Array.isArray(data.obtenerComprobantesNominalesResponse.comprobantes) &&
                            data.obtenerComprobantesNominalesResponse.comprobantes.ramo.includes(
                                '***CCException***===FAC03'
                            ))
                    ) {
                        localStorage.setItem('facturacionError', 'true');
                    } else {
                        localStorage.setItem('facturacionError', 'false');
                    }
                    this.openModal('custom-modal-2');
                    this.cargarInfo();
                },
                (error) => {
                    this.alertService.error(error);
                    localStorage.setItem('facturacionError', 'true');
                }
            );
    }

    onButtonClick(): void {
        if (this.submitButton.nativeElement.disabled === false) {
            this.submitButton.nativeElement.disabled = false;
        } else {
            this.submitButton.nativeElement.disabled = true;
        }
    }

    periodoFacturar(): Subscription {
        const cua = localStorage.getItem('cua');
        const httpOptions = {
            headers: new HttpHeaders({
                [this.contentTypeLabel]: this.contentTypeValue,
                ['apiKey']: environment.apikey
            })
        };
        return this.http
            .get(environment.apiPeriodoFacturar + cua, httpOptions)
            .pipe(first())
            .subscribe((data: any) => {
                this.obtenerSaldos = data.obtenerSaldosResponse.saldo;
                this.obtenerSaldos.detalle[0].clave = cua;
                this.obtenerSaldos.detalle[1].clave = cua;
                this.obtenerSaldos.estado = 'PEND' ? 'Pendiente' : this.obtenerSaldos.estado;
                this.obtenerSaldos.detalle[0].ramo = 'VI' ? 'Vida' : this.obtenerSaldos.detalle[0].ramo;
                this.obtenerSaldos.detalle[1].ramo = 'NV' ? 'No Vida' : this.obtenerSaldos.detalle[1].ramo;
                this.obtenerSaldos.detalle[2].ramo = 'TO' ? 'Total' : this.obtenerSaldos.detalle[2].ramo;
                this.obtenerSaldos.fechaInicial = '' ? '--' : this.obtenerSaldos.fechaInicial;
                this.obtenerSaldos.fechaFinal = '' ? '--' : this.obtenerSaldos.fechaFinal;
                this.obtenerSaldos.pago = '' ? '--' : this.obtenerSaldos.pago;
            });
    }

    // tslint:disable-next-line: no-big-function cognitive-complexity
    comprobantesNominales(): Subscription {
        const cua = localStorage.getItem('cua');
        const tipo = 'vacio';
        let inicio = null;
        let fin = null;

        if (this.fechaInicioPeriodo && this.fechaFinPeriodo) {
            inicio = this.fechaInicioPeriodo;
            fin = this.fechaFinPeriodo;
        } else {
            inicio = localStorage.getItem('fechaInicial');
            fin = localStorage.getItem('fechaFinal');
        }

        const httpOptions = {
            headers: new HttpHeaders({
                [this.contentTypeLabel]: this.contentTypeValue,
                ['apiKey']: environment.apikey
            })
        };
        return (
            this.http
                .get(
                    environment.apiComprobantesNominales +
                        cua +
                        this.fechainicioLabel +
                        inicio +
                        this.fechafinLabel +
                        fin +
                        '/tipo/' +
                        tipo,
                    httpOptions
                )
                .pipe(first())
                // tslint:disable-next-line: no-big-function
                .subscribe((data: ObtenerComprobantesNominalesResponse) => {
                    this.comprobantesFiscales = data.obtenerComprobantesNominalesResponse;

                    if (
                        data &&
                        data.obtenerComprobantesNominalesResponse &&
                        data.obtenerComprobantesNominalesResponse.comprobantes
                    ) {
                        this.detalleComprobantesFiscales = data.obtenerComprobantesNominalesResponse.comprobantes.filter(
                            (item) => item.ramo === 'Vida'
                        );
                        this.detalleComprobantesFiscales2 = data.obtenerComprobantesNominalesResponse.comprobantes.filter(
                            (item) => item.ramo === 'NoVida'
                        );
                        this.nominalesVida = data.obtenerComprobantesNominalesResponse.comprobantes.filter(
                            (item) => item.ramo === 'Vida'
                        );
                        this.nominalesNoVida = data.obtenerComprobantesNominalesResponse.comprobantes.filter(
                            (item) => item.ramo === 'NoVida'
                        );
                    }

                    // ****************** Datos informativos, comprobantes fiscales ***************************************

                    if (
                        this.comprobantesFiscales &&
                        this.comprobantesFiscales.datosFiscales &&
                        this.comprobantesFiscales.datosFiscales.length > 0
                    ) {
                        this.comprobantesFiscales.datosFiscales.forEach((element) => {
                            const dato: DatosFiscales = {
                                facturaComprobar: element.facturaComprobar,
                                ramo: element.ramo,
                                saldoPendiente: element.saldoPendiente,
                                notasComprobar: element.notasComprobar,
                                percepciones: element.percepciones,
                                pendienteProximoPeriodo: element.pendienteProximoPeriodo
                            };
                            this.datos.push(dato);
                        });
                    }

                    this.resultado = this.datos.length - 1;
                    this.comprobantesFiscalesDataSource = new MatTableDataSource<DatosFiscales>(this.datos);

                    // *********************************** MODAL 1 *****************************************

                    if (
                        this.detalleComprobantesFiscales &&
                        this.detalleComprobantesFiscales.length > 0 &&
                        this.detalleComprobantesFiscales[0].comprobanteNominal &&
                        Array.isArray(this.detalleComprobantesFiscales[0].comprobanteNominal) &&
                        this.detalleComprobantesFiscales[0].comprobanteNominal.length > 0
                    ) {
                        this.detalleComprobantesFiscales[0].comprobanteNominal.forEach((element) => {
                            const detalleFiscal: ComprobanteNominal = {
                                clave: (element.clave = localStorage.getItem('cua')),
                                contrato:
                                    element.contrato === '' || !element.contrato ? '--' : element.contrato,
                                totalDespuesImpuestos: element.totalDespuesImpuestos.replace(/,/g, ''),
                                agrupacion: element.agrupacion,
                                ramo: element.ramo,
                                fechaFinPeriodo: element.fechaFinPeriodo,
                                comisionesBonos: element.comisionesBonos,
                                folio: element.folio,
                                tipoComprobante: element.tipoComprobante,
                                fechaInicioPeriodo: element.fechaInicioPeriodo,
                                estadoComprobante: element.estadoComprobante,
                                tipoPeriodo: element.tipoPeriodo,
                                totalImpuesto: element.impuestos.totalImpuesto.toString().replace(/,/g, ''),
                                cedular: element.impuestos.cedular,
                                ivaAcreditado: element.impuestos.ivaAcreditado,
                                ivaRetenido: element.impuestos.ivaRetenido,
                                isrRetenido: element.impuestos.isrRetenido
                            };
                            this.detallesFiscales.push(detalleFiscal);
                        });
                    } else if (
                        this.detalleComprobantesFiscales &&
                        this.detalleComprobantesFiscales.length > 0 &&
                        this.detalleComprobantesFiscales[0].comprobanteNominal &&
                        !Array.isArray(this.detalleComprobantesFiscales[0].comprobanteNominal)
                    ) {
                        const element: ComprobanteNominal = this.detalleComprobantesFiscales[0]
                            .comprobanteNominal;

                        const detalleFiscal: ComprobanteNominal = {
                            clave: (element.clave = localStorage.getItem('cua')),
                            contrato: element.contrato === '' || !element.contrato ? '--' : element.contrato,
                            totalDespuesImpuestos: element.totalDespuesImpuestos.replace(/,/g, ''),
                            agrupacion: element.agrupacion,
                            ramo: element.ramo,
                            fechaFinPeriodo: element.fechaFinPeriodo,
                            comisionesBonos: element.comisionesBonos,
                            folio: element.folio,
                            tipoComprobante: element.tipoComprobante,
                            fechaInicioPeriodo: element.fechaInicioPeriodo,
                            estadoComprobante: element.estadoComprobante,
                            tipoPeriodo: element.tipoPeriodo,
                            totalImpuesto: element.impuestos.totalImpuesto.toString().replace(/,/g, ''),
                            cedular: element.impuestos.cedular,
                            ivaAcreditado: element.impuestos.ivaAcreditado,
                            ivaRetenido: element.impuestos.ivaRetenido,
                            isrRetenido: element.impuestos.isrRetenido
                        };
                        this.detallesFiscales.push(detalleFiscal);
                    } else {
                        const detalleFiscal: ComprobanteNominal = {
                            clave: '--',
                            contrato: '--',
                            totalDespuesImpuestos: '--',
                            agrupacion: '--',
                            ramo: '--',
                            fechaFinPeriodo: '--',
                            comisionesBonos: '--',
                            folio: '--',
                            tipoComprobante: '--',
                            fechaInicioPeriodo: '--',
                            estadoComprobante: '--',
                            tipoPeriodo: '--',
                            totalImpuesto: '--',
                            cedular: '--',
                            ivaAcreditado: '--',
                            ivaRetenido: '--',
                            isrRetenido: '--'
                        };
                        this.detallesFiscales.push(detalleFiscal);
                    }

                    // ********************************************************************************************

                    if (
                        this.detalleComprobantesFiscales2 &&
                        this.detalleComprobantesFiscales2.length > 0 &&
                        this.detalleComprobantesFiscales2[0].comprobanteNominal &&
                        Array.isArray(this.detalleComprobantesFiscales2[0].comprobanteNominal) &&
                        this.detalleComprobantesFiscales2[0].comprobanteNominal.length > 0
                    ) {
                        this.detalleComprobantesFiscales2[0].comprobanteNominal.forEach((element) => {
                            const detalleFiscal2: ComprobanteNominal = {
                                clave: (element.clave = localStorage.getItem('cua')),
                                contrato:
                                    element.contrato === '' || !element.contrato ? '--' : element.contrato,
                                totalDespuesImpuestos: element.totalDespuesImpuestos
                                    .toString()
                                    .replace(/,/g, ''),
                                agrupacion: element.agrupacion,
                                ramo: element.ramo,
                                fechaFinPeriodo: element.fechaFinPeriodo,
                                comisionesBonos: element.comisionesBonos,
                                folio: element.folio,
                                tipoComprobante: element.tipoComprobante,
                                fechaInicioPeriodo: element.fechaInicioPeriodo,
                                estadoComprobante: element.estadoComprobante,
                                tipoPeriodo: element.tipoPeriodo,
                                totalImpuesto: element.impuestos.totalImpuesto.toString().replace(/,/g, ''),
                                cedular: element.impuestos.cedular,
                                ivaAcreditado: element.impuestos.ivaAcreditado,
                                ivaRetenido: element.impuestos.ivaRetenido,
                                isrRetenido: element.impuestos.isrRetenido
                            };
                            this.detallesFiscales2.push(detalleFiscal2);
                        });
                    } else if (
                        this.detalleComprobantesFiscales2 &&
                        this.detalleComprobantesFiscales2.length > 0 &&
                        this.detalleComprobantesFiscales2[0].comprobanteNominal &&
                        !Array.isArray(this.detalleComprobantesFiscales2[0].comprobanteNominal)
                    ) {
                        const element: ComprobanteNominal = this.detalleComprobantesFiscales2[0]
                            .comprobanteNominal;
                        const detalleFiscal2: ComprobanteNominal = {
                            clave: (element.clave = localStorage.getItem('cua')),
                            contrato: element.contrato === '' || !element.contrato ? '--' : element.contrato,
                            totalDespuesImpuestos: element.totalDespuesImpuestos.toString().replace(/,/g, ''),
                            agrupacion: element.agrupacion,
                            ramo: element.ramo,
                            fechaFinPeriodo: element.fechaFinPeriodo,
                            comisionesBonos: element.comisionesBonos,
                            folio: element.folio,
                            tipoComprobante: element.tipoComprobante,
                            fechaInicioPeriodo: element.fechaInicioPeriodo,
                            estadoComprobante: element.estadoComprobante,
                            tipoPeriodo: element.tipoPeriodo,
                            totalImpuesto: element.impuestos.totalImpuesto.toString().replace(/,/g, ''),
                            cedular: element.impuestos.cedular,
                            ivaAcreditado: element.impuestos.ivaAcreditado,
                            ivaRetenido: element.impuestos.ivaRetenido,
                            isrRetenido: element.impuestos.isrRetenido
                        };
                        this.detallesFiscales2.push(detalleFiscal2);
                    } else {
                        const detalleFiscal2: ComprobanteNominal = {
                            clave: '--',
                            contrato: '--',
                            totalDespuesImpuestos: '--',
                            agrupacion: '--',
                            ramo: '--',
                            fechaFinPeriodo: '--',
                            comisionesBonos: '--',
                            folio: '--',
                            tipoComprobante: '--',
                            fechaInicioPeriodo: '--',
                            estadoComprobante: '--',
                            tipoPeriodo: '--',
                            totalImpuesto: '--',
                            cedular: '--',
                            ivaAcreditado: '--',
                            ivaRetenido: '--',
                            isrRetenido: '--'
                        };
                        this.detallesFiscales2.push(detalleFiscal2);
                    }

                    this.detalleComprobantesFiscalesDataSource = new MatTableDataSource<ComprobanteNominal>([
                        ...this.detallesFiscales,
                        ...this.detallesFiscales2
                    ]);
                    this.resultado2 = this.detallesFiscales.length + this.detallesFiscales2.length;

                    // ****************** Desglose de percepciones pendietes de facturar (Vida) ******************************

                    if (
                        this.nominalesVida &&
                        this.nominalesVida.length > 0 &&
                        this.nominalesVida[0].comprobanteNominal &&
                        Array.isArray(this.nominalesVida[0].comprobanteNominal) &&
                        this.nominalesVida[0].comprobanteNominal.length > 0
                    ) {
                        this.nominalesVida[0].comprobanteNominal.forEach((element) => {
                            const comprobante: ComprobanteNominal = {
                                clave: (element.clave = localStorage.getItem('cua')),
                                contrato:
                                    element.contrato === '' || !element.contrato ? '--' : element.contrato,
                                totalDespuesImpuestos: element.totalDespuesImpuestos
                                    .toString()
                                    .replace(/,/g, ''),
                                agrupacion: element.agrupacion,
                                ramo: element.ramo,
                                fechaFinPeriodo: element.fechaFinPeriodo,
                                comisionesBonos: element.comisionesBonos,
                                folio: element.folio,
                                tipoComprobante: element.tipoComprobante,
                                fechaInicioPeriodo: element.fechaInicioPeriodo,
                                estadoComprobante: element.estadoComprobante,
                                tipoPeriodo: element.tipoPeriodo,
                                totalImpuesto: element.impuestos.totalImpuesto.toString().replace(/,/g, ''),
                                cedular: element.impuestos.cedular,
                                ivaAcreditado: element.impuestos.ivaAcreditado,
                                ivaRetenido: element.impuestos.ivaRetenido,
                                isrRetenido: element.impuestos.isrRetenido
                            };
                            this.comprobantes.push(comprobante);
                            this.validacionStatusComprobanteVida(comprobante);
                        });
                    } else if (
                        this.nominalesVida &&
                        this.nominalesVida.length > 0 &&
                        this.nominalesVida[0].comprobanteNominal &&
                        !Array.isArray(this.nominalesVida[0].comprobanteNominal)
                    ) {
                        const element: ComprobanteNominal = this.nominalesVida[0].comprobanteNominal;
                        const comprobante: ComprobanteNominal = {
                            clave: (element.clave = localStorage.getItem('cua')),
                            contrato: element.contrato === '' || !element.contrato ? '--' : element.contrato,
                            totalDespuesImpuestos: element.totalDespuesImpuestos.toString().replace(/,/g, ''),
                            agrupacion: element.agrupacion,
                            ramo: element.ramo,
                            fechaFinPeriodo: element.fechaFinPeriodo,
                            comisionesBonos: element.comisionesBonos,
                            folio: element.folio,
                            tipoComprobante: element.tipoComprobante,
                            fechaInicioPeriodo: element.fechaInicioPeriodo,
                            estadoComprobante: element.estadoComprobante,
                            tipoPeriodo: element.tipoPeriodo,
                            totalImpuesto: element.impuestos.totalImpuesto.toString().replace(/,/g, ''),
                            cedular: element.impuestos.cedular,
                            ivaAcreditado: element.impuestos.ivaAcreditado,
                            ivaRetenido: element.impuestos.ivaRetenido,
                            isrRetenido: element.impuestos.isrRetenido
                        };
                        this.comprobantes.push(comprobante);
                        this.validacionStatusComprobanteVida(comprobante);
                    } else {
                        const comprobante: ComprobanteNominal = {
                            clave: '--',
                            contrato: '--',
                            totalDespuesImpuestos: '--',
                            agrupacion: '--',
                            ramo: '--',
                            fechaFinPeriodo: '--',
                            comisionesBonos: '--',
                            folio: '--',
                            tipoComprobante: '--',
                            fechaInicioPeriodo: '--',
                            estadoComprobante: '--',
                            tipoPeriodo: '--',
                            totalImpuesto: '--',
                            cedular: '--',
                            ivaAcreditado: '--',
                            ivaRetenido: '--',
                            isrRetenido: '--'
                        };
                        this.statusVida = true;
                        this.comprobantes.push(comprobante);
                    }
                    this.statusVida = this.checkStatusComprobantes(this.comprobantes, this.statusVida);
                    this.comprobantesNominalesVidaDataSource = new MatTableDataSource<ComprobanteNominal>(
                        this.comprobantes
                    );

                    // ****************** Desglose de percepciones pendietes de facturar (No Vida) ******************

                    if (
                        this.nominalesNoVida &&
                        this.nominalesNoVida.length > 0 &&
                        this.nominalesNoVida[0].comprobanteNominal &&
                        Array.isArray(this.nominalesNoVida[0].comprobanteNominal) &&
                        this.nominalesNoVida[0].comprobanteNominal.length > 0
                    ) {
                        this.nominalesNoVida[0].comprobanteNominal.forEach((element) => {
                            const comprobante2: ComprobanteNominal = {
                                clave: (element.clave = localStorage.getItem('cua')),
                                contrato:
                                    element.contrato === '' || !element.contrato ? '--' : element.contrato,
                                totalDespuesImpuestos: element.totalDespuesImpuestos
                                    .toString()
                                    .replace(/,/g, ''),
                                agrupacion: element.agrupacion,
                                ramo: element.ramo,
                                fechaFinPeriodo: element.fechaFinPeriodo,
                                comisionesBonos: element.comisionesBonos,
                                folio: element.folio,
                                tipoComprobante: element.tipoComprobante,
                                fechaInicioPeriodo: element.fechaInicioPeriodo,
                                estadoComprobante: element.estadoComprobante,
                                tipoPeriodo: element.tipoPeriodo,
                                totalImpuesto: element.impuestos.totalImpuesto.toString().replace(/,/g, ''),
                                cedular: element.impuestos.cedular,
                                ivaAcreditado: element.impuestos.ivaAcreditado,
                                ivaRetenido: element.impuestos.ivaRetenido,
                                isrRetenido: element.impuestos.isrRetenido
                            };
                            this.comprobantes2.push(comprobante2);
                            this.validacionStatusComprobanteNoVida(comprobante2);
                        });
                    } else if (
                        this.nominalesNoVida &&
                        this.nominalesNoVida.length > 0 &&
                        this.nominalesNoVida[0].comprobanteNominal &&
                        !Array.isArray(this.nominalesNoVida[0].comprobanteNominal)
                    ) {
                        const element: ComprobanteNominal = this.nominalesNoVida[0].comprobanteNominal;
                        const comprobante2: ComprobanteNominal = {
                            clave: (element.clave = localStorage.getItem('cua')),
                            contrato: element.contrato === '' || !element.contrato ? '--' : element.contrato,
                            totalDespuesImpuestos: element.totalDespuesImpuestos.toString().replace(/,/g, ''),
                            agrupacion: element.agrupacion,
                            ramo: element.ramo,
                            fechaFinPeriodo: element.fechaFinPeriodo,
                            comisionesBonos: element.comisionesBonos,
                            folio: element.folio,
                            tipoComprobante: element.tipoComprobante,
                            fechaInicioPeriodo: element.fechaInicioPeriodo,
                            estadoComprobante: element.estadoComprobante,
                            tipoPeriodo: element.tipoPeriodo,
                            totalImpuesto: element.impuestos.totalImpuesto.toString().replace(/,/g, ''),
                            cedular: element.impuestos.cedular,
                            ivaAcreditado: element.impuestos.ivaAcreditado,
                            ivaRetenido: element.impuestos.ivaRetenido,
                            isrRetenido: element.impuestos.isrRetenido
                        };
                        this.comprobantes2.push(comprobante2);
                        this.validacionStatusComprobanteNoVida(comprobante2);
                    } else {
                        const comprobante2: ComprobanteNominal = {
                            clave: '--',
                            contrato: '--',
                            totalDespuesImpuestos: '--',
                            agrupacion: '--',
                            ramo: '--',
                            fechaFinPeriodo: '--',
                            comisionesBonos: '--',
                            folio: '--',
                            tipoComprobante: '--',
                            fechaInicioPeriodo: '--',
                            estadoComprobante: '--',
                            tipoPeriodo: '--',
                            totalImpuesto: '--',
                            cedular: '--',
                            ivaAcreditado: '--',
                            ivaRetenido: '--',
                            isrRetenido: '--'
                        };
                        this.statusNoVida = true;
                        this.comprobantes2.push(comprobante2);
                    }

                    this.statusNoVida = this.checkStatusComprobantes(this.comprobantes2, this.statusNoVida);
                    this.comprobantesNominalesNoVidaDataSource = new MatTableDataSource<ComprobanteNominal>(
                        this.comprobantes2
                    );
                    this.selection = new SelectionModel<ComprobanteNominal>(true, []);

                    if (this.statusNoVida && this.statusVida) {
                        this.status = true;
                    }
                })
        );
    }

    private checkStatusComprobantes(comprobantes: ComprobanteNominal[], statusOriginal: boolean): boolean {
        let statusPend = false;
        const statusComrpobado: boolean[] = [];

        for (const comprobante of comprobantes) {
            if (
                comprobante.estadoComprobante === 'PEND' &&
                (comprobante.tipoPeriodo === 'ACTU' || comprobante.tipoPeriodo === 'ANTE')
            ) {
                statusPend = true;
            } else if (
                comprobante.estadoComprobante === 'Comprobado' &&
                (comprobante.tipoPeriodo === 'ACTU' || comprobante.tipoPeriodo === 'ANTE')
            ) {
                statusComrpobado.push(true);
            }
        }

        if (statusPend) {
            return false;
        } else if (statusComrpobado.filter((status) => status === true).length === comprobantes.length) {
            return true;
        } else {
            return statusOriginal;
        }
    }

    comprobantesReales(): Subscription {
        const cua = localStorage.getItem('cua');
        let inicio = null;
        let fin = null;

        if (this.fechaInicioPeriodo && this.fechaFinPeriodo) {
            inicio = this.fechaInicioPeriodo;
            fin = this.fechaFinPeriodo;
        } else {
            inicio = localStorage.getItem('fechaInicial');
            fin = localStorage.getItem('fechaFinal');
        }

        const httpOptions = {
            headers: new HttpHeaders({
                [this.contentTypeLabel]: this.contentTypeValue,
                ['apiKey']: environment.apikey
            })
        };
        return this.http
            .get(
                environment.apiComprobantesReales +
                    cua +
                    this.fechainicioLabel +
                    inicio +
                    this.fechafinLabel +
                    fin +
                    '/tipo/FACT',
                httpOptions
            )
            .pipe(first())
            .subscribe((data: any) => {
                if (
                    data &&
                    data.obtenerComprobantesRealesResponse &&
                    data.obtenerComprobantesRealesResponse.comprobante &&
                    Array.isArray(data.obtenerComprobantesRealesResponse.comprobante) &&
                    data.obtenerComprobantesRealesResponse.comprobante.length > 0
                ) {
                    data.obtenerComprobantesRealesResponse.comprobante.forEach((element) => {
                        const comprobanteReales: ComprobanteReal = {
                            fechaIngreso: element.fechaIngreso,
                            importeAntesImpuesto: element.importeAntesImpuesto,
                            nombreArchivo: element.nombreArchivo,
                            ramo: element.ramo,
                            cedular: element.impuestos.cedular,
                            ivaAcreditado: element.impuestos.ivaAcreditado,
                            totalImpuestos: element.impuestos.totalImpuestos,
                            isrRetenido: element.impuestos.isrRetenido,
                            ivaRetenido: element.impuestos.ivaRetenido,
                            numeroComprobante: element.numeroComprobante,
                            importeDespuesImpuestos: element.importeDespuesImpuestos,
                            tipoComprobante: element.tipoComprobante,
                            estadoComprobante: element.estadoComprobante,
                            folioDoc: element.folioDoc,
                            fechaPago: element.fechaPago,
                            fechaRechazo: element.fechaRechazo
                        };
                        this.comprobanteReales.push(comprobanteReales);
                    });
                } else if (
                    data &&
                    data.obtenerComprobantesRealesResponse &&
                    data.obtenerComprobantesRealesResponse.comprobante &&
                    !Array.isArray(data.obtenerComprobantesRealesResponse.comprobante)
                ) {
                    const element = data.obtenerComprobantesRealesResponse.comprobante;

                    const comprobanteReales: ComprobanteReal = {
                        fechaIngreso: element.fechaIngreso,
                        importeAntesImpuesto: element.importeAntesImpuesto,
                        nombreArchivo: element.nombreArchivo,
                        ramo: element.ramo,
                        cedular: element.impuestos.cedular,
                        ivaAcreditado: element.impuestos.ivaAcreditado,
                        totalImpuestos: element.impuestos.totalImpuestos,
                        isrRetenido: element.impuestos.isrRetenido,
                        ivaRetenido: element.impuestos.ivaRetenido,
                        numeroComprobante: element.numeroComprobante,
                        importeDespuesImpuestos: element.importeDespuesImpuestos,
                        tipoComprobante: element.tipoComprobante,
                        estadoComprobante: element.estadoComprobante,
                        folioDoc: element.folioDoc,
                        fechaPago: element.fechaPago,
                        fechaRechazo: element.fechaRechazo
                    };

                    this.comprobanteReales.push(comprobanteReales);
                }

                this.comprobantesRealesDataSource = new MatTableDataSource<ComprobanteReal>(
                    this.comprobanteReales
                );
            });
    }

    // tslint:disable-next-line: cognitive-complexity
    private validacionStatusComprobanteVida(comprobante: ComprobanteNominal): void {
        if (
            (comprobante.tipoComprobante !== 'Factura' &&
                comprobante.estadoComprobante === 'Comprobado' &&
                comprobante.tipoPeriodo === 'ANTE') ||
            (comprobante.tipoComprobante === 'Factura' &&
                comprobante.estadoComprobante === 'Comprobado' &&
                comprobante.tipoPeriodo === 'ANTE') ||
            (comprobante.tipoComprobante !== 'Factura' &&
                comprobante.estadoComprobante === 'Comprobado' &&
                comprobante.tipoPeriodo !== 'ANTE') ||
            (comprobante.tipoComprobante === 'Factura' &&
                comprobante.estadoComprobante === 'Comprobado' &&
                comprobante.tipoPeriodo !== 'ANTE')
        ) {
            this.statusVida = true;
            this.estatusDeVida = true;
        } else if (
            comprobante.tipoComprobante !== 'Factura' &&
            comprobante.estadoComprobante === 'PEND' &&
            comprobante.tipoPeriodo === 'ANTE'
        ) {
            this.statusVida = false;
            if (
                comprobante.tipoComprobante === 'Factura' &&
                comprobante.estadoComprobante === 'PEND' &&
                comprobante.tipoPeriodo === 'ANTE'
            ) {
                this.statusVida = true;
                if (
                    comprobante.tipoComprobante !== 'Factura' &&
                    comprobante.estadoComprobante === 'PEND' &&
                    comprobante.tipoPeriodo !== 'ANTE'
                ) {
                    this.statusVida = true;
                    if (
                        comprobante.tipoComprobante === 'Factura' &&
                        comprobante.estadoComprobante === 'PEND' &&
                        comprobante.tipoPeriodo !== 'ANTE'
                    ) {
                        this.statusVida = true;
                    }
                }
            }
        } else if (
            comprobante.tipoComprobante !== 'Factura' &&
            comprobante.estadoComprobante === 'Comprobado' &&
            comprobante.tipoPeriodo === 'ANTE'
        ) {
            this.statusVida = true;
            if (
                comprobante.tipoComprobante === 'Factura' &&
                comprobante.estadoComprobante !== 'Comprobado' &&
                comprobante.tipoPeriodo === 'ANTE'
            ) {
                this.statusVida = false;
                if (
                    comprobante.tipoComprobante !== 'Factura' &&
                    comprobante.estadoComprobante === 'PEND' &&
                    comprobante.tipoPeriodo !== 'ANTE'
                ) {
                    this.statusVida = true;
                    if (
                        comprobante.tipoComprobante === 'Factura' &&
                        comprobante.estadoComprobante === 'PEND' &&
                        comprobante.tipoPeriodo !== 'ANTE'
                    ) {
                        this.statusVida = true;
                    }
                }
            }
        }
    }

    // tslint:disable-next-line: cognitive-complexity
    private validacionStatusComprobanteNoVida(comprobante2: ComprobanteNominal): void {
        if (
            (comprobante2.tipoComprobante !== 'Factura' &&
                comprobante2.estadoComprobante === 'Comprobado' &&
                comprobante2.tipoPeriodo === 'ANTE') ||
            (comprobante2.tipoComprobante === 'Factura' &&
                comprobante2.estadoComprobante === 'Comprobado' &&
                comprobante2.tipoPeriodo === 'ANTE') ||
            (comprobante2.tipoComprobante !== 'Factura' &&
                comprobante2.estadoComprobante === 'Comprobado' &&
                comprobante2.tipoPeriodo !== 'ANTE') ||
            (comprobante2.tipoComprobante === 'Factura' &&
                comprobante2.estadoComprobante === 'Comprobado' &&
                comprobante2.tipoPeriodo !== 'ANTE')
        ) {
            this.statusNoVida = true;
            this.estatusDeNoVida = true;
        } else if (
            comprobante2.tipoComprobante !== 'Factura' &&
            comprobante2.estadoComprobante === 'PEND' &&
            comprobante2.tipoPeriodo === 'ANTE'
        ) {
            this.statusNoVida = false;
            if (
                comprobante2.tipoComprobante === 'Factura' &&
                comprobante2.estadoComprobante === 'PEND' &&
                comprobante2.tipoPeriodo === 'ANTE'
            ) {
                this.statusNoVida = true;
                if (
                    comprobante2.tipoComprobante !== 'Factura' &&
                    comprobante2.estadoComprobante === 'PEND' &&
                    comprobante2.tipoPeriodo !== 'ANTE'
                ) {
                    this.statusNoVida = true;
                    if (
                        comprobante2.tipoComprobante === 'Factura' &&
                        comprobante2.estadoComprobante === 'PEND' &&
                        comprobante2.tipoPeriodo !== 'ANTE'
                    ) {
                        this.statusNoVida = true;
                    }
                }
            }
        } else if (
            comprobante2.tipoComprobante !== 'Factura' &&
            comprobante2.estadoComprobante === 'Comprobado' &&
            comprobante2.tipoPeriodo === 'ANTE'
        ) {
            this.statusNoVida = true;
            if (
                comprobante2.tipoComprobante === 'Factura' &&
                comprobante2.estadoComprobante !== 'Comprobado' &&
                comprobante2.tipoPeriodo === 'ANTE'
            ) {
                this.statusNoVida = false;
                if (
                    comprobante2.tipoComprobante !== 'Factura' &&
                    comprobante2.estadoComprobante === 'PEND' &&
                    comprobante2.tipoPeriodo !== 'ANTE'
                ) {
                    this.statusNoVida = true;
                    if (
                        comprobante2.tipoComprobante === 'Factura' &&
                        comprobante2.estadoComprobante === 'PEND' &&
                        comprobante2.tipoPeriodo !== 'ANTE'
                    ) {
                        this.statusNoVida = true;
                    }
                }
            }
        }
    }

    beneficioIVA(): Subscription {
        const cua = localStorage.getItem('cua');
        const httpOptions = {
            headers: new HttpHeaders({
                [this.contentTypeLabel]: this.contentTypeValue,
                ['apiKey']: environment.apikey
            })
        };
        return this.http
            .get(environment.apiBeneficioIVA + cua, httpOptions)
            .pipe(first())
            .subscribe((data: any) => {
                try {
                    this.buzon = data.consultarBeneficioIVAResponse.return;

                    if (this.buzon === 'X') {
                        this.isChecked = false;
                        this.desactivar = true;
                    } else if (this.buzon === 'N') {
                        this.isChecked = false;
                        this.desactivar = false;
                    } else if (this.buzon === 'S') {
                        this.isChecked = true;
                        this.desactivar = false;
                    }
                } catch (error) {
                    this.isChecked = false;
                    this.desactivar = true;
                }
            });
    }

    guardarBeneficio(): Subscription {
        const cua = localStorage.getItem('cua');
        let activar: boolean;

        if (this.isChecked === true) {
            activar = true;
        } else {
            activar = false;
        }

        const httpOptions = {
            headers: new HttpHeaders({
                [this.contentTypeLabel]: this.contentTypeValue,
                ['apiKey']: environment.apikey
            })
        };
        return this.http
            .get(environment.apiGuardarIva + cua + '/activar/' + activar, httpOptions)
            .pipe(first())
            .subscribe((data: any) => {
                this.cargarInfo();
            });
    }

    validatorFileXML(e): void {
        this.nombreXml = '';
        this.archivosXMLDataSource = null;
        localStorage.removeItem('xml');

        const reader = new FileReader();
        const file = e.target.files[0];
        reader.readAsDataURL(file);
        this.nombreXml = file.name;

        reader.onload = () => {
            this.spinner.show();
            const base64 = reader.result.toString().split(',')[1];
            localStorage.setItem('xml', base64);

            let xml = null;
            let validacionFecha = true;

            try {
                xml = decodeURIComponent(
                    atob(base64)
                        .split('')
                        .map((c) => {
                            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        })
                        .join('')
                );

                const jsonString = parser.xml2json(xml, { compact: false, spaces: 4 });

                const json = JSON.parse(jsonString);

                if (
                    json &&
                    json.elements &&
                    json.elements[0] &&
                    json.elements[0].attributes &&
                    json.elements[0].attributes.Fecha
                ) {
                    const fechaComprobante = moment(json.elements[0].attributes.Fecha).format('YYYY-MM-DD');
                    const fechaActual = moment(new Date()).format('YYYY-MM-DD');

                    if (
                        moment(fechaComprobante).isSame(fechaActual, 'month') &&
                        moment(fechaComprobante).isSame(fechaActual, 'year') &&
                        moment(fechaComprobante).isSameOrBefore(fechaActual)
                    ) {
                        validacionFecha = true;
                    } else {
                        validacionFecha = false;
                        this.msgError = true;
                        this.estilo = '15px';
                        this.messageError =
                            'FAC34 - La fecha del comprobante es erronea. Favor de validarla.';
                    }
                }
            } catch (error) {
                console.log('Formato de xml incorrecto');
            }

            if (validacionFecha) {
                this.cargaValidacionXML();
            } else {
                setTimeout(() => {
                    this.spinner.hide();
                }, 250);
            }

            this.xmlFileInput.nativeElement.value = '';
        };
    }

    // tslint:disable-next-line: no-big-function cognitive-complexity
    private cargaValidacionXML(): Subscription {
        this.msgError = false;
        this.alertService.clear();
        const tipo = [];
        let ramo = '';
        let folio = '';

        if (this.nombreXml.split('.')[0].length >= 30) {
            this.msgError = true;
            this.estilo = '15px';
            this.messageError =
                'FAC41 - La longitud del nombre del archivo electrónico es de 30 caracteres, favor de ingresar el archivo con un nombre más corto.';
            this.spinner.hide();
        } else {
            this.selectionDataSource.data.forEach((item) => {
                if (item.ramo === 'Vida' && item.tipoComprobante === 'Factura') {
                    tipo.push('FAVI');
                    ramo = item.ramo = 'FAVI';
                } else if (item.ramo === 'No vida' && item.tipoComprobante === 'Factura') {
                    tipo.push('FANV');
                    ramo = 'FANV';
                } else if (item.ramo === 'Vida' && item.tipoComprobante === 'Nota de Credito') {
                    tipo.push('NOVI');
                    ramo = 'NOVI';
                } else if (item.ramo === 'No vida' && item.tipoComprobante === 'Nota de Credito') {
                    tipo.push('NONV');
                    ramo = 'NONV';
                }
                folio = item.folio;
            });

            const httpOptions = {
                headers: new HttpHeaders({
                    [this.contentTypeLabel]: this.contentTypeValue,
                    ['apiKey']: environment.apikey
                })
            };
            const body = {
                cua: localStorage.getItem('cua'),
                tipoComprobante: {
                    tipo
                },
                ramo,
                fechaInicioPeriodo: localStorage.getItem('fechaInicial'),
                comprobante: localStorage.getItem('xml'),
                fechaEmision: localStorage.getItem('fechaFinal'),
                folioInterno: folio,
                tipoIntermediario: 'INT',
                folioDocumentum: '',
                nombreArchivo: this.nombreXml
            };
            return this.http
                .post(environment.apiComprobanteXML, body, httpOptions)
                .pipe(first())
                .subscribe((dataValidarComprobante: any) => {
                    const message = dataValidarComprobante.validarComprobanteResponse.numeroComprobante.substring(
                        3,
                        25
                    );

                    if (message === 'CCException***===FAC14') {
                        this.msgError = true;
                        this.estilo = '15px';
                        this.messageError =
                            '***CCException***===FAC14 - No existe información del intermediario o se tiene problemas con el servicio de generación de datos o con el XML. Favor de notificar a Soporte.';
                    } else if (message === 'CCException***===FAC38') {
                        this.msgError = true;
                        this.estilo = '15px';
                        this.messageError =
                            'FAC38 - No fué posible validar tu comprobante, por favor intenta más tarde.';
                    } else if (message === 'CCException***===FAC39') {
                        this.msgError = true;
                        this.estilo = '15px';
                        this.messageError = '***CCException***===FAC39 - RFC Emisor inválido.';
                    } else if (message === 'CCException***===FAC99') {
                        this.msgError = true;
                        this.estilo = '15px';
                        this.messageError =
                            '***CCException***===FAC99 - Ocurrió un error inesperado en el servicio de Cuenta Corriente. Favor de notificar a Soporte.';
                    } else if (message.toString().includes('CCException***===El importe')) {
                        const errorImporte =
                            dataValidarComprobante.validarComprobanteResponse.numeroComprobante;

                        this.msgError = true;
                        this.estilo = '15px';
                        this.messageError = errorImporte;
                    } else if (message.toString().includes('CCException***===La Nota de Cr')) {
                        this.msgError = true;
                        this.estilo = '15px';
                        this.messageError =
                            'La Nota de Crédito debe tener la misma tasa de IVA de la factura relacionada.';
                    } else if (
                        !message.toString().includes('CCException***===FAC') &&
                        !message.toString().includes('CCException***===')
                    ) {
                        this.msgExito = true;
                        this.estilo = '15px';
                        this.messageExito =
                            'FAC30 - Validación de Comprobante Fiscal exitosa, en caso de tener más de un comprobante fiscal ingrese el siguiente';

                        // ***********************************************************************************************
                        const cua = localStorage.getItem('cua');
                        const inicio = localStorage.getItem('fechaInicial');
                        const fin = localStorage.getItem('fechaFinal');

                        this.http
                            .get(
                                environment.apiComprobantesReales +
                                    cua +
                                    this.fechainicioLabel +
                                    inicio +
                                    this.fechafinLabel +
                                    fin +
                                    '/tipo/FACT',
                                httpOptions
                            )
                            .pipe(first())
                            .subscribe((data: any) => {
                                if (
                                    data &&
                                    data.obtenerComprobantesRealesResponse &&
                                    data.obtenerComprobantesRealesResponse.comprobante &&
                                    Array.isArray(data.obtenerComprobantesRealesResponse.comprobante) &&
                                    data.obtenerComprobantesRealesResponse.comprobante.length > 0
                                ) {
                                    const comprobateCargado = data.obtenerComprobantesRealesResponse.comprobante.filter(
                                        (element) =>
                                            dataValidarComprobante.validarComprobanteResponse
                                                .numeroComprobante === element.numeroComprobante
                                    );

                                    // tslint:disable-next-line: no-identical-functions
                                    comprobateCargado.forEach((element) => {
                                        const comprobanteReales: ComprobanteReal = {
                                            fechaIngreso: element.fechaIngreso,
                                            importeAntesImpuesto: element.importeAntesImpuesto,
                                            nombreArchivo: element.nombreArchivo,
                                            ramo: element.ramo,
                                            cedular: element.impuestos.cedular,
                                            ivaAcreditado: element.impuestos.ivaAcreditado,
                                            totalImpuestos: element.impuestos.totalImpuestos,
                                            isrRetenido: element.impuestos.isrRetenido,
                                            ivaRetenido: element.impuestos.ivaRetenido,
                                            numeroComprobante: element.numeroComprobante,
                                            importeDespuesImpuestos: element.importeDespuesImpuestos,
                                            tipoComprobante: element.tipoComprobante,
                                            estadoComprobante: element.estadoComprobante,
                                            folioDoc: element.folioDoc,
                                            fechaPago: element.fechaPago,
                                            fechaRechazo: element.fechaRechazo
                                        };
                                        this.comprobanteReales.push(comprobanteReales);
                                    });
                                } else if (
                                    data &&
                                    data.obtenerComprobantesRealesResponse &&
                                    data.obtenerComprobantesRealesResponse.comprobante &&
                                    !Array.isArray(data.obtenerComprobantesRealesResponse.comprobante)
                                ) {
                                    const element = data.obtenerComprobantesRealesResponse.comprobante;

                                    if (
                                        dataValidarComprobante.validarComprobanteResponse
                                            .numeroComprobante === element.numeroComprobante
                                    ) {
                                        const comprobanteReales: ComprobanteReal = {
                                            fechaIngreso: element.fechaIngreso,
                                            importeAntesImpuesto: element.importeAntesImpuesto,
                                            nombreArchivo: element.nombreArchivo,
                                            ramo: element.ramo,
                                            cedular: element.impuestos.cedular,
                                            ivaAcreditado: element.impuestos.ivaAcreditado,
                                            totalImpuestos: element.impuestos.totalImpuestos,
                                            isrRetenido: element.impuestos.isrRetenido,
                                            ivaRetenido: element.impuestos.ivaRetenido,
                                            numeroComprobante: element.numeroComprobante,
                                            importeDespuesImpuestos: element.importeDespuesImpuestos,
                                            tipoComprobante: element.tipoComprobante,
                                            estadoComprobante: element.estadoComprobante,
                                            folioDoc: element.folioDoc,
                                            fechaPago: element.fechaPago,
                                            fechaRechazo: element.fechaRechazo
                                        };
                                        this.comprobanteReales.push(comprobanteReales);
                                    }
                                }
                                this.archivosXMLDataSource = new MatTableDataSource<any>(
                                    this.comprobanteReales
                                );
                                this.disabledButtonXml = true;
                                this.cargarInfo();
                            });
                        // ***********************************************************************************************
                    } else {
                        this.msgError = true;
                        this.estilo = '15px';
                        this.messageError =
                            dataValidarComprobante.validarComprobanteResponse.numeroComprobante;
                    }
                    this.xmlFileInput.nativeElement.value = '';
                    this.spinner.hide();
                    return dataValidarComprobante;
                });
        }
    }

    exportAsXLSX(): void {
        this.date = moment(new Date()).format('DD-MM-YYYY');

        this.detalleComprobantesFiscalesDataSource.data.forEach((element) => {
            const dato: DatosSimplificados = {
                ClaveUnica: element.clave,
                Folio: element.folio,
                Contrato: element.contrato,
                FechaDeMovimiento: element.fechaInicioPeriodo,
                Ramo: element.ramo,
                TipoDeComprobante: element.tipoComprobante,
                ImporteAntesImpuestos: element.importeAntesImpuestos,
                IVAAcreditado: element.ivaAcreditado,
                IVARetenido: element.ivaRetenido,
                ISRRetenido: element.isrRetenido,
                ImporteDespuesImpuestos: element.totalDespuesImpuestos
            };
            element.importeAntesImpuestos =
                element.totalDespuesImpuestos -
                element.ivaAcreditado -
                element.ivaRetenido -
                element.isrRetenido;
            this.datosExcel.push(dato);
        });

        if (this.datosExcel === null || this.datosExcel.length <= 0) {
            this.alertService.error('La tabla a descargar no contiene datos.');
        } else {
            this.excelService.exportExcel(this.datosExcel, 'Consulta ' + this.date);
        }
    }

    openModal(id: string): void {
        this.modalService.open(id);

        if (id === 'custom-modal-3') {
            this.nombreXml = '';
            this.xmlFileInput.nativeElement.value = '';
            this.msgError = false;
            this.msgExito = false;
            this.disabledButtonXml = false;
            this.selection.selected.forEach((item) => item['this.selection.selected']);
            this.selection2.selected.forEach((item) => item['this.selection2.selected']);
            this.selectionDataSource = new MatTableDataSource<ComprobanteNominal>([
                ...this.selection.selected,
                ...this.selection2.selected
            ]);
        }
    }

    closeModal(id: string): void {
        this.modalService.close(id);
        if (id === 'custom-modal-3') {
            this.archivosXMLDataSource = null;
        }
    }

    closeMessage(): void {
        this.msgExito = false;
        this.msgError = false;
    }

    sortFacturacion(e: Sort): void {
        const data = this.comprobantesFiscalesDataSource.data;
        if (!e.active || e.direction === '') {
            this.comprobantesFiscalesDataSource.data = data;
            return;
        }

        this.comprobantesFiscalesDataSource.data = data.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'ramo':
                    return this.compare(a.ramo, b.ramo, isAsc);
                case 'facturaComprobar':
                    return this.compare(a.facturaComprobar, b.facturaComprobar, isAsc);
                case 'notasComprobar':
                    return this.compare(a.notasComprobar, b.notasComprobar, isAsc);
                case 'percepciones':
                    return this.compare(a.percepciones, b.percepciones, isAsc);
                case 'saldoPendiente':
                    return this.compare(a.saldoPendiente, b.saldoPendiente, isAsc);
                case 'pendienteProximoPeriodo':
                    return this.compare(a.pendienteProximoPeriodo, b.pendienteProximoPeriodo, isAsc);
                default:
                    return 0;
            }
        });
    }

    sortDetalles(e: Sort): void {
        const data = this.detalleComprobantesFiscalesDataSource.data;
        if (!e.active || e.direction === '') {
            this.detalleComprobantesFiscalesDataSource.data = data;
            return;
        }

        this.detalleComprobantesFiscalesDataSource.data = data.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'clave':
                    return this.compare(a.clave, b.clave, isAsc);
                case 'folio':
                    return this.compare(a.folio, b.folio, isAsc);
                case 'contrato':
                    return this.compare(a.contrato, b.contrato, isAsc);
                case 'fechaMovimiento':
                    return this.compare(a.fechaMovimiento, b.fechaMovimiento, isAsc);
                case 'tipoComprobante':
                    return this.compare(a.tipoComprobante, b.tipoComprobante, isAsc);
                case 'ramo':
                    return this.compare(a.ramo, b.ramo, isAsc);
                case 'totalAntesImpuestos':
                    return this.compare(a.totalAntesImpuestos, b.totalAntesImpuestos, isAsc);
                case 'ivaAcreditado':
                    return this.compare(a.ivaAcreditado, b.ivaAcreditado, isAsc);
                case 'ivaRetenido':
                    return this.compare(a.ivaRetenido, b.ivaRetenido, isAsc);
                case 'isrRetenido':
                    return this.compare(a.isrRetenido, b.isrRetenido, isAsc);
                case 'totalDespuesImpuestos':
                    return this.compare(a.totalDespuesImpuestos, b.totalDespuesImpuestos, isAsc);
                default:
                    return 0;
            }
        });
    }

    sortFacturasVida(e: Sort): void {
        const data = this.comprobantesNominalesVidaDataSource.data;
        if (!e.active || e.direction === '') {
            this.comprobantesNominalesVidaDataSource.data = data;
            return;
        }

        this.comprobantesNominalesVidaDataSource.data = data.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'tipoComprobante':
                    return this.compare(a.tipoComprobante, b.tipoComprobante, isAsc);
                case 'fechaInicioPeriodo':
                    return this.compare(a.fechaInicioPeriodo, b.fechaInicioPeriodo, isAsc);
                case 'fechaFinPeriodo':
                    return this.compare(a.fechaFinPeriodo, b.fechaFinPeriodo, isAsc);
                case 'comisionesBonos':
                    return this.compare(a.comisionesBonos, b.comisionesBonos, isAsc);
                case 'ivaAcreditado':
                    return this.compare(a.ivaAcreditado, b.ivaAcreditado, isAsc);
                case 'ivaRetenido':
                    return this.compare(a.ivaRetenido, b.ivaRetenido, isAsc);
                case 'isrRetenido':
                    return this.compare(a.isrRetenido, b.isrRetenido, isAsc);
                case 'cedular':
                    return this.compare(a.cedular, b.cedular, isAsc);
                case 'totalDespuesImpuestos':
                    return this.compare(a.totalDespuesImpuestos, b.totalDespuesImpuestos, isAsc);
                default:
                    return 0;
            }
        });
    }

    sortFacturasNoVida(e: Sort): void {
        const data = this.comprobantesNominalesNoVidaDataSource.data;
        if (!e.active || e.direction === '') {
            this.comprobantesNominalesNoVidaDataSource.data = data;
            return;
        }

        // tslint:disable-next-line: no-identical-functions
        this.comprobantesNominalesNoVidaDataSource.data = data.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'tipoComprobante':
                    return this.compare(a.tipoComprobante, b.tipoComprobante, isAsc);
                case 'fechaInicioPeriodo':
                    return this.compare(a.fechaInicioPeriodo, b.fechaInicioPeriodo, isAsc);
                case 'fechaFinPeriodo':
                    return this.compare(a.fechaFinPeriodo, b.fechaFinPeriodo, isAsc);
                case 'comisionesBonos':
                    return this.compare(a.comisionesBonos, b.comisionesBonos, isAsc);
                case 'ivaAcreditado':
                    return this.compare(a.ivaAcreditado, b.ivaAcreditado, isAsc);
                case 'ivaRetenido':
                    return this.compare(a.ivaRetenido, b.ivaRetenido, isAsc);
                case 'isrRetenido':
                    return this.compare(a.isrRetenido, b.isrRetenido, isAsc);
                case 'cedular':
                    return this.compare(a.cedular, b.cedular, isAsc);
                case 'totalDespuesImpuestos':
                    return this.compare(a.totalDespuesImpuestos, b.totalDespuesImpuestos, isAsc);
                default:
                    return 0;
            }
        });
    }

    sortComprobantesFiscales(e: Sort): void {
        const data = this.selectionDataSource.data;
        if (!e.active || e.direction === '') {
            this.selectionDataSource.data = data;
            return;
        }

        const d = this.selectionDataSource.data;
        d.sort((a: any, b: any) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'tipoComprobante':
                    return this.compare(a.tipoComprobante, b.tipoComprobante, isAsc);
                case 'ramo':
                    return this.compare(a.ramo, b.ramo, isAsc);
                case 'fechaInicio':
                    return this.compare(a.fechaInicio, b.fechaInicio, isAsc);
                case 'fechaFin':
                    return this.compare(a.fechaFin, b.fechaFin, isAsc);
                case 'totalComprobar':
                    return this.compare(a.totalComprobar, b.totalComprobar, isAsc);
                default:
                    return 0;
            }
        });
    }

    sortArchivoXML(e: Sort): void {
        const data = this.comprobantesNominalesNoVidaDataSource.data;
        if (!e.active || e.direction === '') {
            this.comprobantesNominalesNoVidaDataSource.data = data;
            return;
        }

        this.comprobantesNominalesNoVidaDataSource.data = data.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'tipoComision':
                    return this.compare(a.tipoComision, b.tipoComision, isAsc);
                case 'ramo':
                    return this.compare(a.ramo, b.ramo, isAsc);
                case 'facturaUID':
                    return this.compare(a.facturaUID, b.facturaUID, isAsc);
                case 'importeAntesImpuestos':
                    return this.compare(a.importeAntesImpuestos, b.importeAntesImpuestos, isAsc);
                case 'IVARetenido':
                    return this.compare(a.IVARetenido, b.IVARetenido, isAsc);
                case 'IVAAcreditado':
                    return this.compare(a.IVAAcreditado, b.IVAAcreditado, isAsc);
                case 'impuestoCedular':
                    return this.compare(a.impuestoCedular, b.impuestoCedular, isAsc);
                case 'importeDespuesImpuestos':
                    return this.compare(a.importeDespuesImpuestos, b.importeDespuesImpuestos, isAsc);
                case 'isr':
                    return this.compare(a.isr, b.isr, isAsc);
                case 'fechaIngreso':
                    return this.compare(a.fechaIngreso, b.fechaIngreso, isAsc);
                case 'fechaRechazo':
                    return this.compare(a.fechaRechazo, b.fechaRechazo, isAsc);
                case 'fechaPago':
                    return this.compare(a.fechaPago, b.fechaPago, isAsc);
                default:
                    return 0;
            }
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    // ***************************************************************************************************************

    // ******** Vida ****************
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.comprobantesNominalesVidaDataSource.data.filter(
            (element) => element.estadoComprobante !== 'Comprobado'
        ).length;
        return numSelected === numRows;
    }

    masterToggle(): void {
        this.isAllSelected()
            ? this.selection.clear()
            : this.comprobantesNominalesVidaDataSource.data.forEach((row) => {
                  if (row.estadoComprobante !== 'Comprobado') {
                      this.selection.select(row);
                  }
              });
    }

    checkboxLabel(row?: ComprobanteNominal): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.tipoComprobante}`;
    }

    // tslint:disable-next-line: cognitive-complexity
    checkedValidationVida(rowVida: ComprobanteNominal): void {
        this.selection.toggle(rowVida);

        if (this.selection.isSelected(rowVida)) {
            for (const rowNoVida of this.selection2.selected) {
                if (
                    (rowVida.tipoComprobante === 'Factura' && rowNoVida.tipoComprobante !== 'Factura') ||
                    (rowVida.tipoComprobante !== 'Factura' && rowNoVida.tipoComprobante === 'Factura') ||
                    (rowVida.tipoComprobante !== 'Factura' && rowNoVida.tipoComprobante !== 'Factura')
                ) {
                    this.selection2.toggle(rowNoVida);
                }
            }

            const rowsFacturaVida = this.selection.selected.filter(
                (row) => row.tipoComprobante === 'Factura'
            );
            const rowsNotaVida = this.selection.selected.filter((row) => row.tipoComprobante !== 'Factura');

            if (rowVida.tipoComprobante === 'Factura') {
                for (const nota of rowsNotaVida) {
                    this.selection.toggle(nota);
                }
            } else if (rowVida.tipoComprobante !== 'Factura') {
                for (const factura of rowsFacturaVida) {
                    this.selection.toggle(factura);
                }
            }
        }
    }

    validacionNotasAnterioresVida(rowVida: ComprobanteNominal): boolean {
        let bloquearRow = false;

        const rowsAnterioresVida: ComprobanteNominal[] = this.comprobantesNominalesVidaDataSource.data.filter(
            (comprobante) =>
                comprobante.tipoPeriodo === 'ANTE' && comprobante.estadoComprobante !== 'Comprobado'
        );

        if (rowVida.tipoPeriodo === 'ANTE' || rowVida.tipoComprobante === 'Factura') {
            bloquearRow = false;
        } else if (rowVida.tipoPeriodo !== 'ANTE' && rowsAnterioresVida.length > 0) {
            bloquearRow = true;
        }

        return bloquearRow;
    }

    // ******** No Vida ****************
    isAllSelected2(): boolean {
        const numSelected = this.selection2.selected.length;
        const numRows = this.comprobantesNominalesNoVidaDataSource.data.filter(
            (element) => element.estadoComprobante !== 'Comprobado'
        ).length;
        return numSelected === numRows;
    }

    masterToggle2(): void {
        this.isAllSelected2()
            ? this.selection2.clear()
            : this.comprobantesNominalesNoVidaDataSource.data.forEach((row) => {
                  if (row.estadoComprobante !== 'Comprobado') {
                      this.selection2.select(row);
                  }
              });
    }

    checkboxLabel2(row?: ComprobanteNominal): string {
        if (!row) {
            return `${this.isAllSelected2() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection2.isSelected(row) ? 'deselect' : 'select'} row ${row.tipoComprobante}`;
    }

    // tslint:disable-next-line: cognitive-complexity
    checkedValidationNoVida(rowNoVida: ComprobanteNominal): void {
        this.selection2.toggle(rowNoVida);

        if (this.selection2.isSelected(rowNoVida)) {
            for (const rowVida of this.selection.selected) {
                if (
                    (rowNoVida.tipoComprobante === 'Factura' && rowVida.tipoComprobante !== 'Factura') ||
                    (rowNoVida.tipoComprobante !== 'Factura' && rowVida.tipoComprobante === 'Factura') ||
                    (rowNoVida.tipoComprobante !== 'Factura' && rowVida.tipoComprobante !== 'Factura')
                ) {
                    this.selection.toggle(rowVida);
                }
            }

            const rowsFacturaVida = this.selection2.selected.filter(
                (row) => row.tipoComprobante === 'Factura'
            );
            const rowsNotaVida = this.selection2.selected.filter((row) => row.tipoComprobante !== 'Factura');

            if (rowNoVida.tipoComprobante === 'Factura') {
                for (const nota of rowsNotaVida) {
                    this.selection2.toggle(nota);
                }
            } else if (rowNoVida.tipoComprobante !== 'Factura') {
                for (const factura of rowsFacturaVida) {
                    this.selection2.toggle(factura);
                }
            }
        }
    }

    validacionNotasAnterioresNoVida(rowNoVida: ComprobanteNominal): boolean {
        let bloquearRow = false;

        const rowsAnterioresNoVida: ComprobanteNominal[] = this.comprobantesNominalesNoVidaDataSource.data.filter(
            (comprobante) =>
                comprobante.tipoPeriodo === 'ANTE' && comprobante.estadoComprobante !== 'Comprobado'
        );

        if (rowNoVida.tipoPeriodo === 'ANTE' || rowNoVida.tipoComprobante === 'Factura') {
            bloquearRow = false;
        } else if (rowNoVida.tipoPeriodo !== 'ANTE' && rowsAnterioresNoVida.length > 0) {
            bloquearRow = true;
        }

        return bloquearRow;
    }
    // ***************************************************************************************************************
}

export interface VentanaServcioResponse {
    servicio: string;
    mensaje: string;
}

export interface ConsultaAgentesResponse {
    claveOficina: string;
    dinamicaComprobacion: string;
    estatus: string;
    agenteExclusion: string;
    personalidadFiscal: string;
    gerencia: string;
    numeroCuenta: string;
    buzonE: string;
    cua: string;
    rfc: string;
}

export interface ObtenerSaldosResponse {
    saldo: Saldo;
}
export interface Saldo {
    estado: string;
    fechaInicial: string;
    fechaFinal: string;
    dias: string;
    pago: string;
    reporte: string;
    detalle?: any;
}

export interface Detalle {
    folio: string;
    contrato: string;
    fechaMovimiento: string;
    tipoComprobante: string;
    clave: string;
    descuentos: string;
    saldoFinal: string;
    ramo: string;
    saldoInicial: string;
    percepciones: string;
    cedular: string;
    totalImpuesto: string;
    ivaAcreditado: string;
    isrRetenido: string;
    ivaRetenido: string;
}

export interface ObtenerComprobantesNominalesResponse {
    obtenerComprobantesNominalesResponse: ObtenerComprobantesNominalesResponseClass;
}

export interface ObtenerComprobantesRealesResponse {
    comprobantes: ComprobanteReal[];
}

export interface ComprobanteReal {
    fechaIngreso: any;
    importeAntesImpuesto: number;
    nombreArchivo: string;
    ramo: string;
    cedular: number;
    ivaAcreditado: number;
    totalImpuestos: number;
    isrRetenido: number;
    ivaRetenido: number;
    numeroComprobante: string;
    importeDespuesImpuestos: number;
    tipoComprobante: string;
    estadoComprobante: string;
    folioDoc: string;
    fechaPago: string;
    fechaRechazo: any;
}

export interface ObtenerComprobantesNominalesResponseClass {
    datosFiscales: DatosFiscales[];
    comprobantes: Comprobante[];
}

export interface Comprobante {
    ramo: string;
    comprobanteNominal?: ComprobanteNominal[];
}

export interface ComprobanteNominal {
    clave: string;
    contrato: string;
    agrupacion: string;
    ramo: string;
    fechaFinPeriodo: string;
    totalDespuesImpuestos: any;
    comisionesBonos: string;
    folio: string;
    tipoComprobante: string;
    fechaInicioPeriodo: string;
    estadoComprobante: string;
    tipoPeriodo: string;
    cedular: string;
    totalImpuesto: any;
    ivaAcreditado: string;
    isrRetenido: string;
    ivaRetenido: string;
    impuestos?: Impuestos;
}

export interface Impuestos {
    cedular: string;
    totalImpuesto: string;
    ivaAcreditado: string;
    isrRetenido: string;
    ivaRetenido: string;
}

export interface DatosFiscales {
    facturaComprobar: string;
    ramo: string;
    saldoPendiente: string;
    notasComprobar: string;
    percepciones: string;
    pendienteProximoPeriodo: string;
}

export interface DatosSimplificados {
    ClaveUnica: number;
    Folio: number;
    Contrato: string;
    FechaDeMovimiento: string;
    Ramo: string;
    TipoDeComprobante: string;
    ImporteAntesImpuestos: number;
    IVAAcreditado: number;
    IVARetenido: number;
    ISRRetenido: number;
    ImporteDespuesImpuestos: number;
}

export interface ValidarComprobanteResponse {
    fechaIngreso: any;
    importeAntesImpuesto: string;
    nombreArchivo: string;
    fechaRechazo: any;
    numeroComprobante: string;
    importeDespuesImpuestos: string;
    folioDoc: string;
    fechaPago: any;
}
