import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ExporterService } from '../services/exporter.service';
import { ModalService } from '../_modal';
import { AlertService } from '../_services';

@Component({
    selector: 'app-comisiones',
    templateUrl: 'comisiones.component.html',
    styleUrls: ['comisiones-overview.css']
})
export class ComisionesComponent implements AfterViewInit {
    @Input() fechaInicioPeriodo: number;
    @Input() fechaFinPeriodo: number;
    comisiones: DatosComisiones[];
    comisionesAutos: DatosComisiones[];
    comisionesSalud: DatosComisiones[];
    comisionesVida: DatosComisiones[];
    comisionesDanos: DatosComisiones[];

    dataSource = null;
    dataSourceAutos = null;
    dataSourceDanos = null;
    dataSourceSalud = null;
    dataSourceVida = null;

    generalPage = 0;
    generalSize = 5;

    vidaPage = 0;
    vidaSize = 5;

    saludPage = 0;
    saludSize = 5;

    autosPage = 0;
    autosSize = 5;

    danosPage = 0;
    danosSize = 5;

    mFolio = false;
    mAgenteUnidad = false;
    mNumPoliza = false;
    mNumRecibo = false;

    mFolioValue: string;
    mAgenteUnidadValue: string;
    mNumPolizaValue: string;
    mNumReciboValue: string;

    allComplete = false;
    date: any;
    estilo: any;
    longitud: number;
    message: boolean;
    datos: DatosSimplificados[] = [];

    task: Task = {
        name: 'Todos',
        completed: false,

        subtasks: [
            { name: Ramo.vida, completed: false },
            { name: Ramo.salud, completed: false },
            { name: Ramo.autos, completed: false },
            { name: Ramo.danios, completed: false }
        ]
    };

    mensajeFacturacionExito = false;
    mensajeFacturacionError = false;

    // Filtros
    @ViewChild(MatTabGroup, { static: false }) matTab: MatTabGroup;

    // Tabla Generales
    @ViewChild('PaginatorGenerales', { static: false }) set matGeneralesPaginator(paginator: MatPaginator) {
        if (this.dataSource !== null && this.dataSource !== undefined) {
            this.dataSource.paginator = paginator;
        }
    }
    @ViewChild('generalSort', { static: false }) set matGeneralesSort(generalSort: MatSort) {
        if (this.dataSource !== null && this.dataSource !== undefined) {
            this.dataSource.sort = generalSort;
        }
    }

    // Tabla Vida
    @ViewChild('PaginatorVida', { static: false }) set matVidaPaginator(paginatorVida: MatPaginator) {
        if (this.dataSourceVida !== null && this.dataSourceVida !== undefined) {
            this.dataSourceVida.paginator = paginatorVida;
        }
    }
    @ViewChild('vidaSort', { static: false }) set matVidaSort(vidaSort: MatSort) {
        if (this.dataSourceVida !== null && this.dataSourceVida !== undefined) {
            this.dataSourceVida.sort = vidaSort;
        }
    }

    // Tabla Salud
    @ViewChild('PaginatorSalud', { static: false }) set matSaludPaginator(paginatorSalud: MatPaginator) {
        if (this.dataSourceSalud !== null && this.dataSourceSalud !== undefined) {
            this.dataSourceSalud.paginator = paginatorSalud;
        }
    }
    @ViewChild('saludSort', { static: false }) set matSaludSort(saludSort: MatSort) {
        if (this.dataSourceSalud !== null && this.dataSourceSalud !== undefined) {
            this.dataSourceSalud.sort = saludSort;
        }
    }

    // Tabla Autos
    @ViewChild('PaginatorAutos', { static: false }) set matAutosPaginator(paginatorAutos: MatPaginator) {
        if (this.dataSourceAutos !== null && this.dataSourceAutos !== undefined) {
            this.dataSourceAutos.paginator = paginatorAutos;
        }
    }
    @ViewChild('autosSort', { static: false }) set matAutosSort(autosSort: MatSort) {
        if (this.dataSourceAutos !== null && this.dataSourceAutos !== undefined) {
            this.dataSourceAutos.sort = autosSort;
        }
    }

    // Tabla Daños
    @ViewChild('PaginatorDanos', { static: false }) set matDanosPaginator(paginatorDanos: MatPaginator) {
        if (this.dataSourceDanos !== null && this.dataSourceDanos !== undefined) {
            this.dataSourceDanos.paginator = paginatorDanos;
        }
    }
    @ViewChild('danosSort', { static: false }) set matDanosSort(danosSort: MatSort) {
        if (this.dataSourceDanos !== null && this.dataSourceDanos !== undefined) {
            this.dataSourceDanos.sort = danosSort;
        }
    }

    constructor(
        private http: HttpClient,
        private excelService: ExporterService,
        private modalService: ModalService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService
    ) {}

    displayedColumns: string[] = [
        'circulo',
        'claveUnica',
        'folio',
        'agenteUnidad',
        'contrato',
        'fechaMovimiento',
        'ramo',
        'tipoComprobante',
        'importeAntes',
        'ivaAcredit',
        'ivaReten',
        'isrReten',
        'importeDesp',
        'numPoliza',
        'numRecibo',
        'nombreContra'
    ];

    ngAfterViewInit(): void {
        this.tablaComisiones();
    }

    // tslint:disable-next-line: cognitive-complexity
    tablaComisiones(): Subscription {
        this.spinner.show();

        const cua = localStorage.getItem('cua');
        let inicio = '';
        let fin = '';

        if (this.fechaInicioPeriodo && this.fechaFinPeriodo) {
            inicio = this.fechaInicioPeriodo.toString();
            fin = this.fechaFinPeriodo.toString();
        } else {
            inicio = localStorage.getItem('fechaInicial');
            fin = localStorage.getItem('fechaFinal');
        }

        const httpOptions = {
            headers: new HttpHeaders({ ['Content-Type']: 'application/json', ['apiKey']: environment.apikey })
        };
        return this.http
            .get(
                environment.apiComisiones + '/' + cua + '/periodoinicio/' + inicio + '/periodofin/' + fin,
                httpOptions
            )
            .pipe(first())
            .subscribe((data: DatosComisiones[]) => {
                this.comisiones = data;

                this.longitud = this.comisiones.length;
                if (this.longitud === 3500) {
                    this.message = true;
                } else {
                    this.message = false;
                }

                this.comisiones.forEach((item) => {
                    switch (item.unidadEstrategiaNegocioClave) {
                        case 'AU':
                            item.estiloCirculo = '#ff8c00';
                            item.unidadEstrategiaNegocioClave = 'Autos';
                            break;
                        case 'SA':
                            item.estiloCirculo = '#0643cf';
                            item.unidadEstrategiaNegocioClave = 'Salud';
                            break;
                        case 'DA':
                            item.estiloCirculo = '#b639ff';
                            item.unidadEstrategiaNegocioClave = 'Daños';
                            break;
                        case 'VI':
                        case 'VC':
                        case 'VG':
                            item.estiloCirculo = '#00c300';
                            item.unidadEstrategiaNegocioClave = 'Vida';
                            break;
                        default:
                            item.estiloCirculo = 'red';
                    }
                    item.claveTransaccion = cua;
                    item.agenteUnidad = '' ? '--' : item.agenteUnidad;
                });
                this.dataSource = new MatTableDataSource<DatosComisiones>(this.comisiones);

                /********************************************************************/
                this.comisionesAutos = this.comisiones.filter(
                    (item) => item.unidadEstrategiaNegocioClave === 'Autos'
                );
                this.dataSourceAutos = new MatTableDataSource<DatosComisiones>(this.comisionesAutos);

                /********************************************************************/

                this.comisionesSalud = this.comisiones.filter(
                    (item) => item.unidadEstrategiaNegocioClave === 'Salud'
                );
                this.dataSourceSalud = new MatTableDataSource<DatosComisiones>(this.comisionesSalud);

                /********************************************************************/

                this.comisionesDanos = this.comisiones.filter(
                    (item) => item.unidadEstrategiaNegocioClave === 'Daños'
                );
                this.dataSourceDanos = new MatTableDataSource<DatosComisiones>(this.comisionesDanos);

                /********************************************************************/

                this.comisionesVida = this.comisiones.filter(
                    (item) => item.unidadEstrategiaNegocioClave === 'Vida'
                );
                this.dataSourceVida = new MatTableDataSource<DatosComisiones>(this.comisionesVida);

                if (this.fechaInicioPeriodo && this.fechaFinPeriodo) {
                    this.spinner.hide();
                } else {
                    this.checkFacturacion();
                }
            });
    }

    mensajeShow(): void {
        this.message = false;
    }

    cambioPaginaGeneral(e: any): void {
        this.generalPage = e.pageIndex;
        this.generalSize = e.pageSize;
    }

    sortGenerales(e: Sort): void {
        const data = this.dataSource.data;
        if (!e.active || e.direction === '') {
            this.dataSource.data = data;
            return;
        }

        this.dataSource.data = this.sortTable(data, e);
    }

    sortVida(e: Sort): void {
        const data = this.dataSourceVida.data;
        if (!e.active || e.direction === '') {
            this.dataSourceVida.data = data;
            return;
        }

        this.dataSourceVida.data = this.sortTable(data, e);
    }

    sortSalud(e: Sort): void {
        const data = this.dataSourceSalud.data;
        if (!e.active || e.direction === '') {
            this.dataSourceSalud.data = data;
            return;
        }

        this.dataSourceSalud.data = this.sortTable(data, e);
    }

    sortAutos(e: Sort): void {
        const data = this.dataSourceAutos.data;
        if (!e.active || e.direction === '') {
            this.dataSourceAutos.data = data;
            return;
        }

        this.dataSourceAutos.data = this.sortTable(data, e);
    }

    sortDanos(e: Sort): void {
        const data = this.dataSourceDanos.data;
        if (!e.active || e.direction === '') {
            this.dataSourceDanos.data = data;
            return;
        }

        this.dataSourceDanos.data = this.sortTable(data, e);
    }

    private sortTable(data, e): any {
        return data.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'importeAntes':
                    return this.compare(
                        a.movimientoPercepcionImporteAntesImpuesto,
                        b.movimientoPercepcionImporteAntesImpuesto,
                        isAsc
                    );
                case 'ivaAcredit':
                    return this.compare(a.ivaAcreditado, b.ivaAcreditado, isAsc);
                case 'ivaReten':
                    return this.compare(a.ivaRetenido, b.ivaRetenido, isAsc);
                case 'isrReten':
                    return this.compare(a.isr, b.isr, isAsc);
                case 'importeDesp':
                    return this.compare(
                        a.movimientoPercepcionImporteDespuesDeImpuesto,
                        b.movimientoPercepcionImporteDespuesDeImpuesto,
                        isAsc
                    );
                default:
                    return 0;
            }
        });
    }

    cambioPaginaVida(e: any): void {
        this.vidaPage = e.pageIndex;
        this.vidaSize = e.pageSize;
    }

    cambioPaginaSalud(e: any): void {
        this.saludPage = e.pageIndex;
        this.saludSize = e.pageSize;
    }

    cambioPaginaAutos(e: any): void {
        this.autosPage = e.pageIndex;
        this.autosSize = e.pageSize;
    }

    cambioPaginaDanos(e: any): void {
        this.danosPage = e.pageIndex;
        this.danosSize = e.pageSize;
    }

    compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    /**
     * 0- General.
     * 1- Vida.
     * 2- Salud.
     * 3- Autos.
     * 4- Daños.
     *
     * @param e Valor a filtrar
     */
    filtrar(e: string, type: string): void {
        switch (this.matTab.selectedIndex) {
            case 0:
                this.dataSource.filterPredicate = (data: DatosComisiones, filter: any): boolean => {
                    const dataFilter = data[type].toString();
                    return dataFilter.toLocaleLowerCase().indexOf(filter) !== -1;
                };
                this.dataSource.filter = e.trim().toLocaleLowerCase();
                break;
            case 1:
                this.dataSourceVida.filterPredicate = (data: DatosComisiones, filter: any): boolean => {
                    const dataFilter = data[type].toString();
                    return dataFilter.toLocaleLowerCase().indexOf(filter) !== -1;
                };
                this.dataSourceVida.filter = e.trim().toLocaleLowerCase();
                break;
            case 2:
                this.dataSourceSalud.filterPredicate = (data: DatosComisiones, filter: any): boolean => {
                    const dataFilter = data[type].toString();
                    return dataFilter.toLocaleLowerCase().indexOf(filter) !== -1;
                };
                this.dataSourceSalud.filter = e.trim().toLocaleLowerCase();
                break;
            case 3:
                this.dataSourceAutos.filterPredicate = (data: DatosComisiones, filter: any): boolean => {
                    const dataFilter = data[type].toString();
                    return dataFilter.toLocaleLowerCase().indexOf(filter) !== -1;
                };
                this.dataSourceAutos.filter = e.trim().toLocaleLowerCase();
                break;
            case 4:
                this.dataSourceDanos.filterPredicate = (data: DatosComisiones, filter: any): boolean => {
                    const dataFilter = data[type].toString();
                    return dataFilter.toLocaleLowerCase().indexOf(filter) !== -1;
                };
                this.dataSourceDanos.filter = e.trim().toLocaleLowerCase();
                break;
            default:
                return;
        }
    }

    prepararFiltros(): void {
        this.mFolio = false;
        this.mAgenteUnidad = false;
        this.mNumPoliza = false;
        this.mNumRecibo = false;
    }

    // tslint:disable-next-line: cognitive-complexity
    exportAsXLSX(): void {
        let exportComisiones = [];
        this.date = moment(new Date()).format('DD-MM-YYYY');

        if (this.allComplete) {
            exportComisiones = exportComisiones.concat(this.comisiones);
        } else {
            for (const subtask of this.task.subtasks) {
                if (subtask.name === Ramo.vida && subtask.completed) {
                    exportComisiones = exportComisiones.concat(this.comisionesVida);
                } else if (subtask.name === Ramo.salud && subtask.completed) {
                    exportComisiones = exportComisiones.concat(this.comisionesSalud);
                } else if (subtask.name === Ramo.autos && subtask.completed) {
                    exportComisiones = exportComisiones.concat(this.comisionesAutos);
                } else if (subtask.name === Ramo.danios && subtask.completed) {
                    exportComisiones = exportComisiones.concat(this.comisionesDanos);
                }
            }
        }

        if (exportComisiones.length <= 0) {
            this.alertService.error('La tabla a descargar no contiene datos.');
        } else if (exportComisiones.length > 0) {
            exportComisiones.forEach((item) => {
                const utc = new Date(item.movimientoPercepcionFechaProcesoSistema).toUTCString();

                const date =
                    new Date(utc).getFullYear() +
                    '-' +
                    (new Date(utc).getMonth() + 1) +
                    '-' +
                    new Date(utc).getDate();

                const dato: DatosSimplificados = {
                    ClaveUnica: item.claveTransaccion,
                    Folio: item.movimientoPercepcionFolioAgente,
                    FolioAgente: (item.agenteUnidad = '' ? '--' : item.agenteUnidad),
                    Contrato: item.contratoAgenteNumero,
                    FechaDeMovimiento: (item.movimientoPercepcionFechaProcesoSistema = date),
                    Ramo: item.unidadEstrategiaNegocioClave,
                    TipoComprobante: item.operacion,
                    ImporteAntesImpuestos: item.movimientoPercepcionImporteAntesImpuesto,
                    IVAAcreditado: item.ivaAcreditado,
                    IVARetenido: item.ivaRetenido,
                    ISRRetenido: item.isr,
                    ImporteDespuesImpuestos: item.movimientoPercepcionImporteDespuesDeImpuesto,
                    NumeroDePoliza: item.movimientoPercepcionNumeroPoliza,
                    NumeroDeRecibo: item.movimientoPercepcionNumeroReferenciaPago,
                    NombreDeContratante: item.movimientoPercepcionNombreAsegurado
                };
                this.datos.push(dato);
            });
            this.excelService.exportExcel(this.datos, 'Consulta ' + this.date);
            this.datos = [];
        }
    }

    openModal(id: string): void {
        this.modalService.open(id);
    }

    closeModal(id: string): void {
        this.modalService.close(id);
    }

    updateAllComplete(e: boolean, s: Task): void {
        let allComplete = true;
        s.completed = e;
        this.task.subtasks.forEach((t) => {
            allComplete = t.completed && allComplete;
        });
        this.allComplete = allComplete;
    }

    someComplete(): boolean {
        if (this.task.subtasks == null) {
            return false;
        }
        return this.task.subtasks.filter((t) => t.completed).length > 0 && !this.allComplete;
    }

    setAll(completed: boolean): void {
        this.allComplete = completed;
        if (this.task.subtasks == null) {
            return;
        }
        this.task.subtasks.forEach((t) => (t.completed = completed));
    }

    abrirFiltro(option: string): void {
        switch (option) {
            case 'folio':
                this.mFolio = !this.mFolio;
                this.mAgenteUnidad = false;
                this.mNumPoliza = false;
                this.mNumRecibo = false;
                break;
            case 'agenteUnidad':
                this.mFolio = false;
                this.mAgenteUnidad = !this.mAgenteUnidad;
                this.mNumPoliza = false;
                this.mNumRecibo = false;
                break;
            case 'poliza':
                this.mFolio = false;
                this.mAgenteUnidad = false;
                this.mNumPoliza = !this.mNumPoliza;
                this.mNumRecibo = false;
                break;
            case 'recibo':
                this.mFolio = false;
                this.mAgenteUnidad = false;
                this.mNumPoliza = false;
                this.mNumRecibo = !this.mNumRecibo;
                break;
        }
    }

    // tslint:disable-next-line: cognitive-complexity
    checkFacturacion(): void {
        const valoresAdmitidos = ['ENVI', 'ESPE', 'PEND', 'RECI'];
        const valoresCorrectos = ['RECI'];

        const cua = localStorage.getItem('cua');
        const httpOptions = {
            headers: new HttpHeaders({ ['Content-Type']: 'application/json', ['apiKey']: environment.apikey })
        };
        this.http
            .get(environment.apiBuzonE + cua, httpOptions)
            .pipe(first())
            .subscribe((data: any) => {
                const buzonE = data.consultaAgentesResponse.buzonE;

                if (buzonE === 'S') {
                    if (
                        localStorage.getItem('facturacionError') &&
                        localStorage.getItem('facturacionError') === 'true'
                    ) {
                        this.mensajeFacturacionError = true;
                        this.spinner.hide();
                    } else {
                        let inicio = '';
                        let fin = '';

                        if (this.fechaInicioPeriodo && this.fechaFinPeriodo) {
                            inicio = this.fechaInicioPeriodo.toString();
                            fin = this.fechaFinPeriodo.toString();
                        } else {
                            inicio = localStorage.getItem('fechaInicial');
                            fin = localStorage.getItem('fechaFinal');
                        }
                        this.http
                            .get(
                                environment.apiHistorico3 +
                                    cua +
                                    '/periodoinicio/' +
                                    inicio +
                                    '/periodofin/' +
                                    fin,
                                httpOptions
                            )
                            .pipe(first())
                            .subscribe((comprobantes: any) => {
                                const notasErroneas =
                                    comprobantes.notas && comprobantes.notas.length > 0
                                        ? comprobantes.notas.filter(
                                              (element) =>
                                                  !valoresAdmitidos.includes(
                                                      element.claveEstatusComprobante
                                                  ) ||
                                                  (valoresCorrectos.includes(
                                                      element.claveEstatusComprobante
                                                  ) &&
                                                      element.uuidComprobanteFiscal.includes('KEY:'))
                                          ).length
                                        : 0;
                                const facturasErroneas =
                                    comprobantes.facturas && comprobantes.facturas.length > 0
                                        ? comprobantes.facturas.filter(
                                              (element) =>
                                                  !valoresAdmitidos.includes(
                                                      element.claveEstatusComprobante
                                                  ) ||
                                                  (valoresCorrectos.includes(
                                                      element.claveEstatusComprobante
                                                  ) &&
                                                      element.uuidComprobanteFiscal.includes('KEY:'))
                                          ).length
                                        : 0;

                                if (notasErroneas > 0 || facturasErroneas > 0) {
                                    this.mensajeFacturacionError = true;
                                } else {
                                    const notasCorrectas =
                                        comprobantes.notas && comprobantes.notas.length > 0
                                            ? comprobantes.notas.filter((element) =>
                                                  valoresCorrectos.includes(element.claveEstatusComprobante)
                                              ).length
                                            : 0;
                                    const facturasCorrectas =
                                        comprobantes.facturas && comprobantes.facturas.length > 0
                                            ? comprobantes.facturas.filter((element) =>
                                                  valoresCorrectos.includes(element.claveEstatusComprobante)
                                              ).length
                                            : 0;

                                    if (notasCorrectas > 0 || facturasCorrectas > 0) {
                                        this.mensajeFacturacionExito = true;
                                    }
                                }

                                this.spinner.hide();
                            });
                    }
                } else {
                    this.mensajeFacturacionError = false;
                    this.mensajeFacturacionExito = false;
                    this.spinner.hide();
                }
            });
    }
}

export interface Task {
    name: string;
    completed: boolean;
    subtasks?: Task[];
}

export interface DatosComisiones {
    agenteUnidad: string;
    contratoAgenteNumero: number;
    movimientoPercepcionFechaProcesoSistema: any;
    unidadEstrategiaNegocioClave: string;
    claveTransaccion: string;
    movimientoPercepcionImporteAntesImpuesto: number;
    ivaRetenido: number;
    ivaAcreditado: number;
    isr: number;
    movimientoPercepcionImporteDespuesDeImpuesto: number;
    movimientoPercepcionNumeroPoliza: string;
    operacion: string;
    movimientoPercepcionFolioAgente: string;
    movimientoPercepcionNumeroReferenciaPago: string;
    movimientoPercepcionNombreAsegurado: string;
    estiloCirculo?: string;
}

export interface DatosSimplificados {
    ClaveUnica: string;
    Folio: string;
    FolioAgente: string;
    Contrato: number;
    FechaDeMovimiento: string;
    Ramo: string;
    TipoComprobante: string;
    ImporteAntesImpuestos: number;
    IVAAcreditado: number;
    IVARetenido: number;
    ISRRetenido: number;
    ImporteDespuesImpuestos: number;
    NumeroDePoliza: string;
    NumeroDeRecibo: string;
    NombreDeContratante: string;
}

enum Ramo {
    vida = 'Vida',
    salud = 'Salud',
    autos = 'Autos',
    danios = 'Daños'
}
