import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../_services';

@Component({ selector: 'app-periodos-anteriores', templateUrl: 'periodosAnteriores.component.html' })
export class PeriodosAnterioresComponent implements OnInit {
    loading = false;
    year1: any;
    year2: any;
    year3: any;
    year4: any;
    year5: any;
    anoActual = true;
    anoAnterior = true;
    anoAnterior2 = true;
    anoAnterior3 = true;
    anoAnterior4 = true;
    indexYear = 0;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.route.snapshot.paramMap.get('periodos-anteriores');

        if (!this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit(): void {
        const fecha = new Date();
        this.year1 = fecha.getFullYear();
        this.year2 = this.year1 - 1;
        this.year3 = this.year1 - 2;
        this.year4 = this.year1 - 3;
        this.year5 = this.year1 - 4;

        if (localStorage.getItem('anio1')) {
            this.indexYear = 0;
        } else if (localStorage.getItem('anio2')) {
            this.indexYear = 1;
        } else if (localStorage.getItem('anio3')) {
            this.indexYear = 2;
        } else if (localStorage.getItem('anio4')) {
            this.indexYear = 3;
        } else if (localStorage.getItem('anio5')) {
            this.indexYear = 4;
        }
    }

    returnComisiones(): void {
        this.router.navigate(['/']);
    }
}
