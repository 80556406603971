import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import { AuthenticationService, UserService } from '../_services';

@Component({ selector: 'app-home', templateUrl: 'home.component.html' })
@Injectable()
export class HomeComponent implements OnInit, OnDestroy {
    [x: string]: any;
    loading = false;
    users: User[];
    comisiones = true;
    bonos = false;
    otrosMovimientos = false;
    facturacion = false;
    pagos = false;
    currentUser: User;
    currentUserSubscription: Subscription;
    saldos: DetalleIngresos;
    fechas = false;
    portal: string;
    returnUrl: string = null;
    cambioCua: boolean;
    factura: any;
    fechaInicioPeriodo: any;
    fechaFinPeriodo: any;
    cambiarCua: boolean;
    dias: any;
    verDetalle = false;
    private formatDate = 'DD/MM/YYYY';

    constructor(
        private authenticationService: AuthenticationService,
        public userService: UserService,
        private http: HttpClient,
        private router: Router
    ) {
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe((user) => {
            this.currentUser = user;
        });

        const detalleFactura = localStorage.getItem('verDetalleFactura');

        if (
            detalleFactura !== undefined &&
            detalleFactura !== null &&
            detalleFactura !== '' &&
            detalleFactura !== 'undefined'
        ) {
            this.verDetalle = true;

            this.factura = JSON.parse(detalleFactura);

            this.fechaInicioPeriodo = this.factura.fechaInicioPeriodo;
            this.fechaFinPeriodo = this.factura.fechaFinPeriodo;

            const fechaInicio1 = moment(this.fechaInicioPeriodo).format(this.formatDate).substring(0, 6);
            const fechaInicio2 = moment(this.fechaInicioPeriodo).format(this.formatDate).substring(8, 10);
            this.factura.fechaInicio = fechaInicio1 + fechaInicio2;

            const fechaFin1 = moment(this.fechaFinPeriodo).format(this.formatDate).substring(0, 6);
            const fechaFin2 = moment(this.fechaFinPeriodo).format(this.formatDate).substring(8, 10);
            this.factura.fechaFin = fechaFin1 + fechaFin2;
        }
        localStorage.setItem('verDetalleFactura', undefined);
    }

    ngOnInit(): void {
        if (localStorage.getItem('portal').substring(9, 17) === 'empleado') {
            this.cambiarCua = true;
        } else {
            this.cambioCua = false;
        }

        localStorage.removeItem('fechaInicial');
        localStorage.removeItem('fechaFinal');
        this.detalleIngresos();
        this.saldoDisponible();
    }

    saldoDisponible(): Subscription {
        const cua = localStorage.getItem('cua');
        const httpOptions = {
            headers: new HttpHeaders({ ['Content-Type']: 'application/json', ['apiKey']: environment.apikey })
        };
        return this.http
            .get(environment.apiPeriodoFacturar + cua, httpOptions)
            .pipe(first())
            .subscribe((data: any) => {
                this.dias = data.obtenerSaldosResponse.saldo.dias;
            });
    }

    returnLogin(): void {
        const cua = localStorage.getItem('cua');
        const portal = localStorage.getItem('portal');

        sessionStorage.clear();
        localStorage.clear();

        localStorage.setItem('cua', cua);
        localStorage.setItem('portal', portal);

        this.authenticationService.setNullCurrentUser();

        // validaciones
        this.router.navigate(['/login-empleados']);
    }

    detalleIngresos(): Subscription {
        const cua = localStorage.getItem('cua');
        const inicio = this.fechaInicioPeriodo;
        const fin = this.fechaFinPeriodo;

        const httpOptions = {
            headers: new HttpHeaders({ ['Content-Type']: 'application/json', ['apiKey']: environment.apikey })
        };

        if (this.verDetalle === true) {
            return this.http
                .get(
                    environment.apiDetalleIngresos + cua + '/periodoinicio/' + inicio + '/periodofin/' + fin,
                    httpOptions
                )
                .pipe(first())
                .subscribe((data: DetalleIngresos) => {
                    this.saldos = data;

                    const fechaInicio1 = moment(this.saldos.peridoInicial)
                        .format(this.formatDate)
                        .substring(0, 6);
                    const fechaInicio2 = moment(this.saldos.peridoInicial)
                        .format(this.formatDate)
                        .substring(8, 10);
                    const fechaIniTemp = fechaInicio1 + fechaInicio2;

                    const fechaFin1 = moment(this.saldos.periodoFinal)
                        .format(this.formatDate)
                        .substring(0, 6);
                    const fechaFin2 = moment(this.saldos.periodoFinal)
                        .format(this.formatDate)
                        .substring(8, 10);
                    const fechaFinTemp = fechaFin1 + fechaFin2;

                    const fechaInicio = new Date(this.fechaInicioPeriodo).getTime();
                    const fechaFin = new Date(this.fechaFinPeriodo).getTime();

                    const diff = fechaFin - fechaInicio;
                    this.dias = diff / (1000 * 60 * 60 * 24);

                    this.saldos.fechaInicial = fechaIniTemp;
                    this.saldos.fechaFinal = fechaFinTemp;

                    localStorage.setItem('fechaInicial', this.saldos.peridoInicial);
                    localStorage.setItem('fechaFinal', this.saldos.periodoFinal);

                    this.fechas = true;
                });
        } else {
            return this.http
                .get(environment.apiDetalleIngresos + cua, httpOptions)
                .pipe(first())
                .subscribe((data: DetalleIngresos) => {
                    this.saldos = data;

                    const fechaInicio1 = moment(this.saldos.peridoInicial)
                        .format(this.formatDate)
                        .substring(0, 6);
                    const fechaInicio2 = moment(this.saldos.peridoInicial)
                        .format(this.formatDate)
                        .substring(8, 10);
                    const fechaIniTemp = fechaInicio1 + fechaInicio2;

                    const fechaFin1 = moment(this.saldos.periodoFinal)
                        .format(this.formatDate)
                        .substring(0, 6);
                    const fechaFin2 = moment(this.saldos.periodoFinal)
                        .format(this.formatDate)
                        .substring(8, 10);
                    const fechaFinTemp = fechaFin1 + fechaFin2;

                    this.saldos.fechaInicial = fechaIniTemp;
                    this.saldos.fechaFinal = fechaFinTemp;

                    localStorage.setItem('fechaInicial', this.saldos.peridoInicial);
                    localStorage.setItem('fechaFinal', this.saldos.periodoFinal);

                    this.fechas = true;
                });
        }
    }

    periodosAnteriores(): void {
        this.router.navigate(['/periodos-anteriores']);
    }

    ngOnDestroy(): void {
        this.currentUserSubscription.unsubscribe();
    }

    banderaComisiones(): void {
        this.comisiones = true;
        this.bonos = false;
        this.otrosMovimientos = false;
        this.facturacion = false;
        this.pagos = false;
    }

    banderaBonos(): void {
        this.bonos = true;
        this.comisiones = false;
        this.otrosMovimientos = false;
        this.facturacion = false;
        this.pagos = false;
    }

    banderaOtrosMovimientos(): void {
        this.otrosMovimientos = true;
        this.comisiones = false;
        this.bonos = false;
        this.facturacion = false;
        this.pagos = false;
    }

    banderaFacturacion(): void {
        this.otrosMovimientos = false;
        this.comisiones = false;
        this.bonos = false;
        this.facturacion = true;
        this.pagos = false;
    }

    banderaPagos(): void {
        this.otrosMovimientos = false;
        this.comisiones = false;
        this.bonos = false;
        this.facturacion = false;
        this.pagos = true;
    }
}

export interface DetalleIngresos {
    bonos: number;
    comisiones: number;
    impuestos: number;
    otrosMovimientos: number;
    peridoInicial: any;
    periodoFinal: any;
    saldoDisponible: number;
    saldoPagado: number;
    fechaInicial?: string;
    fechaFinal?: string;
    pagos?: number;
}

export interface ObtenerSaldosResponse {
    saldo: Saldo;
}

export interface Saldo {
    estado: string;
    fechaInicial: string;
    fechaFinal: string;
    dias: string;
    pago: string;
    reporte: string;
}
