import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, Sort } from '@angular/material';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ExporterService } from '../services/exporter.service';
import { AlertService } from '../_services';

@Component({ selector: 'app-pagos', templateUrl: 'pagos.component.html' })
export class PagosComponent implements OnInit {
    @Input() fechaInicioPeriodo: number;
    @Input() fechaFinPeriodo: number;
    dataSource: MatTableDataSource<Pagos>;
    pagos: Pagos[] = [];
    date: any;
    datos: DatosSimplificados[] = [];
    private esFactura: boolean;

    @ViewChild('pagosSort', { static: false }) set matPagosSort(pagosSort: MatSort) {
        if (this.dataSource !== null && this.dataSource !== undefined) {
            this.dataSource.sort = pagosSort;
        }
    }

    constructor(
        private http: HttpClient,
        private alertService: AlertService,
        private excelService: ExporterService,
        private spinner: NgxSpinnerService
    ) {}

    displayedColumns: string[] = ['concepto', 'observacion', 'monto'];

    ngOnInit(): Subscription {
        this.alertService.clear();

        this.spinner.show();

        const cua = localStorage.getItem('cua');
        let inicio: any;
        let fin: any;

        if (this.fechaInicioPeriodo && this.fechaFinPeriodo) {
            this.esFactura = true;
            inicio = this.fechaInicioPeriodo;
            fin = this.fechaFinPeriodo;
        } else {
            this.esFactura = false;
            inicio = localStorage.getItem('fechaInicial');
            fin = localStorage.getItem('fechaFinal');
        }

        const httpOptions = {
            headers: new HttpHeaders({ ['Content-Type']: 'application/json', ['apiKey']: environment.apikey })
        };
        return this.http
            .get(environment.apiPagos + cua + '/periodoinicio/' + inicio + '/periodofin/' + fin, httpOptions)
            .pipe(first())
            .subscribe((data: Pagos[]) => {
                data.forEach((element) => {
                    const pagos: Pagos = {
                        claveTransaccion: element.claveTransaccion,
                        tipoTransaccion: element.tipoTransaccion,
                        facturable: element.facturable,
                        pagoDescripcionComplemento: element.pagoDescripcionComplemento,
                        pagoImporteDespuesDeImpuesto: element.pagoImporteDespuesDeImpuesto
                    };
                    this.pagos.push(pagos);
                });

                this.dataSource = new MatTableDataSource<Pagos>(this.pagos);
                this.spinner.hide();
            });
    }

    exportAsXLSX(): void {
        this.date = moment(new Date()).format('DD-MM-YYYY');

        this.pagos.forEach((item) => {
            const dato: DatosSimplificados = {
                Concepto: item.tipoTransaccion,
                Observacion: item.pagoDescripcionComplemento,
                Monto: item.pagoImporteDespuesDeImpuesto
            };
            this.datos.push(dato);
        });

        if (this.datos.length <= 0) {
            this.alertService.error('La tabla a descargar no contiene datos');
        } else {
            this.excelService.exportExcel(this.datos, 'Consulta ' + this.date);
        }
    }

    sortPagos(e: Sort): void {
        const data = this.dataSource.data;
        if (!e.active || e.direction === '') {
            this.dataSource.data = data;
            return;
        }

        const d = this.dataSource.data;
        d.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'concepto':
                    return this.compare(a.tipoTransaccion, b.tipoTransaccion, isAsc);
                case 'observacion':
                    return this.compare(a.pagoDescripcionComplemento, b.pagoDescripcionComplemento, isAsc);
                case 'monto':
                    return this.compare(a.pagoImporteDespuesDeImpuesto, b.pagoDescripcionComplemento, isAsc);
                default:
                    return 0;
            }
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}

export interface Pagos {
    claveTransaccion: string;
    tipoTransaccion: string;
    facturable: string;
    pagoImporteDespuesDeImpuesto: number;
    pagoDescripcionComplemento: string;
}

export interface DatosSimplificados {
    Concepto: string;
    Observacion: string;
    Monto: number;
}
