import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, Sort } from '@angular/material';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ExporterService } from '../services/exporter.service';
import { AlertService } from '../_services';

@Component({ selector: 'app-otrosMovimientos', templateUrl: 'otrosMovimientos.component.html' })
export class OtrosMovimientosComponent implements OnInit {
    @Input() fechaInicioPeriodo: number;
    @Input() fechaFinPeriodo: number;
    dataSource: MatTableDataSource<OtrosMovimientos>;
    otrosMovimientos: OtrosMovimientos[] = [];
    date: any;
    datos: DatosSimplificados[] = [];
    message: string;
    private esFactura: boolean;

    @ViewChild('otrosSort', { static: false }) set matOtrosSort(otrosSort: MatSort) {
        if (this.dataSource !== null && this.dataSource !== undefined) {
            this.dataSource.sort = otrosSort;
        }
    }

    constructor(
        private http: HttpClient,
        private excelService: ExporterService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService
    ) {}

    displayedColumns: string[] = ['tipoMovimiento', 'transaccion', 'descripcion', 'importeDespuesDeImpuesto'];

    ngOnInit(): void {
        this.alertService.clear();
        this.tablaOtrosMovimientos();
    }

    tablaOtrosMovimientos(): Subscription {
        this.spinner.show();

        const cua = localStorage.getItem('cua');
        let inicio: any;
        let fin: any;

        if (this.fechaInicioPeriodo && this.fechaFinPeriodo) {
            this.esFactura = true;
            inicio = this.fechaInicioPeriodo;
            fin = this.fechaFinPeriodo;
        } else {
            this.esFactura = false;
            inicio = localStorage.getItem('fechaInicial');
            fin = localStorage.getItem('fechaFinal');
        }

        const httpOptions = {
            headers: new HttpHeaders({ ['Content-Type']: 'application/json', ['apiKey']: environment.apikey })
        };
        return this.http
            .get(
                environment.apiOtrosMovimientos +
                    '/' +
                    cua +
                    '/periodoinicio/' +
                    inicio +
                    '/periodofin/' +
                    fin,
                httpOptions
            )
            .pipe(first())
            .subscribe((data: OtrosMovimientos[]) => {
                data.forEach((element) => {
                    const otrosMovimientos: OtrosMovimientos = {
                        claveTransaccion: element.claveTransaccion,
                        tipoTransaccion: element.tipoTransaccion,
                        facturable: element.facturable,
                        movimientoPercepcionDescripcionComplemento:
                            element.movimientoPercepcionDescripcionComplemento,
                        movimientoPercepcionImporteDespuesDeImpuesto:
                            element.movimientoPercepcionImporteDespuesDeImpuesto
                    };
                    this.otrosMovimientos.push(otrosMovimientos);
                });
                this.convertFacturable();

                this.dataSource = new MatTableDataSource<OtrosMovimientos>(this.otrosMovimientos);
                this.spinner.hide();
            });
    }

    private convertFacturable(): void {
        this.otrosMovimientos.forEach((movimiento) => {
            switch (movimiento.facturable) {
                case 'S':
                    movimiento.facturable = 'FACTURABLE';
                    break;
                case 'N':
                    movimiento.facturable = 'NO FACTURABLE';
                    break;
            }
        });
    }

    exportAsXLSX(): void {
        this.date = moment(new Date()).format('DD-MM-YYYY');

        this.otrosMovimientos.forEach((item) => {
            const dato: DatosSimplificados = {
                TipoMovimiento: item.tipoTransaccion,
                Concepto: item.tipoTransaccion,
                Observacion: item.movimientoPercepcionDescripcionComplemento,
                Monto: item.movimientoPercepcionImporteDespuesDeImpuesto
            };
            this.datos.push(dato);
        });

        if (this.datos.length <= 0) {
            this.alertService.error('La tabla a descargar no contiene datos');
        } else {
            this.excelService.exportExcel(this.datos, 'Consulta ' + this.date);
        }
    }

    sortOtros(e: Sort): void {
        const data = this.dataSource.data;
        if (!e.active || e.direction === '') {
            this.dataSource.data = data;
            return;
        }

        const d = this.dataSource.data;
        d.sort((a, b) => {
            const isAsc = e.direction === 'asc';
            switch (e.active) {
                case 'tipoMovimiento':
                    return this.compare(a.tipoTransaccion, b.tipoTransaccion, isAsc);
                case 'transaccion':
                    return this.compare(a.claveTransaccion, b.claveTransaccion, isAsc);
                case 'descripcion':
                    return this.compare(
                        a.movimientoPercepcionDescripcionComplemento,
                        b.movimientoPercepcionDescripcionComplemento,
                        isAsc
                    );
                case 'importeDespuesDeImpuesto':
                    return this.compare(
                        a.movimientoPercepcionImporteDespuesDeImpuesto,
                        b.movimientoPercepcionImporteDespuesDeImpuesto,
                        isAsc
                    );
                default:
                    return 0;
            }
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}

export interface OtrosMovimientos {
    claveTransaccion: string;
    tipoTransaccion: string;
    facturable: string;
    movimientoPercepcionImporteDespuesDeImpuesto: number;
    movimientoPercepcionDescripcionComplemento: string;
}

export interface DatosSimplificados {
    TipoMovimiento: string;
    Concepto: string;
    Observacion: string;
    Monto: number;
}
