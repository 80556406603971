import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private contentType = 'Content-Type';
    private appJson = 'application/json';

    returnUrl: string;
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    portal: string;
    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private cookies: CookieService,
        private router: Router
    ) {
        try {
            this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('token')));
            this.currentUser = this.currentUserSubject.asObservable();
            this.returnUrl = this.route.snapshot.queryParams.reutrnUrl || '/';
        } catch (error) {
            return error;
        }
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(password): Observable<any> {
        const nip = password;
        const cua = localStorage.getItem('cua');
        try {
            const httpOptions = {
                headers: new HttpHeaders({
                    [this.contentType]: this.appJson,
                    ['apiKey']: environment.apikey
                })
            };
            return this.http.get<any>(environment.apiURL + '/cua/' + cua + '/nip/' + nip, httpOptions).pipe(
                catchError((error) => {
                    return throwError(error);
                }),
                map((user) => {
                    return this.setCurrentUser(user);
                })
            );
        } catch (error) {
            console.log(error);
        }
    }

    loginEmpleadosCUA(agente): Observable<any> {
        const agenteId = agente;
        localStorage.setItem('cua', agente);
        try {
            const httpOptions = {
                headers: new HttpHeaders({
                    [this.contentType]: this.appJson,
                    ['apiKey']: environment.apikey
                })
            };
            return this.http.get<any>(environment.apiCUA + agenteId, httpOptions).pipe(
                catchError((error) => {
                    return throwError(error);
                }),
                map((user) => {
                    return this.setCurrentUser(user);
                })
            );
        } catch (error) {
            console.log(error);
        }
    }

    loginEmpleadosNOMBRE(nombres, paterno, materno): Observable<any> {
        try {
            const httpOptions = {
                headers: new HttpHeaders({
                    [this.contentType]: this.appJson,
                    ['apiKey']: environment.apikey
                })
            };
            return this.http
                .get<any>(
                    environment.apiNombre + nombres + '/paterno/' + paterno + '/materno/' + materno,
                    httpOptions
                )
                .pipe(
                    catchError((error) => {
                        return throwError(error);
                    }),
                    map((user) => {
                        return this.setCurrentUser(user);
                    })
                );
        } catch (error) {
            console.log(error);
        }
    }

    loginEmpleadosRAZONSOCIAL(razonSocial: string): Observable<any> {
        try {
            const httpOptions = {
                headers: new HttpHeaders({
                    [this.contentType]: this.appJson,
                    ['apiKey']: environment.apikey
                })
            };
            return this.http.get<any>(environment.apiRazonSocial + razonSocial, httpOptions).pipe(
                catchError((error) => {
                    return throwError(error);
                }),
                map((user) => {
                    return this.setCurrentUser(user);
                })
            );
        } catch (error) {
            console.log(error);
        }
    }

    loginAgentes(nombreCompleto: string): Observable<Observable<any[]>> {
        try {
            const httpOptions = {
                headers: new HttpHeaders({
                    [this.contentType]: this.appJson,
                    ['x-api-key']: environment.xApiKeyAgentes
                })
            };
            return this.http.get<any>(environment.apiAgentes + nombreCompleto, httpOptions).pipe(
                catchError((error) => {
                    return throwError(error);
                }),
                map((agentes) => {
                    const arrayData = [];
                    if (agentes && agentes.length > 0) {
                        for (const agente of agentes) {
                            arrayData.push(this.getAgenteCriterios(agente.idParticipante));
                        }
                        sessionStorage.setItem('token', JSON.stringify(agentes));
                        this.currentUserSubject.next(agentes);
                        return forkJoin(arrayData);
                    } else {
                        return throwError('Dato inválido y/o agente inexistente, favor de verificar');
                    }
                })
            );
        } catch (error) {
            console.log(error);
        }
    }

    private setCurrentUser(user: any): any {
        sessionStorage.setItem('token', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
    }

    private getAgenteCriterios(idAgente: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                [this.contentType]: this.appJson,
                ['x-api-key']: environment.xApiKeyAgentes
            }),
            params: new HttpParams().set('idAgente', idAgente)
        };
        return this.http.get<any>(environment.apiAgentesCriterios, httpOptions);
    }

    logout(): void {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('expires_at');

        localStorage.removeItem('cua');
        localStorage.removeItem('xml');
        localStorage.removeItem('data2020');
        localStorage.removeItem('data2019');
        localStorage.removeItem('data2018');
        localStorage.removeItem('data2017');
        localStorage.removeItem('data2016');
        localStorage.removeItem('fechaInicial');
        localStorage.removeItem('fechaFinal');

        localStorage.removeItem('mesAnio1');
        localStorage.removeItem('mesAnio2');
        localStorage.removeItem('mesAnio3');
        localStorage.removeItem('mesAnio4');
        localStorage.removeItem('mesAnio5');
        localStorage.removeItem('anio1');
        localStorage.removeItem('anio2');
        localStorage.removeItem('anio3');
        localStorage.removeItem('anio4');
        localStorage.removeItem('anio5');
    }

    setNullCurrentUser(): void {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('token')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.returnUrl = this.route.snapshot.queryParams.reutrnUrl || '/';
    }

    setToken(token: string): void {
        this.cookies.set('token', token);
    }

    getToken(): string {
        return this.cookies.get('token');
    }
}
