import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({ selector: 'app-comparacionPeriodos', templateUrl: 'comparacionPeriodos.component.html' })
export class ComparacionPeriodosComponent implements OnInit {
    loading = false;

    constructor(private route: ActivatedRoute, private router: Router) {
        this.route.snapshot.paramMap.get('comparacion-periodos');
    }

    comparativoAnual = true;
    comparativoMensual = true;
    comparativoPeriodo = true;

    ngOnInit(): void {}

    returnComisiones(): void {
        this.router.navigate(['/']);
    }
}
